import * as Yup from "yup";
import { genderOptions } from "../../helpers/data";

export const AddSalaryValidation = Yup.object().shape({
  basic: Yup.number(),
  da: Yup.number(),
  hra: Yup.number(),
  other_allowance: Yup.number(),
  all_cash_reimbursement: Yup.number(),
  lta: Yup.number(),
  medical: Yup.number(),
  arrears: Yup.number(),
  pf: Yup.number(),
  esi: Yup.number(),
  income_tax: Yup.number(),
  professional_tax: Yup.number(),
  loans_and_advances: Yup.number(),
  perquisites: Yup.number(),
});

export const EditSalaryValidation = Yup.object().shape({
  // _id: Yup.string().trim().required(),
  basic: Yup.number(),
  da: Yup.number(),
  hra: Yup.number(),
  other_allowance: Yup.number(),
  all_cash_reimbursement: Yup.number(),
  lta: Yup.number(),
  medical: Yup.number(),
  arrears: Yup.number(),
  pf: Yup.number(),
  esi: Yup.number(),
  income_tax: Yup.number(),
  professional_tax: Yup.number(),
  loans_and_advances: Yup.number(),
  perquisites: Yup.number(),
});
