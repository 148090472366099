import { configureStore } from "@reduxjs/toolkit";
import {AuthReducer, RESET_STORE} from "./reducers/authReducer";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import { combineReducers } from 'redux'


const persistConfig = {
  key: 'root',
  storage,
}

const appReducer = combineReducers({
  auth: AuthReducer,
});
const rootReducer = (state, action) => {
    if (action.type === RESET_STORE) {
    state = undefined;
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
})

export const persistor = persistStore(store)
