import React from "react";
import { useNavigate } from "react-router-dom";
import "./Sidebar.scss";

export default function Sidebar() {
  const toggleSidebar = () => {
    const sidebar = document.getElementById("accordionSidebar");
    sidebar.classList.toggle("toggled");
  };
  const navigate = useNavigate();
  const goTo = (page = "/home") => {
    navigate(page);
  };
  return (
    <>
      <div class="col-md-2 col-sm-12 no_mar open_left_bar">
        <div class="left_sidenav">
          <div class="close_left_bar">
            <a
              href="javascript:void(0);"
              className="material-symbols-outlined nav_open"
            >
              close
            </a>
          </div>
          <ul>
            <li class={window.location.pathname.match(/home/) ? "active" : ""}>
              <a href="javascript:void(0);" onClick={() => goTo("/home")}>
                <span class="material-symbols-outlined">other_houses</span>{" "}
                <small>Dash board</small>
              </a>
            </li>
            <li class={window.location.pathname.match(/sites/) ? "active" : ""}>
              <a href="javascript:void(0);" onClick={() => goTo("/sites")}>
                <span class="material-symbols-outlined">apartment</span>{" "}
                <small>Sites</small>
              </a>
            </li>
            <li
              class={window.location.pathname.match(/client/) ? "active" : ""}
            >
              <a href="javascript:void(0);" onClick={() => goTo("/clients")}>
                <span class="material-symbols-outlined">person</span>{" "}
                <small>Clients</small>
              </a>
            </li>
            <li class={window.location.pathname.match(/user/) ? "active" : ""}>
              <a href="javascript:void(0);" onClick={() => goTo("/users")}>
                <span class="material-symbols-outlined">person</span>{" "}
                <small>Users</small>
              </a>
            </li>
            <li
              class={window.location.pathname.match(/employee/) ? "active" : ""}
            >
              <a href="javascript:void(0);" onClick={() => goTo("/employees")}>
                <span class="material-symbols-outlined">person</span>{" "}
                <small>Employees</small>
              </a>
            </li>
            {/* <li class={window.location.pathname.match(/role/) ? "active" : ""}>
              <a href="javascript:void(0);" onClick={() => goTo("/roles")}>
                <span class="material-symbols-outlined">groups</span>{" "}
                <small>User role management</small>
              </a>
            </li> */}
            <li
              class={
                window.location.pathname.match(/attendance/) ? "active" : ""
              }
            >
              <a
                href="javascript:void(0);"
                onClick={() => goTo("/attendances")}
              >
                <span class="material-symbols-outlined">groups</span>{" "}
                <small>Attendance</small>
              </a>
            </li>
            <li
              class={window.location.pathname.match(/payments/) ? "active" : ""}
            >
              <a href="javascript:void(0);" onClick={() => goTo("/payments")}>
                <span class="material-symbols-outlined">work</span>{" "}
                <small>Payments</small>
              </a>
            </li>
            <li
              class={window.location.pathname.match(/expenses/) ? "active" : ""}
            >
              <a href="javascript:void(0);" onClick={() => goTo("/expenses")}>
                <span class="material-symbols-outlined">work</span>{" "}
                <small>Expenses</small>
              </a>
            </li>
            <li
              class={window.location.pathname.match(/vendor/) ? "active" : ""}
            >
              <a href="javascript:void(0);" onClick={() => goTo("/vendors")}>
                <span class="material-symbols-outlined">work</span>{" "}
                <small>Vendors</small>
              </a>
            </li>
            <li
              class={window.location.pathname.match(/invoice/) ? "active" : ""}
            >
              <a href="javascript:void(0);" onClick={() => goTo("/invoices")}>
                <span class="material-symbols-outlined">pageview</span>{" "}
                <small>Invoices</small>
              </a>
            </li>

            {/* <li
              class={window.location.pathname.match(/salary/) ? "active" : ""}
            >
              <a href="javascript:void(0);" onClick={() => goTo("/salary")}>
                <span class="material-symbols-outlined"></span>{" "}
                <small>Salary</small>
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </>
  );
}
