import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import Network from "../../helpers/network";
import api from "../../config/api";
import { Formik, setIn } from "formik";
import { EmployeeSalaryBreakDownValidation } from "./Employee.validation";
import { bankAccountMasking, camelToTitle } from "../../helpers/data";
import { Toast } from "bootstrap";
import AppContext from "../../appContext";

export default function EmployeeSalaryBreakDown() {
  const [user, setUser] = useState(null);
  const [userId, setUserId] = useState(null);
  const [isError, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [message, setMessage] = useState(null);
  const appContext = useContext(AppContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const [initialValues, setInitialValues] = useState({
    basic: "0",
    da: "0",
    hra: "0",
    other_allowance: "0",

    total_earning: "0",
    net_pay: "0",
    is_pf_enabled: true,
    // insurance_deduction: "0",
  });
  const getUser = async (id) => {
    try {
      if (id) {
        setUserId(searchParams.get("id"));
        getSalaryBreakDown(searchParams.get("id"));
        const result = await Network.get(api.getUser, { id: id });
        console.log("user >>", result);
        setUser(result?.data?.data);
        // setMessage("User has been fatched successfully");
        // setSuccess(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const getSalaryBreakDown = async (id) => {
    try {
      if (id) {
        const result = await Network.get(api.salaryList, { id: id });
        console.log("getSalaryBreakDown >>", result);
        if (result?.data?.data?.data) {
          setInitialValues(result?.data?.data?.data);
        }
        //

        // setUser(result?.data?.data);
        // setMessage("User has been fatched successfully");
        // setSuccess(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const addSalaryBreakdownHandler = async (values, resetForm) => {
    try {
      const { total_earning, total_deduction, net_pay, ...rest } = values;
      const res = await Network.post(api.addSalary, {
        ...values,
        total_earning:
          (parseFloat(values?.basic) ? parseFloat(values?.basic) : 0) +
          (parseFloat(values?.hra) ? parseFloat(values?.hra) : 0) +
          (parseFloat(values?.da) ? parseFloat(values?.da) : 0) +
          // (parseFloat(values?.medical) ? parseFloat(values?.medical) : 0) +
          (parseFloat(values?.other_allowance)
            ? parseFloat(values?.other_allowance)
            : 0),
        // (parseFloat(values?.loans_and_advances)
        //   ? parseFloat(values?.loans_and_advances)
        //   : 0)
        net_pay:
          (parseFloat(values?.basic) ? parseFloat(values?.basic) : 0) +
          (parseFloat(values?.hra) ? parseFloat(values?.hra) : 0) +
          (parseFloat(values?.da) ? parseFloat(values?.da) : 0) +
          (parseFloat(values?.other_allowance)
            ? parseFloat(values?.other_allowance)
            : 0),
        // +
        // (parseFloat(values?.medical) ? parseFloat(values?.medical) : 0) +
        // (parseFloat(values?.other_allowance)
        //   ? parseFloat(values?.other_allowance)
        //   : 0) +
        // (parseFloat(values?.loans_and_advances)
        //   ? parseFloat(values?.loans_and_advances)
        //   : 0)
        // ((parseFloat(values?.pf) ? parseFloat(values?.pf) : 0) +
        //   (parseFloat(values?.esi) ? parseFloat(values?.esi) : 0) +
        // (parseFloat(values?.income_tax)
        //   ? parseFloat(values?.income_tax)
        //   : 0) +
        // (parseFloat(values?.professional_tax)
        //   ? parseFloat(values?.professional_tax)
        //   : 0)),
        // (parseFloat(values?.perquisitess)
        //   ? parseFloat(values?.perquisitess)
        //   : 0) +
        // (parseFloat(values?.insurance_deduction)
        //   ? parseFloat(values?.insurance_deduction)
        //   : 0)
        // total_deduction:
        //   (parseFloat(values?.pf) ? parseFloat(values?.pf) : 0) +
        //   (parseFloat(values?.esi) ? parseFloat(values?.esi) : 0) +
        //   // (parseFloat(values?.income_tax)
        //   //   ? parseFloat(values?.income_tax)
        //   //   : 0) +
        //   (parseFloat(values?.professional_tax)
        //     ? parseFloat(values?.professional_tax)
        //     : 0),
        // +
        // (parseFloat(values?.perquisitess)
        //   ? parseFloat(values?.perquisitess)
        //   : 0) +
        // (parseFloat(values?.insurance_deduction)
        //   ? parseFloat(values?.insurance_deduction)
        //   : 0)
        userId: userId,
      });
      console.log(res);
      appContext.showToast("success", res?.data?.message);
    } catch (err) {
      appContext.showToast("error", err?.response?.data?.message);
    }
  };
  useEffect(() => {
    // setUserId(searchParams.get("id"));
    getUser(searchParams.get("id"));
  }, [searchParams]);
  return (
    <div class="col-md-10 col-sm-12  bashboard_boxes_open no_mar mb-2">
      <div class="bg-white mt-3 rounded p-4 m-4`">
        <div class="row">
          <Formik
            initialValues={initialValues}
            onSubmit={(values, { resetForm }) => {
              // console.log(values);
              // return;
              addSalaryBreakdownHandler(values, resetForm);
            }}
            validationSchema={EmployeeSalaryBreakDownValidation}
            enableReinitialize={true}
          >
            {({
              handleChange,
              handleSubmit,
              handleBlur,
              errors,
              touched,
              values,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                {isError && <div class="alert alert-danger">{message}</div>}
                {isSuccess && <div class="alert alert-success">{message}</div>}
                <div class="col-md-12">
                  <div class=" lh-1 mb-2">
                    <h6 class="fw-bold">Payment breakdown structure</h6>{" "}
                    {/* <span class="fw-normal">
                Payment slip for the month of June 2023
              </span> */}
                  </div>
                  <div class="d-flex justify-content-end">
                    <span>
                      Working Branch:{user && user?.siteId?.site_name}{" "}
                    </span>{" "}
                  </div>
                  <div class="row">
                    <div class="col-md-10">
                      <div class="row">
                        <div class="col-md-6">
                          <div>
                            {" "}
                            <span class="fw-bolder">EMP Code</span>{" "}
                            <small class="ms-3">{user && user?.empId}</small>{" "}
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div>
                            {" "}
                            <span class="fw-bolder">EMP Name</span>{" "}
                            <small class="ms-3">
                              {user && user?.firstName + "" + user?.lastName}
                            </small>{" "}
                          </div>
                        </div>
                      </div>
                      {/* <div class="row">
                  <div class="col-md-6">
                    <div>
                      {" "}
                      <span class="fw-bolder">PF No.</span>{" "}
                      <small class="ms-3">101523065714</small>{" "}
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div>
                      {" "}
                      <span class="fw-bolder">NOD</span>{" "}
                      <small class="ms-3">28</small>{" "}
                    </div>
                  </div>
                </div> */}
                      <div class="row">
                        <div class="col-md-6">
                          <div>
                            {" "}
                            <span class="fw-bolder">UAN No.</span>{" "}
                            <small class="ms-3">{user && user?.UAN_no}</small>{" "}
                          </div>
                        </div>
                        {/* <div class="col-md-6">
                    <div>
                      {" "}
                      <span class="fw-bolder">Mode of Pay</span>{" "}
                      <small class="ms-3">SBI</small>{" "}
                    </div>
                  </div> */}
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div>
                            {" "}
                            <span class="fw-bolder">Designation</span>{" "}
                            <small class="ms-3">{user && user?.role}</small>{" "}
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div>
                            {" "}
                            <span class="fw-bolder">Ac No.</span>{" "}
                            <small class="ms-3">
                              {user &&
                                user?.accountDetails?.accountNumber &&
                                bankAccountMasking(
                                  user?.accountDetails?.accountNumber
                                )}
                            </small>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                    <table class="mt-4 table table-bordered">
                      <thead class="bg-light  text-danger">
                        <tr>
                          <th
                            style={{ width: "30%" }}
                            class="text-danger"
                            scope="col"
                          >
                            Earnings
                          </th>
                          <th
                            style={{ width: "20%" }}
                            class="text-danger"
                            scope="col"
                          >
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">Basic</th>
                          <td>
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              name="basic"
                              onChange={handleChange}
                              onBlur={handleChange}
                              value={values?.basic}
                            />
                            {errors.basic && touched.basic ? (
                              <div class="text-danger">
                                {camelToTitle(errors?.basic)}
                              </div>
                            ) : null}
                          </td>
                        </tr>

                        <tr>
                          <th scope="row">HRA</th>
                          <td>
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              onChange={handleChange("hra")}
                              onBlur={handleChange("hra")}
                              value={values?.hra}
                            />{" "}
                            {errors.hra && touched.hra ? (
                              <div class="text-danger">
                                {camelToTitle(errors.hra)}
                              </div>
                            ) : null}
                          </td>
                        </tr>

                        <tr>
                          <th scope="row">DA</th>
                          <td>
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              onChange={handleChange("da")}
                              value={values?.da}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Other Allowance</th>
                          <td>
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              onChange={handleChange("other_allowance")}
                              value={values?.other_allowance}
                            />
                          </td>
                          {/* <td></td>
                          <td></td> */}
                        </tr>
                        <tr class="border-top">
                          <th class="text-danger fw-bold" scope="row">
                            Total Earning
                          </th>
                          <td class="text-danger fw-bold">
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              readOnly={true}
                              onChange={handleChange("total_earning")}
                              value={
                                (parseFloat(values?.basic)
                                  ? parseFloat(values?.basic)
                                  : 0) +
                                (parseFloat(values?.hra)
                                  ? parseFloat(values?.hra)
                                  : 0) +
                                (parseFloat(values?.da)
                                  ? parseFloat(values?.da)
                                  : 0) +
                                (parseFloat(values?.other_allowance)
                                  ? parseFloat(values?.other_allowance)
                                  : 0)
                                // (parseFloat(values?.loans_and_advances)
                                //   ? parseFloat(values?.loans_and_advances)
                                //   : 0)
                              }
                            />
                          </td>
                          {/* <td class="text-danger fw-bold">Total Deductions</td>
                          <td class="text-danger fw-bold">
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              readOnly={true}
                              onChange={handleChange("total_deduction")}
                              value={
                                (parseFloat(values?.pf)
                                  ? parseFloat(values?.pf)
                                  : 0) +
                                (parseFloat(values?.esi)
                                  ? parseFloat(values?.esi)
                                  : 0) +
                                // (parseFloat(values?.income_tax)
                                //   ? parseFloat(values?.income_tax)
                                //   : 0) +
                                (parseFloat(values?.professional_tax)
                                  ? parseFloat(values?.professional_tax)
                                  : 0)
                                //    +
                                // (parseFloat(values?.perquisitess)
                                //   ? parseFloat(values?.perquisitess)
                                //   : 0) +
                                // (parseFloat(values?.insurance_deduction)
                                //   ? parseFloat(values?.insurance_deduction)
                                //   : 0)
                              }
                            />
                          </td> */}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="row">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="check1"
                        name="is_pf_enabled"
                        value={values.is_pf_enabled}
                        checked={values.is_pf_enabled === true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label class="form-check-label" for="check1">
                        PF deducted
                      </label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      {" "}
                      <br />{" "}
                      <span class="fw-bold text-danger d-flex">
                        Net Pay :{" "}
                        <input
                          style={{ width: 200 }}
                          class="form-control ms-2"
                          placeholder="00"
                          type="text"
                          readOnly={true}
                          onChange={handleChange("net_pay")}
                          value={
                            (parseFloat(values?.basic)
                              ? parseFloat(values?.basic)
                              : 0) +
                            (parseFloat(values?.hra)
                              ? parseFloat(values?.hra)
                              : 0) +
                            (parseFloat(values?.da)
                              ? parseFloat(values?.da)
                              : 0) +
                            (parseFloat(values?.other_allowance)
                              ? parseFloat(values?.other_allowance)
                              : 0) -
                            // (parseFloat(values?.loans_and_advances)
                            //   ? parseFloat(values?.loans_and_advances)
                            //   : 0)
                            ((parseFloat(values?.pf)
                              ? parseFloat(values?.pf)
                              : 0) +
                              (parseFloat(values?.esi)
                                ? parseFloat(values?.esi)
                                : 0) +
                              // (parseFloat(values?.income_tax)
                              //   ? parseFloat(values?.income_tax)
                              //   : 0) +
                              (parseFloat(values?.professional_tax)
                                ? parseFloat(values?.professional_tax)
                                : 0))
                            //   +
                            // (parseFloat(values?.perquisitess)
                            //   ? parseFloat(values?.perquisitess)
                            //   : 0) +
                            // (parseFloat(values?.insurance_deduction)
                            //   ? parseFloat(values?.insurance_deduction)
                            //   : 0)
                          }
                        />
                      </span>{" "}
                    </div>
                    {/* <div class="border col-md-8">
                      <div class="d-flex flex-column fw-bold pt-3 pb-3">
                        <label class="b-block mb-2" for="">
                          In Words
                        </label>
                        <textarea
                          class="form-control"
                          placeholder=""
                        ></textarea>
                      </div>
                    </div> */}

                    <div class="text-end mt-4">
                      <button type="submit" class="btn btn-primary">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <div style={{ clear: "both" }}></div>
    </div>
  );
}
