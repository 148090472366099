import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../config/api";
import Network from "../../helpers/network";
import moment from "moment";
import AppContext from "../../appContext";
import ExcelGenerator from "../../components/ExcelGenerator/ExcelGenerator";

// import "./Home.scss";

export default function AttendanceDashboard() {
  const [result, setResult] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sites, setSites] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedMonth, setSelectedMonth] = useState(
    moment().format("YYYY-MM")
  );
  const [selectedSite, setSelectedSite] = useState(null);
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const [totalAttendence, setTotalAttendence] = useState([]);

  // const getDashboard = async () => {
  //   try {
  //     const res = await Network.get(api.attendence);
  //     console.log("dashboard", res);
  //     setResult(res?.data?.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const getSites = async () => {
    try {
      const result = await Network.get(api.sites);
      setSites(result?.data?.data);
    } catch (err) {}
  };
  const goToPage = (page) => {
    navigate(page);
  };
  useEffect(() => {
    // getDashboard();
    getSites();
    getPaymentHistory();
    // attendenceExport();
  }, []);
  useEffect(() => {
    getPaymentHistory();
    // attendenceExport();
  }, [selectedSite]);
  useEffect(() => {
    getPaymentHistory();
    // attendenceExport();
  }, [selectedMonth]);
  useEffect(() => {
    getPaymentHistory();
    // attendenceExport();
  }, [search]);
  const getPaymentHistory = async () => {
    try {
      let query = { gm: true };
      if (selectedSite) {
        query["siteId"] = selectedSite;
      }
      if (selectedMonth) {
        query["gm"] = selectedMonth;
      }
      // if (search) {
      //   query["Q"] = search;
      // }
      // let params = new URLSearchParams(query).toString();
      const employeeAttendence = await Network.get(api.paymentHistory, query);
      console.log("dataa", employeeAttendence);
      setResult(employeeAttendence?.data?.data);
    } catch (err) {
      console.log(err, err?.response);
      // if (err?.response?.status === 401) alert(err?.response?.data?.message);
      appContext.showToast("error", err?.response?.data?.message);
      navigate("/");
      // console.log("101", appContext);
    }
  };

  // const attendenceExport = async () => {
  //   try {
  //     let query = { gm: true };
  //     if (selectedSite) {
  //       query["siteId"] = selectedSite;
  //     }
  //     if (selectedMonth) {
  //       query["gm"] = selectedMonth;
  //     }
  //     if (search) {
  //       query["Q"] = search;
  //     }
  //     let params = new URLSearchParams(query).toString();
  //     const employeeAttendence = await Network.get(
  //       api.attendenceExport + "?" + params
  //       // null,
  //       // {
  //       //   responseType: "blob", // important
  //       // }
  //     );
  //     setTotalAttendence(employeeAttendence.data?.data);
  //     // const url = window.URL.createObjectURL(
  //     //   new Blob([employeeAttendence?.data])
  //     // );
  //     // console.log("url>>>", url);
  //     // const link = document.createElement("a");
  //     // link.href = url;
  //     // link.setAttribute("download", `${Date.now()}.xls`);
  //     // document.body.appendChild(link);
  //     // link.click();
  //     // setResult(employeeAttendence?.data?.data);
  //   } catch (err) {
  //     console.log(err, err?.response);
  //     // if (err?.response?.status === 401) alert(err?.response?.data?.message);
  //     appContext.showToast("error", err?.response?.data?.message);
  //     navigate("/");
  //     // console.log("101", appContext);
  //   }
  // };

  const searching = (e) => {
    if (e.target.value.length > 3) {
      setSearch(e.target.value);
    } else {
      setSearch(null);
    }
  };
  // const gotoEditAttendence = (id) => {
  //   navigate("/attendance", { id: id });
  // };
  // const gotoDetailsAttendence = (id) => {
  //   navigate("/attendance-details", { id: id });
  // };
  return (
    <>
      <div class="col-md-10 col-sm-12  bashboard_boxes_open no_mar">
        {/* <div class="bashboard_boxes ">
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="dashboard_box">
                <div class="prole_pic_2"></div>
                <a
                  href="javascript:void(0);"
                  onClick={() => goToPage("/users")}
                >
                  <p>Today</p>
                  {0}
                </a>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="dashboard_box border-color_blue">
                <div class="prole_pic_2"></div>
                <a
                  href="javascript:void(0);"
                  onClick={() => goToPage("/clients")}
                >
                  <p>Total This Month</p>
                  {0}
                </a>
              </div>
            </div>
          
          </div>
        </div> */}
        <div class="container-fluid pt-3">
          <div class="row">
            <div class="col-sm-12">
              <div class="card gate_entry_details2">
                <div class="table-content  table-responsive card-body">
                  <div class="row mb-2">
                    <div class="col-sm-4">
                      <div class="row form-group mb-0">
                        <label
                          for="inputPassword"
                          class="col-sm-4 col-form-label"
                        >
                          Select Month
                        </label>
                        <div class="col-sm-8">
                          <input
                            type="month"
                            class="form-control"
                            max={moment().format("YYYY-MM")}
                            value={selectedMonth}
                            onChange={(e) => setSelectedMonth(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-4">
                      <div class="row form-group mb-0">
                        <label
                          for="inputPassword"
                          class="col-sm-4 col-form-label text-end"
                        >
                          Site
                        </label>
                        <div class="col-sm-8">
                          <select
                            class="form-control"
                            onChange={(e) => setSelectedSite(e.target.value)}
                          >
                            <option>Select site</option>
                            {sites &&
                              sites.length > 0 &&
                              sites.map((s) => (
                                <option value={s?._id}>{s.site_name}</option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-1">
                      <div class="row form-group mb-0">
                        {/* <label
                          for="inputPassword"
                          class="col-sm-4 col-form-label text-end"
                        >
                          Search
                        </label>
                        <div class="col-sm-8">
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            value={search}
                            onChange={searching}
                          />
                        </div> */}
                        {/* <button className="form-control">Search</button> */}
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="col-sm-6">
                        {/* <div>
                          <b>
                            <a
                              className="text-dark"
                              href={`/attendance?${
                                selectedMonth ? "&m=" + selectedMonth : ""
                              }`}
                            >
                              <span class="material-symbols-outlined align-middle">
                                add_circle
                              </span>
                            </a>
                          </b>
                        </div> */}
                      </div>
                      <div class="col-sm-6 text-end">
                        {result && result.length > 0 && (
                          <ExcelGenerator
                            excelData={result?.map((r) => ({
                              "Employee Name": `${r?.users?.firstName} ${r?.users?.lastName}`,
                              "Employee Code": `${r?.users?.empId}`,
                              "Work Location": `${
                                r?.users?.work_location
                                  ? r?.users?.work_location
                                  : ""
                              }`,
                              Designation: `${r?.users?.empId}`,
                              "ESI Number": `${r?.users?.ESIC_no}`,
                              "UAN Number": `${
                                r?.users?.UAN_no ? r?.users?.UAN_no : ""
                              }`,
                              "Bank Account Number": `${r?.users?.accountDetails?.accountNumber}`,
                              "IFSC Code": `${r?.users?.accountDetails?.ifscCode}`,
                              "Month Days": `${r?.totalWorkingDay}`,
                              "Working Days": `${r?.totalAttendence}`,
                              OT: `${r?.ot_hours}`,
                              "Fixed Basic": `${r?.basic}`,
                              "Fixed DA": `${r?.da}`,
                              "Fixed HRA": `${r?.hra}`,
                              "Other Allowance": `${r?.other_allowance}`,
                              "Earn Basic": `${r?.earn_basic}`,
                              "Earn DA": `${r?.earn_da}`,
                              "Earn HRA": `${r?.earn_hra}`,
                              Bonus: `${r?.earn_bonus}`,
                              "Earn Leave with": `${r?.earn_leave_with}`,
                              Incentive: `${r?.incentive}`,
                              ESI: `${r?.esi}`,
                              PF: `${r?.pf}`,
                              "P-Tax": `${r?.professional_tax}`,
                              "Net Pay": `${r?.netPay}`,
                            }))}
                            fileName={Date.now()}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <table
                    id="example"
                    class="table   dt-responsive "
                    width="100%"
                  >
                    <thead>
                      <tr>
                        <th class="table-primary" width="30%">
                          Employee Name
                        </th>
                        <th class="table-primary">Employee Code</th>
                        <th class="table-primary  text-center">
                          Work location
                        </th>
                        <th class="table-primary  text-center">Designation</th>
                        <th class="table-primary  text-center">ESI Number</th>
                        <th class="table-primary  text-center">UAN Number</th>
                        <th class="table-primary  text-center">
                          Bank Account Number
                        </th>
                        <th class="table-primary  text-center">IFSC Code</th>
                        <th class="table-primary  text-center">Month Days</th>
                        <th class="table-primary  text-center">Working Days</th>
                        <th class="table-primary  text-center">OT</th>{" "}
                        <th class="table-primary  text-center">Fixed Basic</th>{" "}
                        <th class="table-primary  text-center">Fixed DA</th>{" "}
                        <th class="table-primary  text-center">Fixed HRA</th>{" "}
                        <th class="table-primary  text-center">
                          Other Allowance
                        </th>{" "}
                        <th class="table-primary  text-center">Earn Basic</th>{" "}
                        <th class="table-primary  text-center">Earn DA</th>{" "}
                        <th class="table-primary  text-center">Earn HRA</th>{" "}
                        <th class="table-primary  text-center">Bonus</th>
                        <th class="table-primary  text-center">
                          Earn Leave with
                        </th>
                        <th class="table-primary  text-center">Incentive</th>
                        <th class="table-primary  text-center">ESI</th>
                        <th class="table-primary  text-center">PF</th>
                        <th class="table-primary  text-center">P-Tax</th>
                        <th class="table-primary  text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {result &&
                        result.length > 0 &&
                        result?.map((r) => (
                          <tr>
                            <td>{`${r?.users?.firstName} ${r?.users?.lastName}`}</td>
                            <td>{`${r?.users?.empId}`}</td>
                            <td>{`${
                              r?.users?.work_location
                                ? r?.users?.work_location
                                : ""
                            }`}</td>
                            <td>{`${r?.users?.designation}`}</td>
                            {/* <td>{`${r?.users?.empId}`}</td> */}
                            <td>{`${r?.users?.ESIC_no}`}</td>
                            <td>{`${
                              r?.users?.UAN_no ? r?.users?.UAN_no : ""
                            }`}</td>
                            <td>{`${r?.users?.accountDetails?.accountNumber}`}</td>
                            <td>{`${r?.users?.accountDetails?.ifscCode}`}</td>
                            <td>{`${r?.totalWorkingDay}`}</td>
                            <td>{`${r?.totalAttendence}`}</td>
                            <td>{`${r?.ot_hours}`}</td>
                            <td>{`${r?.basic}`}</td>
                            <td>{`${r?.da}`}</td>
                            <td>{`${r?.hra}`}</td>
                            <td>{`${
                              r?.other_allowance ? r?.other_allowance : 0
                            }`}</td>
                            <td>{`${r?.earn_basic}`}</td>
                            <td>{`${r?.earn_da}`}</td>
                            <td>{`${r?.earn_hra}`}</td>
                            <td>{`${r?.earn_bonus}`}</td>
                            <td>{`${r?.earn_leave_with}`}</td>
                            <td>{`${r?.incentive}`}</td>
                            <td>{`${r?.esi}`}</td>
                            <td>{`${r?.pf}`}</td>
                            <td>{`${r?.professional_tax}`}</td>
                            <td>
                              <a
                                href={`${process.env.REACT_APP_API_URL}${r?.paySlipGenaret}`}
                                target="_blank"
                              >
                                Payslip
                              </a>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
