import api from "../../config/api";
import Network, { setToken } from "../../helpers/network";
import axios from "axios";
export const ACTION_SUBMIT_LOGIN_SUCCESS = "ACTION_SUBMIT_LOGIN_SUCCESS";
export const ACTION_LOGIN_FAILURE = "ACTION_LOGIN_FAILURE";
export const ACTION_LOGIN_REQUEST = "ACTION_LOGIN_REQUEST";

export const ACTION_SUBMIT_FORGOTPASSWORD_SUCCESS =
  "ACTION_SUBMIT_FORGOTPASSWORD_SUCCESS";
export const ACTION_FORGOTPASSWORD_FAILURE = "ACTION_FORGOTPASSWORD_FAILURE";
export const ACTION_FORGOTPASSWORD_REQUEST = "ACTION_FORGOTPASSWORD_REQUEST";

export const RESET_STORE = "RESET_STORE";
export const ERROR_SET = "ERROR_SET";

const initialState = {
  user: null,
  isLogin: false,
  isSubmitted: false,
  isProcessing: null,
  isAuthenticated: false,
  isError: false,
  message: "",
};
export const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_SUBMIT_LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isLogin: true,
        isSubmitted: false,
        isProcessing: false,
        isAuthenticated: true,
      };
    case ACTION_LOGIN_FAILURE:
      return {
        ...state,
        user: {},
        isLogin: false,
        isProcessing: false,
        isAuthenticated: false,
      };
    case ACTION_LOGIN_REQUEST:
      return { ...state, isSubmitted: true, isProcessing: true };
    case ACTION_FORGOTPASSWORD_REQUEST:
      return {
        ...state,
        isLogin: false,
        isSubmitted: true,
        isProcessing: true,
      };
    case ACTION_SUBMIT_FORGOTPASSWORD_SUCCESS:
      return { ...state, isSubmitted: false, isProcessing: false };
    case ACTION_FORGOTPASSWORD_FAILURE:
      return { ...state, isProcessing: false };
    case ERROR_SET:
      return {
        ...state,
        isLogin: false,
        isError: true,
        message: action.payload.message,
      };
    default:
      return state;
  }
};

export const login = (email, password) => (dispatch) => {
  return new Promise((resolve, reject) => {
    try {
      dispatch({ type: ACTION_LOGIN_REQUEST });
      Network.post(api.login, { email, password })
        .then((res) => {
          console.log("res>>>", res);
          dispatch({
            type: ACTION_SUBMIT_LOGIN_SUCCESS,
            payload: res?.data?.data,
          });
          setToken(res?.data?.data?.token);

          localStorage.setItem("token", res?.data?.data?.token);
          return resolve({ status: true, data: res?.data?.data });
        })
        .catch((err) => {
          dispatch({ type: ACTION_LOGIN_FAILURE });
          return reject(err);
        });
    } catch (err) {
      console.log("error", err);
      dispatch({ type: ACTION_LOGIN_FAILURE });
      return reject(err);
    }
  });
};
export const forgotPassword = (email) => (dispatch) => {
  return new Promise((resolve, reject) => {
    try {
      dispatch({ type: ACTION_FORGOTPASSWORD_REQUEST });
      Network.post(api.forgotPassword, { email })
        .then((res) => {
          dispatch({
            type: ACTION_SUBMIT_FORGOTPASSWORD_SUCCESS,
            payload: res?.data,
          });
          resolve({
            status: true,
            message: res?.data?.message,
            redirect: res?.data?.redirect,
          });
        })
        .catch((err) => {
          console.log("error", err);
          dispatch({ type: ACTION_FORGOTPASSWORD_FAILURE });
          reject(err);
        });
    } catch (err) {
      console.log("error", err);
      dispatch({ type: ACTION_FORGOTPASSWORD_FAILURE });
      reject(err);
    }
  });
};
export const resetPassword = (password, token) => (dispatch) => {
  return new Promise((resolve, reject) => {
    try {
      dispatch({ type: ACTION_FORGOTPASSWORD_REQUEST });
      Network.post(
        api.resetPassword,
        { password },
        {
          headers: {
            "x-access-token": `${token}`,
          },
        }
      )
        .then((res) => {
          dispatch({
            type: ACTION_SUBMIT_FORGOTPASSWORD_SUCCESS,
            payload: res?.data,
          });
          resolve({
            status: true,
            message: res?.data?.message,
            redirect: res?.data?.redirect,
          });
        })
        .catch((err) => {
          dispatch({ type: ACTION_FORGOTPASSWORD_FAILURE });
          reject(err);
        });
    } catch (err) {
      console.log("error", err);
      dispatch({ type: ACTION_FORGOTPASSWORD_FAILURE });
      reject(err);
    }
  });
};
export const errorHandler = (error) => (dispatch) => {
  return new Promise((resolve, reject) => {
    switch (error.response?.status) {
      case 401:
        // dispatch({
        //   type: ERROR_SET,
        //   payload: { message: "Token expired" },
        // });
        alert("session expired! Please login again");

        setTimeout(() => {
          return resolve(dispatch(logout()));
        }, 100);
      default:
    }
  });
};
export const logout = () => (dispatch) => {
  sessionStorage.removeItem("token");
  return dispatch({
    type: RESET_STORE,
  });
};
