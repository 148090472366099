import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "./../../redux/reducers/authReducer";
import Dropdown from "react-bootstrap/Dropdown";
export default function NavBar(props) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state?.auth?.user);
  const logoutHandler = () => {
    try {
      const res = dispatch(logout());
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <header>
        <div class="row">
          <div class="col-md-2 col-sm-3 no_mar">
            <div class="dashboard_left_bar_top">
              <div class="left_nav_topbar">
                <a
                  href="javascript:void(0);"
                  class="material-symbols-outlined nav_open"
                >
                  {" "}
                  menu{" "}
                </a>
                <div class="left_logo">
                  <a href="#">
                    <img src="images/logo.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-10 col-sm-9 no_mar">
            <nav
              class="navbar navbar-expand-lg bg-white p-0"
              style={{ minHeight: 72 }}
            >
              <div class="container-fluid">
                <button
                  class="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div
                  class="collapse navbar-collapse d-flex justify-content-between"
                  id="navbarSupportedContent"
                >
                  <ul class="navbar-nav"></ul>
                  <div class="dash_bord_bar">
                    <div class="heading_dashboard">
                      <span class="material-symbols-outlined">
                        other_houses
                      </span>{" "}
                      Dashboard
                    </div>

                    <div class="welcome_text">
                      Welcome to Retailpos Sachin !
                    </div>
                  </div>
                  <ul class="navbar-nav mb-2 mb-lg-0">
                    <li class="nav-item dropdown no-icon">
                      <div class="dropdown">
                        <a
                          class="dropdown-toggle nav-link"
                          href="#"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span class="material-symbols-outlined">
                            {" "}
                            search{" "}
                          </span>
                        </a>
                        <ul
                          class="dropdown-menu dropdown-menu-end p-2 "
                          style={{ width: 300 }}
                        >
                          <li class="d-flex" role="search">
                            <input
                              class="form-control me-2"
                              type="search"
                              placeholder="Search"
                              aria-label="Search"
                            />
                            <button
                              class="btn btn-outline-success"
                              type="submit"
                            >
                              Search
                            </button>
                          </li>
                        </ul>
                      </div>
                    </li>
                    {/* <li class="nav-item">
                      <a class="nav-link" href="#">
                        <span class="material-symbols-outlined">
                          {" "}
                          notifications{" "}
                        </span>
                      </a>
                    </li> */}
                    <li class="nav-item dropdown profile_right_main mb-0">
                      <Dropdown>
                        {/* <a
                          class="nav-link dropdown-toggle mb-0"
                          href="#"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        > */}
                        <Dropdown.Toggle
                          //   id="dropdown-basic"
                          className="bg-white border-0"
                        >
                          <img
                            src="https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG-Image.png"
                            alt=""
                            style={{ width: 36 }}
                          />
                          {/* </a> */}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            href="javascript:void(0);"
                            onClick={logoutHandler}
                          >
                            Logout
                          </Dropdown.Item>
                        </Dropdown.Menu>
                        {/* <ul class="dropdown-menu-1">
                          <li>
                            <a class="dropdown-item" href="#">
                              User Name
                            </a>
                          </li>
                          <li>
                            <a class="dropdown-item" href="#">
                              Logout
                            </a>
                          </li>
                        </ul> */}
                      </Dropdown>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
}
