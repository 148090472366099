import * as Yup from "yup";

export const AddSiteValidation = Yup.object().shape({
  site_name: Yup.string().trim().required(),
  site_address: Yup.string().trim().required(),
  site_city: Yup.string().trim().required(),
  site_pincode: Yup.string()
    .required()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(6, "Must be exactly 6 digits")
    .max(6, "Must be exactly 6 digits"),
});

export const EditSiteValidation = Yup.object().shape({
  site_name: Yup.string().trim().required(),
  site_address: Yup.string().trim().required(),
  site_city: Yup.string().trim().required(),
  site_pincode: Yup.string()
    .required()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(6, "Must be exactly 6 digits")
    .max(6, "Must be exactly 6 digits"),
});
