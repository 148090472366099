import React, { useEffect, useState } from "react";
import config from "../../config/config";
import Network from "../../helpers/network";
import api from "../../config/api";
import { timeFormated } from "../../helpers/time";
import { Navigate, useNavigate,useSearchParams} from "react-router-dom";
import Pagination from "../../components/Pagination/Pagination.js";

export default function SalaryInfo() {
   
  const [result, setResult] = useState([]);
  const [page, setPage] = useState(1);
  const [totalUser, setTotalUser] = useState(0);
  const [isError, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [message, setMessage] = useState(null);
  const [search, setSearch] = useState(null);
  const [status, setStatus] = useState("active");
  const [searchParams, setSearchParams] = useSearchParams();
  const [salaryId, setSalaryId] = useState([]);
 
  const navigate = useNavigate();
 
  const getSingleSalaryList = async (id) => {
    try {
        
      let res;
      if (search && search.length > 3) {
        res = await Network.get(api.getSalaryById,{
         id:id
        });
      } else {
        res = await Network.get(api.getSalaryById, {
          id:id
        });
      }

      console.log(res?.data?.data);
      setResult(res?.data?.data);
     
    } catch (error) {
      console.log(error);
    }
  };

  const approvedSalary=async()=>{
    console.log("hello");
  }
  console.log(result);
  useEffect(() => {
    getSingleSalaryList(searchParams.get("id"))
  }, []);

  return (
    <>
      <div class="col-md-10 col-sm-12  bashboard_boxes_open no_mar mb-2">
        <div class="purchase_order_buttom_nav mt-0">
          <div class=" mt-0 mb-0">
            <nav class="navbar bg-body-tertiary">
              <div class="container-fluid">
                <form class="d-flex ">
                  <h6 class="navbar-brand text-white">Salary View</h6>
                </form>
                <ul class="d-flex nav justify-content-end">
                  <li class="nav-item">
                    <a href="#">
                      <button class="nav-link bg-primary text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined">
                          add_circle
                        </span>
                      </button>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="flow_through_details.html">
                      <button class="nav-link bg-success text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined">edit_note</span>
                      </button>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="#">
                      <button class="nav-link bg-success text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined d-block">
                          {" "}
                          save{" "}
                        </span>
                      </button>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="#">
                      <button class="nav-link bg-danger text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined">cancel</span>{" "}
                      </button>
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div class="container-fluid pt-3">
          <div class="row">
           <div class="col-sm-3">
            <label>Name</label>
            <input type="text" class="form-control" value={result?.userId?.firstName} readOnly/>
           </div>
           <div class="col-sm-3">
           <label>Email</label>
            <input type="text" class="form-control" value={result?.userId?.email} readOnly/>
           </div>
           <div class="col-sm-3">
           <label>Number</label>
            <input type="text" class="form-control" value={result?.userId?.phone} readOnly/>
           </div>
           <div class="col-sm-3">
           <label>Basic</label>
            <input type="text" class="form-control" value={result?.basic} readOnly/>
           </div>
           <div class="col-sm-3">
           <label>Da</label>
            <input type="text" class="form-control" value={result?.da} readOnly/>
           </div>
           <div class="col-sm-3">
           <label>Hra</label>
            <input type="text" class="form-control" value={result?.hra} readOnly/>
           </div>
           <div class="col-sm-3">
           <label>Other Allowance</label>
            <input type="text" class="form-control" value={result?.other_allowance} readOnly/>
           </div>
           <div class="col-sm-3">
           <label>All Cash Reimbursement</label>
            <input type="text" class="form-control" value={result?.all_cash_reimbursement} readOnly/>
           </div>
           <div class="col-sm-3">
           <label>Lta</label>
            <input type="text" class="form-control" value={result?.lta} readOnly/>
           </div>
           <div class="col-sm-3">
           <label>Medical</label>
            <input type="text" class="form-control" value={result?.medical} readOnly/>
           </div>
           <div class="col-sm-3">
           <label>Arrears</label>
            <input type="text" class="form-control" value={result?.arrears} readOnly/>
           </div>
           <div class="col-sm-3">
           <label>Pf</label>
            <input type="text" class="form-control" value={result?.pf} readOnly/>
           </div>
           <div class="col-sm-3">
           <label>Esi</label>
            <input type="text" class="form-control" value={result?.esi} readOnly/>
           </div>
           <div class="col-sm-3">
           <label>Income Tax</label>
            <input type="text" class="form-control" value={result?.income_tax} readOnly/>
           </div>
           <div class="col-sm-3">
           <label>Professional Tax</label>
            <input type="text" class="form-control" value={result?.professional_tax} readOnly/>
           </div>
           <div class="col-sm-3">
           <label>Loans And Advances</label>
            <input type="text" class="form-control" value={result?.loans_and_advances} readOnly/>
           </div>
           <div class="col-sm-3">
           <label>Perquisites</label>
            <input type="text" class="form-control" value={result?.perquisites} readOnly/>
           </div>
           <div class="col-sm-3">
           <label>Status</label>
            <input type="text" class="form-control" value={result?.status} readOnly/>
           </div>
           <div class="col-sm-3">
            <label>.</label>
           <button type="submit" class="form-control btn btn-danger" onClick={approvedSalary}>Approved</button>
           </div>
          </div>
        </div>
      </div>
    </>
  );
}
