import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Network from "../../helpers/network";
import api from "../../config/api";
import { Formik, setIn } from "formik";
import { EmployeeSalaryBreakDownValidation } from "./Employee.validation";
import { bankAccountMasking, camelToTitle } from "../../helpers/data";
import { Toast } from "bootstrap";
import AppContext from "../../appContext";

export default function EmployeeSalaryPayForm() {
  const [user, setUser] = useState(null);
  const [userId, setUserId] = useState(null);
  const [isError, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [message, setMessage] = useState(null);
  const appContext = useContext(AppContext);
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [initialValues, setInitialValues] = useState({
    basic: 0,
    da: 0,
    hra: 0,
    other_allowance: 0,
    pf: 0,
    esi: 0,
    professional_tax: 0,
    earn_da: 0,
    earn_basic: 0,
    earn_hra: 0,
    ot_hours: 0,
    incentive: 0,
    earn_bonus: 0,
    earn_other_allowance: 0,
    earn_leave_with: 0,
    total_deduction: 0,
    total_earning: 0,
    net_pay: 0,
    totalWorkingDays: 0,
    totalAttendence: 0,
  });
  const getUser = async (id) => {
    try {
      if (id) {
        setUserId(searchParams.get("id"));
        getSalaryBreakDown(searchParams.get("id"));
        const result = await Network.get(api.getUser, { id: id });
        console.log("user >>", result);
        setUser(result?.data?.data);
        // setMessage("User has been fatched successfully");
        // setSuccess(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const getSalaryBreakDown = async (id) => {
    try {
      if (id) {
        const result = await Network.get(api.salaryList, { id: id });
        console.log(result);
        const salaryDetails = result?.data?.data;
        if (salaryDetails) {
          // const fixSalary =
          //   salaryDetails?.data?.basic +
          //   salaryDetails?.data?.hra +
          //   salaryDetails?.data?.da +
          //   salaryDetails?.data?.other_allowance;

          // const perHoursIncentive =
          //   ((salaryDetails?.data?.basic +
          //     salaryDetails?.data?.hra +
          //     salaryDetails?.data?.da) /
          //     26 /
          //     8) *
          //   2;
          // const grossSalary =
          //   salaryDetails?.earn_da +
          //   salaryDetails?.earn_hra +
          //   salaryDetails?.earn_basic;

          setInitialValues((old) => ({
            ...old,
            ...salaryDetails?.data,
            earn_da: salaryDetails?.earn_da,
            earn_hra: salaryDetails?.earn_hra,
            earn_basic: salaryDetails?.earn_basic,
            earn_other_allowance: salaryDetails?.earn_other_allowance,
            earn_bonus: salaryDetails?.earn_bonus,
            earn_leave_with: salaryDetails?.earn_leave_with,
            totalWorkingDays: salaryDetails?.total_days,
            totalAttendence: salaryDetails?.totalWorkingDays,
            perHoursIncentive: salaryDetails?.perHoursIncentive,
            incentive: salaryDetails?.incentive,
            ot_hours: salaryDetails?.ot_hours,
            fixSalary: salaryDetails?.fixSalary,
            grossSalary: salaryDetails?.grossSalary,
            pf: salaryDetails?.pf,
            esi: salaryDetails?.esi,
            professional_tax: salaryDetails?.professional_tax,
            total_earning: salaryDetails?.grossSalary,
            total_deduction:
              salaryDetails?.pf +
              salaryDetails?.esi +
              salaryDetails?.professional_tax,
            net_pay:
              salaryDetails?.grossSalary -
              (salaryDetails?.pf +
                salaryDetails?.esi +
                salaryDetails?.professional_tax),
          }));
        } else {
          alert("No Salary breakdown info present in system. Please add first");
          navigate("/employee-salary-details?id=" + id);
        }
        // setUser(result?.data?.data);
        // setMessage("User has been fatched successfully");
        // setSuccess(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const paySalary = async (values, resetForm) => {
    try {
      console.log("values", values);
      // return;
      // let { total_earning, total_deduction, net_pay, ...others } = values;
      // total_earning =
      //   (parseFloat(values?.basic) ? parseFloat(values?.basic) : 0) +
      //   (parseFloat(values?.hra) ? parseFloat(values?.hra) : 0) +
      //   (parseFloat(values?.other_allowance)
      //     ? parseFloat(values?.other_allowance)
      //     : 0);
      // total_deduction =
      //   (parseFloat(values?.pf) ? parseFloat(values?.pf) : 0) +
      //   (parseFloat(values?.esi) ? parseFloat(values?.esi) : 0) +
      //   (parseFloat(values?.professional_tax)
      //     ? parseFloat(values?.professional_tax)
      //     : 0);
      // net_pay =
      //   (parseFloat(values?.basic) ? parseFloat(values?.basic) : 0) +
      //   (parseFloat(values?.hra) ? parseFloat(values?.hra) : 0) +
      //   (parseFloat(values?.other_allowance)
      //     ? parseFloat(values?.other_allowance)
      //     : 0) +
      //   -(
      //     (parseFloat(values?.pf) ? parseFloat(values?.pf) : 0) +
      //     (parseFloat(values?.esi) ? parseFloat(values?.esi) : 0) +
      //     (parseFloat(values?.professional_tax)
      //       ? parseFloat(values?.professional_tax)
      //       : 0)
      //   );
      const res = await Network.post(api.paySalary + "?userId=" + userId, {
        // ...others,
        // total_earning,
        // total_deduction,
        // net_pay,
        ...values,
        // userId: userId,
      });
      console.log(res);
      // alert("Payment has been completed");
      appContext.showToast("success", res?.data?.message);
    } catch (err) {
      console.log(err);
      appContext.showToast("error", err?.response?.data?.message);
      // alert("Error occur! Please try after sometime");
    }
  };
  useEffect(() => {
    // setUserId(searchParams.get("id"));
    getUser(searchParams.get("id"));
  }, [searchParams]);
  return (
    <div class="col-md-10 col-sm-12  bashboard_boxes_open no_mar mb-2">
      <div class="bg-white mt-3 rounded p-4 m-4`">
        <div class="row">
          <Formik
            initialValues={{ ...initialValues }}
            onSubmit={(values, { resetForm }) => {
              paySalary(values, resetForm);
            }}
            validationSchema={EmployeeSalaryBreakDownValidation}
            enableReinitialize={true}
          >
            {({
              handleChange,
              handleSubmit,
              errors,
              touched,
              values,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                {isError && <div class="alert alert-danger">{message}</div>}
                {isSuccess && <div class="alert alert-success">{message}</div>}
                <div class="col-md-12">
                  <div class=" lh-1 mb-2">
                    <h6 class="fw-bold">Pay structure</h6>{" "}
                    {/* <span class="fw-normal">
                Payment slip for the month of June 2023
              </span> */}
                  </div>
                  <div class="d-flex justify-content-end">
                    <span>
                      Working Branch:{user && user?.siteId?.site_name}{" "}
                    </span>{" "}
                  </div>
                  <div class="row">
                    <div class="col-md-10">
                      <div class="row">
                        <div class="col-md-6">
                          <div>
                            {" "}
                            <span class="fw-bolder">EMP Code</span>{" "}
                            <small class="ms-3">{user && user?.empId}</small>{" "}
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div>
                            {" "}
                            <span class="fw-bolder">EMP Name</span>{" "}
                            <small class="ms-3">
                              {user && user?.firstName + "" + user?.lastName}
                            </small>{" "}
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div>
                            {" "}
                            <span class="fw-bolder">UAN No.</span>{" "}
                            <small class="ms-3">{user && user?.UAN_no}</small>{" "}
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div>
                            {" "}
                            <span class="fw-bolder">Designation</span>{" "}
                            <small class="ms-3">{user && user?.role}</small>{" "}
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div>
                            {" "}
                            <span class="fw-bolder">Ac No.</span>{" "}
                            <small class="ms-3">
                              {user &&
                                user?.accountDetails &&
                                user?.accountDetails?.accountNumber &&
                                bankAccountMasking(
                                  user?.accountDetails?.accountNumber
                                )}
                            </small>{" "}
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div>
                            {" "}
                            <span class="fw-bolder">
                              Total working days
                            </span>{" "}
                            <input
                              type="number"
                              readOnly
                              // className="form-control"
                              onChange={handleChange("totalWorkingDays")}
                              value={values?.totalWorkingDays}
                            />
                            {errors.totalWorkingDays &&
                            touched.totalWorkingDays ? (
                              <div class="text-danger">
                                {camelToTitle(errors.totalWorkingDays)}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div>
                            {" "}
                            <span class="fw-bolder">Total Present</span>{" "}
                            <input
                              type="number"
                              readOnly
                              // className="form-control"
                              onChange={handleChange("totalAttendence")}
                              value={values?.totalAttendence}
                            />
                            {errors.totalAttendence &&
                            touched.totalAttendence ? (
                              <div class="text-danger">
                                {camelToTitle(errors.totalAttendence)}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <table class="mt-4 table table-bordered">
                      <thead class="bg-light  text-danger">
                        <tr>
                          <th
                            style={{ width: "30%" }}
                            class="text-danger"
                            scope="col"
                          >
                            Earnings
                          </th>
                          <th
                            style={{ width: "20%" }}
                            class="text-danger"
                            scope="col"
                          >
                            Amount
                          </th>
                          <th
                            style={{ width: "30%" }}
                            class="text-danger"
                            scope="col"
                          >
                            Deductions
                          </th>
                          <th
                            style={{ width: "20%" }}
                            class="text-danger"
                            scope="col"
                          >
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">Basic ({values?.basic})</th>
                          <td>
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              readOnly
                              onChange={handleChange("basic")}
                              value={values?.earn_basic}
                            />
                            {errors.earn_basic && touched.earn_basic ? (
                              <div class="text-danger">
                                {camelToTitle(errors.earn_basic)}
                              </div>
                            ) : null}
                          </td>
                          <td>PF</td>
                          <td>
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              readOnly
                              onChange={handleChange("pf")}
                              value={values?.pf}
                            />
                            {errors.pf && touched.pf ? (
                              <div class="text-danger">
                                {camelToTitle(errors.pf)}
                              </div>
                            ) : null}
                          </td>
                        </tr>

                        <tr>
                          <th scope="row">HRA ({values?.hra})</th>
                          <td>
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              readOnly
                              onChange={handleChange("earn_hra")}
                              value={values?.earn_hra}
                            />{" "}
                            {errors.earn_hra && touched.earn_hra ? (
                              <div class="text-danger">
                                {camelToTitle(errors.earn_hra)}
                              </div>
                            ) : null}
                          </td>
                          <td>E.S.I</td>
                          <td>
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              readOnly
                              onChange={handleChange("esi")}
                              value={values?.esi}
                            />
                            {errors.esi && touched.esi ? (
                              <div class="text-danger">
                                {camelToTitle(errors.esi)}
                              </div>
                            ) : null}
                          </td>
                        </tr>
                        {/* <tr>
                          <th scope="row">Medical Allowance</th>
                          <td>
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              onChange={handleChange("medical")}
                              value={values?.medical}
                            />
                            {errors.medical && touched.medical ? (
                              <div class="text-danger">
                                {camelToTitle(errors.medical)}
                              </div>
                            ) : null}
                          </td>
                          <td>Income tax</td>
                          <td>
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              onChange={handleChange("income_tax")}
                              value={values?.income_tax}
                            />
                            {errors.income_tax && touched.income_tax ? (
                              <div class="text-danger">
                                {camelToTitle(errors.income_tax)}
                              </div>
                            ) : null}
                          </td>
                        </tr> */}
                        <tr>
                          <th scope="row">
                            DA (<span>{values?.da}</span>)
                          </th>
                          <td>
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              readOnly
                              onChange={handleChange("earn_da")}
                              value={values?.earn_da}
                            />
                          </td>
                          <td>Professional tax</td>
                          <td>
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              readOnly
                              onChange={handleChange("professional_tax")}
                              value={values?.professional_tax}
                            />
                            {errors.professional_tax &&
                            touched.professional_tax ? (
                              <div class="text-danger">
                                {camelToTitle(errors.professional_tax)}
                              </div>
                            ) : null}
                          </td>
                        </tr>
                        {/* <tr>
                          <th scope="row">Loans and advances</th>
                          <td>
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              onChange={handleChange("loans_and_advances")}
                              value={values?.loans_and_advances}
                            />
                            {errors.loans_and_advances &&
                            touched.loans_and_advances ? (
                              <div class="text-danger">
                                {camelToTitle(errors.loans_and_advances)}
                              </div>
                            ) : null}
                          </td>
                          <td>SPL. Deduction</td>
                          <td>
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              onChange={handleChange("perquisites")}
                              value={values?.perquisites}
                            />
                            {errors.perquisites && touched.perquisites ? (
                              <div class="text-danger">
                                {camelToTitle(errors.perquisites)}
                              </div>
                            ) : null}
                          </td>
                        </tr> */}
                        <tr>
                          <th scope="row">
                            Other allowance ({values?.other_allowance})
                          </th>
                          <td>
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              readOnly
                              onChange={handleChange("earn_other_allowance")}
                              value={values?.earn_other_allowance}
                            />{" "}
                            {errors.earn_other_allowance &&
                            touched.earn_other_allowance ? (
                              <div class="text-danger">
                                {camelToTitle(errors.earn_other_allowance)}
                              </div>
                            ) : null}
                          </td>

                          {/* <td>Insurance Deduction</td>
                          <td>
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              onChange={handleChange("insurance_deduction")}
                              value={values?.insurance_deduction}
                            />
                            {errors.insurance_deduction &&
                            touched.insurance_deduction ? (
                              <div class="text-danger">
                                {camelToTitle(errors.insurance_deduction)}
                              </div>
                            ) : null}
                          </td> */}
                        </tr>
                        <tr>
                          <th scope="row">Earn Leave with</th>
                          <td>
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              readOnly
                              onChange={handleChange("earn_leave_with")}
                              value={values?.earn_leave_with}
                            />{" "}
                            {errors.earn_leave_with &&
                            touched.earn_leave_with ? (
                              <div class="text-danger">
                                {camelToTitle(errors.earn_leave_with)}
                              </div>
                            ) : null}
                          </td>

                          {/* <td>Insurance Deduction</td>
                          <td>
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              onChange={handleChange("insurance_deduction")}
                              value={values?.insurance_deduction}
                            />
                            {errors.insurance_deduction &&
                            touched.insurance_deduction ? (
                              <div class="text-danger">
                                {camelToTitle(errors.insurance_deduction)}
                              </div>
                            ) : null}
                          </td> */}
                        </tr>
                        <tr>
                          <th scope="row">Earn Bonus</th>
                          <td>
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              readOnly
                              onChange={handleChange("earn_bonus")}
                              value={values?.earn_bonus}
                            />{" "}
                            {errors.earn_bonus && touched.earn_bonus ? (
                              <div class="text-danger">
                                {camelToTitle(errors.earn_bonus)}
                              </div>
                            ) : null}
                          </td>

                          {/* <td>Insurance Deduction</td>
                          <td>
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              onChange={handleChange("insurance_deduction")}
                              value={values?.insurance_deduction}
                            />
                            {errors.insurance_deduction &&
                            touched.insurance_deduction ? (
                              <div class="text-danger">
                                {camelToTitle(errors.insurance_deduction)}
                              </div>
                            ) : null}
                          </td> */}
                        </tr>
                        <tr>
                          <th scope="row">OT hours</th>
                          <td>
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              name="ot_hours"
                              value={values.ot_hours}
                              onChange={(e) => {
                                setFieldValue("ot_hours", e.target.value);
                                const earn_incentive =
                                  e.target.value && parseInt(e.target.value) > 0
                                    ? (
                                        parseInt(e.target.value) *
                                        initialValues.perHoursIncentive
                                      ).toFixed(0)
                                    : 0;
                                setFieldValue("incentive", earn_incentive);
                                const total_gross_salary =
                                  parseFloat(earn_incentive) +
                                  parseFloat(values.grossSalary);
                                setFieldValue(
                                  "total_earning",
                                  total_gross_salary
                                );
                                const esi = Math.round(
                                  total_gross_salary > 21000
                                    ? 0
                                    : total_gross_salary * (0.75 / 100),
                                  0
                                );
                                const pTax =
                                  total_gross_salary > 25000 ? 200 : 0;
                                setFieldValue("esi", esi);
                                setFieldValue("professional_tax", pTax);
                                const total_net_pay =
                                  total_gross_salary - (esi + pTax + values.pf);
                                setFieldValue("net_pay", total_net_pay);
                                setFieldValue(
                                  "total_deduction",
                                  esi + pTax + values.pf
                                );
                              }}
                            />{" "}
                            {errors.ot_hours && touched.ot_hours ? (
                              <div class="text-danger">
                                {camelToTitle(errors.ot_hours)}
                              </div>
                            ) : null}
                          </td>

                          {/* <td>Insurance Deduction</td>
                          <td>
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              onChange={handleChange("insurance_deduction")}
                              value={values?.insurance_deduction}
                            />
                            {errors.insurance_deduction &&
                            touched.insurance_deduction ? (
                              <div class="text-danger">
                                {camelToTitle(errors.insurance_deduction)}
                              </div>
                            ) : null}
                          </td> */}
                        </tr>
                        <tr>
                          <th scope="row">Incentive</th>
                          <td>
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              readOnly
                              onChange={handleChange("incentive")}
                              value={values?.incentive}
                            />{" "}
                            {errors.incentive && touched.incentive ? (
                              <div class="text-danger">
                                {camelToTitle(errors.incentive)}
                              </div>
                            ) : null}
                          </td>

                          {/* <td>Insurance Deduction</td>
                          <td>
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              onChange={handleChange("insurance_deduction")}
                              value={values?.insurance_deduction}
                            />
                            {errors.insurance_deduction &&
                            touched.insurance_deduction ? (
                              <div class="text-danger">
                                {camelToTitle(errors.insurance_deduction)}
                              </div>
                            ) : null}
                          </td> */}
                        </tr>
                        <tr class="border-top">
                          <th class="text-danger fw-bold" scope="row">
                            Total Earning
                          </th>
                          <td class="text-danger fw-bold">
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              readOnly={true}
                              onChange={handleChange("total_earning")}
                              value={
                                parseFloat(values?.total_earning)
                                //  +
                                // parseFloat(values?.incentive)
                                // (parseFloat(values?.basic)
                                //   ? parseFloat(values?.basic)
                                //   : 0) +
                                // (parseFloat(values?.hra)
                                //   ? parseFloat(values?.hra)
                                //   : 0) +
                                // (parseFloat(values?.medical)
                                //   ? parseFloat(values?.medical)
                                //   : 0) +
                                // (parseFloat(values?.other_allowance)
                                //   ? parseFloat(values?.other_allowance)
                                //   : 0) +
                                // (parseFloat(values?.loans_and_advances)
                                //   ? parseFloat(values?.loans_and_advances)
                                //   : 0) +
                                // (parseFloat(values?.loans_and_advances)
                                //   ? parseFloat(values?.loans_and_advances)
                                //   : 0)
                              }
                            />
                          </td>
                          <td class="text-danger fw-bold">Total Deductions</td>
                          <td class="text-danger fw-bold">
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              readOnly={true}
                              onChange={handleChange("total_deduction")}
                              value={
                                values.total_deduction
                                // (parseFloat(values?.pf)
                                //   ? parseFloat(values?.pf)
                                //   : 0) +
                                // (parseFloat(values?.esi)
                                //   ? parseFloat(values?.esi)
                                //   : 0) +
                                // // (parseFloat(values?.income_tax)
                                // //   ? parseFloat(values?.income_tax)
                                // //   : 0) +
                                // (parseFloat(values?.professional_tax)
                                //   ? parseFloat(values?.professional_tax)
                                //   : 0)
                                // (parseFloat(values?.perquisites)
                                //   ? parseFloat(values?.perquisites)
                                //   : 0) +
                                // (parseFloat(values?.insurance_deduction)
                                //   ? parseFloat(values?.insurance_deduction)
                                //   : 0)
                              }
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      {" "}
                      <br />{" "}
                      <span class="fw-bold text-danger d-flex">
                        Net Pay :{" "}
                        <input
                          style={{ width: 200 }}
                          class="form-control ms-2"
                          placeholder="00"
                          type="text"
                          readOnly={true}
                          onChange={handleChange("net_pay")}
                          value={
                            values?.net_pay
                            // (parseFloat(values?.basic)
                            //   ? parseFloat(values?.basic)
                            //   : 0) +
                            // (parseFloat(values?.hra)
                            //   ? parseFloat(values?.hra)
                            //   : 0) +
                            // (parseFloat(values?.medical)
                            //   ? parseFloat(values?.medical)
                            //   : 0) +
                            // (parseFloat(values?.other_allowance)
                            //   ? parseFloat(values?.other_allowance)
                            //   : 0) +
                            // (parseFloat(values?.loans_and_advances)
                            //   ? parseFloat(values?.loans_and_advances)
                            //   : 0) -
                            // ((parseFloat(values?.pf)
                            //   ? parseFloat(values?.pf)
                            //   : 0) +
                            //   (parseFloat(values?.esi)
                            //     ? parseFloat(values?.esi)
                            //     : 0) +
                            //   (parseFloat(values?.income_tax)
                            //     ? parseFloat(values?.income_tax)
                            //     : 0) +
                            //   (parseFloat(values?.professional_tax)
                            //     ? parseFloat(values?.professional_tax)
                            //     : 0) +
                            //   (parseFloat(values?.perquisitess)
                            //     ? parseFloat(values?.perquisitess)
                            //     : 0) +
                            //   (parseFloat(values?.insurance_deduction)
                            //     ? parseFloat(values?.insurance_deduction)
                            //     : 0))
                          }
                        />
                      </span>{" "}
                    </div>
                    {/* <div class="border col-md-8">
                      <div class="d-flex flex-column fw-bold pt-3 pb-3">
                        <label class="b-block mb-2" for="">
                          In Words
                        </label>
                        <textarea
                          class="form-control"
                          placeholder=""
                        ></textarea>
                      </div>
                    </div> */}

                    <div class="text-end mt-4">
                      <button type="submit" class="btn btn-primary">
                        Pay
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <div style={{ clear: "both" }}></div>
    </div>
  );
}
