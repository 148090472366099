import React, { useEffect, useState } from "react";
import config from "../../config/config";
import Network from "../../helpers/network";
import api from "../../config/api";
import { timeFormated } from "../../helpers/time";
import { Navigate, useNavigate, createSearchParams } from "react-router-dom";
import Pagination from "../../components/Pagination/Pagination.js";
import ExcelGenerator from "../../components/ExcelGenerator/ExcelGenerator.js";
import moment from "moment";
export default function Expenses(props) {
  const [result, setResult] = useState([]);
  const [page, setPage] = useState(1);
  const [totalUser, setTotalUser] = useState(0);
  const [isError, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [message, setMessage] = useState(null);
  const [search, setSearch] = useState(null);
  const [status, setStatus] = useState("active");
  const [sites, setSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState(null);
  const [balance, setBalance] = useState(0);
  const navigate = useNavigate();
  const getExpenseList = async () => {
    try {
      let res;
      let params = {
        per_page: config.per_page,
        page: page,
      };
      if (search && search.length > 3) {
        params["q"] = search;
      }
      if (selectedSite) {
        params["site"] = selectedSite;
      }
      res = await Network.get(api.pettycash, params);

      console.log(res?.data?.data);
      setResult(res?.data?.data?.list);
      setBalance(res?.data?.data?.total);
      // setTotalUser(res?.data?.data?.totalUsers);
    } catch (error) {
      console.log(error);
    }
  };
  const goToAdd = () => {
    console.log("navigate");
    navigate("/expenses-add");
  };
  const clearStatus = () => {
    setError(false);
    setSuccess(false);
    setMessage(null);
  };

  const getSites = async () => {
    try {
      const result = await Network.get(api.sites);
      console.log(result);
      setSites(result?.data?.data);
    } catch (err) { }
  };

  const deleteSite = async (id) => {
    try {
      clearStatus();
      if (window.confirm("Are you sure?")) {
        const result = await Network.delete(api.sites, { id });
        console.log(result);
        getExpenseList();
        setMessage("Site has been updated successfully");
        setSuccess(true);
      }
    } catch (error) {
      setMessage(error?.response?.message);
      setSuccess(false);
      setError(true);
      console.log(error);
    }
  };
  const searchHandler = () => {
    getExpenseList();
  };

  useEffect(() => {
    getExpenseList();
  }, [page, selectedSite]);

  useEffect(() => {
    getExpenseList();
    getSites();
  }, []);
  useEffect(() => {
    getExpenseList();
  }, [status]);
  return (
    <>
      <div class="col-md-10 col-sm-12  bashboard_boxes_open no_mar mb-2">
        <div class="purchase_order_buttom_nav mt-0">
          <div class=" mt-0 mb-0">
            <nav class="navbar bg-body-tertiary">
              <div class="container-fluid">
                <form class="d-flex ">
                  <h6 class="navbar-brand text-white">Expenses</h6>
                </form>
                <ul class="d-flex nav justify-content-end">
                  <li class="nav-item">
                    <a href="javascript:void(0);" onClick={goToAdd}>
                      <button class="nav-link bg-primary text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined">
                          add_circle
                        </span>
                      </button>
                    </a>
                  </li>
                  {/* <li class="nav-item">
                    <a href="flow_through_details.html">
                      <button class="nav-link bg-success text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined">edit_note</span>
                      </button>
                    </a>
                  </li> */}
                  {/* <li class="nav-item">
                    <a href="#">
                      <button class="nav-link bg-success text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined d-block">
                          {" "}
                          save{" "}
                        </span>
                      </button>
                    </a>
                  </li> */}
                  {/* <li class="nav-item">
                    <a href="#">
                      <button class="nav-link bg-danger text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined">cancel</span>{" "}
                      </button>
                    </a>
                  </li> */}
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div class="container-fluid pt-3">
          <div class="row">
            <div class="col-sm-12">
              <div class="card gate_entry_details2">
                <div class="table-content  tabile-responsive card-body">
                  <div class="row mb-2">
                    <div class="col-sm-3">
                      <div class="row form-group mb-0">
                        <label
                          for="inputPassword"
                          class="col-sm-4 col-form-label"
                        >
                          Site
                        </label>
                        <div class="col-sm-8">
                          <select
                            class="form-control"
                            onChange={(e) => setSelectedSite(e.target.value)}
                          >
                            <option>Select site</option>
                            {sites &&
                              sites.length > 0 &&
                              sites.map((s) => (
                                <option value={s?._id}>{s.site_name}</option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="row form-group mb-0">
                        {/* <label
                          for="inputPassword"
                          class="col-sm-4 col-form-label text-end"
                        >
                          Search
                        </label>
                        <div class="col-sm-8">
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            value={search}
                            onChange={searching}
                          />
                        </div> */}
                        {result.length > 0 ? (
                          <ExcelGenerator
                            excelData={result.map((r) => ({
                              Date: moment(r?.createdAt).format("DD-MM-YYYY"),
                              Site: r?.site?.site_name,
                              Category: r?.category,
                              Note: r?.note,
                              Type: r?.type,
                              Amount: r?.amount,
                            }))}
                          />
                        ) : null}
                        {/* <button className="form-control">Search</button> */}
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="col-sm-6">
                        <h2> Balance: {balance}</h2>
                        {/* <div>
                          <b>
                            <a
                              className="text-dark"
                              href={`/attendance?${
                                selectedMonth ? "&m=" + selectedMonth : ""
                              }`}
                            >
                              <span class="material-symbols-outlined align-middle">
                                add_circle
                              </span>
                            </a>
                          </b>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <table
                    id="example"
                    class="table   dt-responsive "
                    width="100%"
                  >
                    <thead>
                      <tr>
                        <th class="table-primary" width="30%">
                          Site Name
                        </th>
                        <th class="table-primary">Category</th>
                        <th class="table-primary">Type</th>
                        <th class="table-primary">Amount</th>
                        <th class="table-primary  text-center">Note</th>
                      </tr>
                    </thead>
                    <tbody>
                      {result.length > 0 &&
                        result.map((r) => (
                          <tr>
                            <td>{r?.site?.site_name}</td>
                            <td>{r?.category}</td>
                            <td>{r?.type}</td>
                            <td>{r?.amount}</td>
                            <td class="text-center">
                              {r?.note}
                              {/* <button
                                class=" bg-primary text-white  border-0 "
                                onClick={() => gotoEditSite(r?._id)}
                              >
                                <span class="material-symbols-outlined">
                                  edit
                                </span>
                              </button>
                              <button
                                class=" bg-danger text-white  border-0 "
                                onClick={() => {
                                  deleteSite(r?._id);
                                }}
                              >
                                <span class="material-symbols-outlined">
                                  delete
                                </span>
                              </button> */}
                            </td>
                          </tr>
                        ))}
                      {result.length === 0 && (
                        <tr>
                          <td colSpan={5}>No result found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {totalUser > 0 && (
                    <Pagination
                      currentPage={page}
                      totalCount={totalUser}
                      onPageChange={(e) => {
                        console.log(e);
                        // setPage((p) => p + 1);
                        setPage(e);
                      }}
                      pageSize={10}
                    />
                  )}
                </div>
              </div>
              {/* <div class="card-body mt-3">
                <nav aria-label="Page navigation example">
                  <ul class="pagination justify-content-center">
                    <li class="page-item disabled ">
                      <a class="page-link bg-success text-white">Previous</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">
                        1
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">
                        2
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">
                        3
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link bg-success text-white" href="#">
                        Next
                      </a>
                    </li>
                  </ul>
                </nav>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
