import React, { useState, useEffect } from "react";
import { Field, Formik } from "formik";
// import { AddUserValidation, EditUserValidation } from "./Roles.validation";
import Network from "../../helpers/network";
import api from "../../config/api";
import { camelToTitle, generatePassword } from "../../helpers/data";
import { useSearchParams } from "react-router-dom";
import "./Roles.scss";

export default function ExpenseForm() {
  const [isEdit, setEdit] = useState(false);
  const [isShowPassword, setShowPassword] = useState(false);
  const [autoPassword, setAutoPassword] = useState(null);
  const [isError, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [message, setMessage] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [userId, setUserId] = useState(null);
  const [user, setUser] = useState(null);
  const [category, setCategory] = useState([]);
  const [sites, setSites] = useState([]);

  const submit = async (values, resetForm) => {
    try {
      clearStatus();
      console.log("value>>", values);
      if (isEdit) {
        const result = await Network.put(api.pettycash, {
          ...values,
          id: userId,
        });
        console.log(result);
        setMessage("Petty cash has been updated successfully");
        setSuccess(true);
      } else {
        const result = await Network.post(api.pettycash, values);
        console.log(result);
        setMessage("Petty cash has been added successfully");
        setSuccess(true);
        resetForm();
      }
    } catch (error) {
      console.log("error", error);
      setError(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
    }
  };
  const getCategory = async () => {
    try {
      const result = await Network.get(api.expenseCategory);
      console.log(result);
      setCategory(result?.data?.data);
    } catch (err) {}
  };
  const getSites = async () => {
    try {
      const result = await Network.get(api.sites);
      console.log(result);
      setSites(result?.data?.data);
    } catch (err) {}
  };
  const clearStatus = () => {
    setError(false);
    setSuccess(false);
    setMessage(null);
  };
  //   const generatePasswordHandler = () => {
  //     const pass = generatePassword();
  //     setAutoPassword(pass);
  //   };
  //   const getUser = async (id) => {
  //     try {
  //       if (id && !isEdit) {
  //         setUserId(searchParams.get("id"));
  //         setEdit(true);
  //         clearStatus();
  //         const result = await Network.get(api.getUser, { id: id });
  //         console.log(result);
  //         setUser(result?.data?.data);
  //         // setMessage("User has been fatched successfully");
  //         // setSuccess(true);
  //       }
  //     } catch (error) {
  //       console.log("error", error);
  //     }
  //   };
  useEffect(() => {
    getCategory();
    getSites();
  }, []);
  //   useEffect(() => {
  //     // setUserId(searchParams.get("id"));
  //     // getUser(searchParams.get("id"));
  //   }, [searchParams]);
  return (
    <>
      <div class="col-md-10 col-sm-12  bashboard_boxes_open no_mar mb-2">
        <div class="purchase_order_buttom_nav mt-0">
          <div class=" mt-0 mb-0">
            <nav class="navbar bg-body-tertiary">
              <div class="container-fluid">
                <form class="d-flex ">
                  <h6 class="navbar-brand text-white">Deposite or expense</h6>
                </form>
                <ul class="d-flex nav justify-content-end">
                  {/* <li class="nav-item">
                    <a href="#">
                      <button class="nav-link bg-primary text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined">
                          add_circle
                        </span>
                      </button>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="flow_through_details.html">
                      <button class="nav-link bg-success text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined">edit_note</span>
                      </button>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="flow_through_details.html">
                      <button class="nav-link bg-success text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined d-block">
                          {" "}
                          save{" "}
                        </span>
                      </button>
                    </a>
                  </li> */}
                  <li class="nav-item">
                    <a href="javascript:void(0);">
                      <button class="nav-link bg-danger text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined">cancel</span>{" "}
                      </button>
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div class="container-fluid p-3">
          <Formik
            initialValues={{
              type: "expense",
              amount: 0,
              note: "",
              siteId: "",
              category: "",
              other: "",
            }}
            onSubmit={(values, { resetForm }) => submit(values, resetForm)}
            //   validationSchema={
            //     isEdit ? EditUserValidation : AddUserValidation
            //   }
            enableReinitialize={true}
          >
            {({
              handleChange,
              handleSubmit,
              errors,
              touched,
              values,
              setFieldValue,
            }) => (
              <form class="user" onSubmit={handleSubmit}>
                <div className="col-sm-12">
                  <div className="card p-3">
                    {isError && <div class="alert alert-danger">{message}</div>}
                    {isSuccess && (
                      <div class="alert alert-success">{message}</div>
                    )}
                    <div class="mb-2 row">
                      <label for="firstName" class="col-sm-3 col-form-label">
                        Site <span class="text-danger">*</span>
                      </label>
                      <div class="col-sm-9">
                        <select
                          className="form-control"
                          onChange={(e) =>
                            setFieldValue("siteId", e.target.value)
                          }
                        >
                          <option>Select site</option>
                          {sites &&
                            sites.length > 0 &&
                            sites.map((s) => (
                              <option value={s?._id}>{s.site_name}</option>
                            ))}
                        </select>
                        {errors.firstName && touched.firstName ? (
                          <div class="text-danger">
                            {camelToTitle(errors.firstName)}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div class="mb-2 row">
                      <label for="firstName" class="col-sm-3 col-form-label">
                        Type <span class="text-danger">*</span>
                      </label>
                      <div class="col-sm-9">
                        <select
                          className="form-control"
                          onChange={(e) =>
                            setFieldValue("type", e.target.value)
                          }
                        >
                          <option value="expense">Expense</option>
                          <option value="deposite">Deposite</option>
                        </select>
                        {errors.firstName && touched.firstName ? (
                          <div class="text-danger">
                            {camelToTitle(errors.firstName)}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {values.type === "expense" && (
                      <div class="mb-2 row">
                        <label for="firstName" class="col-sm-3 col-form-label">
                          Category <span class="text-danger">*</span>
                        </label>
                        <div class="col-sm-9">
                          <select
                            className="form-control"
                            onChange={(e) =>
                              setFieldValue("category", e.target.value)
                            }
                          >
                            <option>Select</option>
                            {category &&
                              category.map((c) => (
                                <option value={c.key}>{c.value}</option>
                              ))}
                          </select>
                          {errors.firstName && touched.firstName ? (
                            <div class="text-danger">
                              {camelToTitle(errors.firstName)}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    )}
                    <div class="mb-2 row">
                      <label for="staticEmail" class="col-sm-3 col-form-label">
                        Amount <span class="text-danger">*</span>
                      </label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          name="amount"
                          onChange={handleChange}
                          value={values.amount}
                          class="form-control form-control-user"
                          id="staticEmail"
                        />
                        {errors.amount && touched.amount ? (
                          <div class="text-danger">
                            {camelToTitle(errors.amount)}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div class="mb-2 row">
                      <label for="staticEmail" class="col-sm-3 col-form-label">
                        Note <span class="text-danger">*</span>
                      </label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          name="note"
                          onChange={handleChange}
                          value={values.note}
                          class="form-control form-control-user"
                          id="staticEmail"
                        />
                        {errors.note && touched.note ? (
                          <div class="text-danger">
                            {camelToTitle(errors.note)}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <button class="btn btn-primary ">Submit</button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}
