import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AddVendorValidation, EditVendorValidation } from "./Vendor.validation";
import Network from "../../helpers/network";
import api from "../../config/api";
import { camelToTitle } from "../../helpers/data";
import Loader from "../../components/Loader/Loader";
import ImageLightBox from "../../components/ImageLightBox/ImageLightBox";
import "./Vendors.scss";
export default function VendorForm() {
  const [isEdit, setEdit] = useState(false);
  const [isShowPassword, setShowPassword] = useState(false);
  const [autoPassword, setAutoPassword] = useState(null);
  const [isError, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [message, setMessage] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [vendorId, setVendorId] = useState(null);
  const [vendor, setVendor] = useState(null);
  const [clients, setClients] = useState([]);
  const [sites, setSites] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [images, setImages] = useState([]);
  const navigation = useNavigate();
  const goToBack = () => {
    navigation("/vendors");
  };
  const fileUpload = async (e, name, setFieldValue) => {
    try {
      const frmData = new FormData();
      let res;
      setLoading(true);

      frmData.append("file", e.target.files[0]);
      res = await Network.upload(frmData);
      console.log("100 fileupload>>", res);
      setFieldValue(name, res?.data?.data?.path);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(true);
      setMessage("File upload faild! Please try after sometime.");
    }
  };
  const clearStatus = () => {
    setError(false);
    setSuccess(false);
    setMessage(null);
  };
  const submit = async (values, resetForm) => {
    try {
      clearStatus();
      console.log("value>>", values);
      if (isEdit) {
        const result = await Network.put(api.vendors, {
          ...values,
          id: vendorId,
        });
        console.log(result);
        setMessage("Vendor has been updated successfully");
        setSuccess(true);
      } else {
        const result = await Network.post(api.vendors, values);
        console.log(result);
        setMessage("Vendor has been created successfully");
        setSuccess(true);
        resetForm();
      }
    } catch (error) {
      console.log("error", error);
      setError(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
    }
  };
  const getClientList = async () => {
    try {
      const res = await Network.get(api.client);
      setClients(res?.data?.data);
    } catch (err) {}
  };
  const getSiteList = async () => {
    try {
      const res = await Network.get(api.sites);
      console.log("sites>>", res);
      setSites(res?.data?.data);
    } catch (err) {}
  };
  const getUser = async (id) => {
    try {
      if (id && !isEdit) {
        setVendorId(searchParams.get("id"));
        setEdit(true);
        clearStatus();
        const result = await Network.get(api.getVendorById, { id: id });
        console.log(result);
        setVendor(result?.data?.data);
        // setMessage("User has been fatched successfully");
        // setSuccess(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const openLightBox = (images) => {
    console.log("101 images>>>", images);
    setOpen(true);
    setImages(images);
  };

  const closeLightBox = () => {
    setOpen(false);
    setImages([]);
  };
  useEffect(() => {
    // setUserId(searchParams.get("id"));
    getUser(searchParams.get("id"));
  }, [searchParams]);
  useEffect(() => {
    getSiteList();
    getClientList();
  }, []);
  return (
    <>
      <div class="col-md-10 col-sm-12  bashboard_boxes_open no_mar mb-2">
        <div class="purchase_order_buttom_nav mt-0">
          <div class=" mt-0 mb-0">
            <nav class="navbar bg-body-tertiary">
              <div class="container-fluid">
                <form class="d-flex ">
                  <h6 class="navbar-brand text-white">Add Vendor</h6>
                </form>
                <ul class="d-flex nav justify-content-end">
                  {/* <li class="nav-item">
                    <a href="#">
                      <button class="nav-link bg-primary text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined">
                          add_circle
                        </span>
                      </button>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="flow_through_details.html">
                      <button class="nav-link bg-success text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined">edit_note</span>
                      </button>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="#">
                      <button class="nav-link bg-success text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined d-block">
                          {" "}
                          save{" "}
                        </span>
                      </button>
                    </a>
                  </li> */}
                  <li class="nav-item">
                    <a href="javascript:void(0);" onClick={goToBack}>
                      <button class="nav-link bg-danger text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined">cancel</span>{" "}
                      </button>
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div class="container-fluid pt-3">
          {isLoading && <Loader />}
          <div class="row">
            <Formik
              initialValues={{
                vendor_name: isEdit ? vendor?.vendor_name : "",
                contact_person: isEdit ? vendor?.contact_person : "",
                phone: isEdit ? vendor?.phone : "",
                products: isEdit ? vendor?.products.join(",") : "",
                sites: isEdit ? vendor?.sites?._id : "",
                district: isEdit ? vendor?.district : "",
                state: isEdit ? vendor?.state : "",
                gst_number: isEdit ? vendor?.gst_number : "",
                gst_pic: isEdit ? vendor?.gst_pic : "",
                aadhaar_number: isEdit ? vendor?.kyc?.aadhaar_number : "",
                aadhaar_fpic: isEdit ? vendor?.kyc?.aadhaar_fpic : "",
                aadhaar_bpic: isEdit ? vendor?.kyc?.aadhaar_bpic : "",
                pan_number: isEdit ? vendor?.kyc?.pan_number : "",
                pan_pic: isEdit ? vendor?.kyc?.pan_pic : "",
                client: isEdit ? vendor?.client?._id : "",
                accountNumber: isEdit
                  ? vendor?.accountDetails?.accountNumber
                  : "",
                bankName: isEdit ? vendor?.accountDetails?.bankName : "",
                ifscCode: isEdit ? vendor?.accountDetails?.ifscCode : "",
                bankDoc: isEdit ? vendor?.accountDetails?.bankDoc : "",
              }}
              onSubmit={(values, { resetForm }) => submit(values, resetForm)}
              validationSchema={
                isEdit ? EditVendorValidation : AddVendorValidation
              }
              enableReinitialize={true}
            >
              {({
                handleChange,
                handleSubmit,
                errors,
                touched,
                values,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  {/* {JSON.stringify(errors)} */}
                  {isSuccess && (
                    <div className="alert alert-success">{message}</div>
                  )}
                  {isError && (
                    <div className="alert alert-danger">{message}</div>
                  )}
                  <div class="col-sm-12">
                    <h2 class="heading_style">
                      <a href="#">Personal Details</a>
                    </h2>
                    <div class="card mb-3">
                      <div class="row g-0 p-1">
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item border-radius">
                            <div class="row">
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Establishment Name
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="vendor_name"
                                      onChange={handleChange}
                                      value={values.vendor_name}
                                    />
                                    {errors.vendor_name &&
                                    touched.vendor_name ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.vendor_name)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Contact Person
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="contact_person"
                                      onChange={handleChange}
                                      value={values.contact_person}
                                    />{" "}
                                    {errors.contact_person &&
                                    touched.contact_person ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.contact_person)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Mobile Number
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="phone"
                                      onChange={handleChange}
                                      value={values.phone}
                                    />{" "}
                                    {errors.phone && touched.phone ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.phone)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class="list-group-item border-radius">
                            <div class="row">
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Product of Supply
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="products"
                                      onChange={handleChange}
                                      value={values.products}
                                    />{" "}
                                    {errors.products && touched.products ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.products)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Site Location
                                  </label>
                                  <div class="col-sm-7">
                                    <select
                                      class="form-control form-control-sm"
                                      name="sites"
                                      onChange={handleChange}
                                      value={values.sites}
                                    >
                                      <option>Select</option>
                                      {sites &&
                                        sites.length > 0 &&
                                        sites.map((s) => (
                                          <option
                                            value={s?._id}
                                            selected={s?._id === values.sites}
                                          >
                                            {s?.site_name}
                                          </option>
                                        ))}
                                    </select>
                                    {errors.sites && touched.sites ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.sites)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    District
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="district"
                                      onChange={handleChange}
                                      value={values.district}
                                    />{" "}
                                    {errors.district && touched.district ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.district)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class="list-group-item border-radius">
                            <div class="row">
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    State
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="state"
                                      onChange={handleChange}
                                      value={values.state}
                                    />
                                    {errors.state && touched.state ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.state)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Client
                                  </label>
                                  <div class="col-sm-7">
                                    <Field
                                      as="select"
                                      class="form-control form-control-sm"
                                      name="client"
                                      onChange={handleChange}
                                      value={values.client}
                                    >
                                      <option>Select</option>
                                      {clients &&
                                        clients.length > 0 &&
                                        clients.map((c) => (
                                          <option
                                            value={c?._id}
                                            selected={c?._id === values?.client}
                                          >
                                            {c?.client_name}
                                          </option>
                                        ))}
                                    </Field>
                                    {errors.client && touched.client ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.client)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    GST Number
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="gst_number"
                                      onChange={handleChange}
                                      value={values.gst_number}
                                    />
                                    {errors.gst_number && touched.gst_number ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.gst_number)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class="list-group-item border-radius">
                            <div class="row">
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Upload GST
                                  </label>
                                  <div class="col-sm-7 previewImage">
                                    {isEdit && values.gst_pic && (
                                      <a
                                        href="javascript:void(0);"
                                        onClick={() => {
                                          openLightBox([
                                            { url: `${values.gst_pic}` },
                                          ]);
                                        }}
                                      >
                                        <img
                                          src={values.gst_pic}
                                          // crossOrigin="anonymous"
                                        />
                                      </a>
                                    )}
                                    <input
                                      type="file"
                                      class="form-control"
                                      id="customFile"
                                      accept="image/png,  image/jpeg"
                                      onChange={(e) => {
                                        fileUpload(e, "gst_pic", setFieldValue);
                                      }}
                                    />
                                    <input
                                      type="hidden"
                                      name="gst_pic"
                                      onChange={handleChange}
                                      value={values.gst_pic}
                                    />
                                    {errors.gst_pic && touched.gst_pic ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.gst_pic)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <h2 class="heading_style">
                      <a href="#">ID card Details</a>
                    </h2>
                    <div class="card mb-3">
                      <div class="row g-0 p-1">
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item border-radius">
                            <div class="row">
                              <div class="col-sm-6">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Aadhar Number
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="aadhaar_number"
                                      onChange={handleChange}
                                      value={values.aadhaar_number}
                                    />
                                    {errors.aadhaar_number &&
                                    touched.aadhaar_number ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.aadhaar_number)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Choose Aadhar card (Front)
                                  </label>
                                  <div class="col-sm-7 previewImage">
                                    {isEdit && values.aadhaar_fpic && (
                                      <a
                                        href="javascript:void(0);"
                                        onClick={() => {
                                          openLightBox([
                                            { url: `${values.aadhaar_fpic}` },
                                          ]);
                                        }}
                                      >
                                        <img
                                          src={values.aadhaar_fpic}
                                          // crossOrigin="anonymous"
                                        />
                                      </a>
                                    )}
                                    <input
                                      type="file"
                                      class="form-control"
                                      id="customFile"
                                      accept="image/png,  image/jpeg"
                                      onChange={(e) => {
                                        fileUpload(
                                          e,
                                          "aadhaar_fpic",
                                          setFieldValue
                                        );
                                      }}
                                    />
                                    <input
                                      type="hidden"
                                      name="aadhaar_fpic"
                                      onChange={handleChange}
                                      value={values.aadhaar_fpic}
                                    />
                                    {errors.aadhaar_fpic &&
                                    touched.aadhaar_fpic ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.aadhaar_fpic)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Choose Aadhar card (Back)
                                  </label>
                                  <div class="col-sm-7 previewImage">
                                    {isEdit && values.aadhaar_bpic && (
                                      <a
                                        href="javascript:void(0);"
                                        onClick={() => {
                                          openLightBox([
                                            { url: `${values.aadhaar_bpic}` },
                                          ]);
                                        }}
                                      >
                                        <img
                                          src={values.aadhaar_bpic}
                                          // crossOrigin="anonymous"
                                        />
                                      </a>
                                    )}
                                    <input
                                      type="file"
                                      class="form-control"
                                      id="customFile"
                                      accept="image/png,  image/jpeg"
                                      onChange={(e) => {
                                        fileUpload(
                                          e,
                                          "aadhaar_bpic",
                                          setFieldValue
                                        );
                                      }}
                                    />
                                    <input
                                      type="hidden"
                                      name="aadhaar_bpic"
                                      onChange={handleChange}
                                      value={values.aadhaar_bpic}
                                    />
                                    {errors.aadhaar_bpic &&
                                    touched.aadhaar_bpic ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.aadhaar_bpic)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>

                          <li class="list-group-item border-radius">
                            <div class="row">
                              <div class="col-sm-6">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    pan card Number
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="pan_number"
                                      onChange={handleChange}
                                      value={values.pan_number}
                                    />
                                    {errors.pan_number && touched.pan_number ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.pan_number)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Choose pan card
                                  </label>
                                  <div class="col-sm-7 previewImage">
                                    {isEdit && values.pan_pic && (
                                      <a
                                        href="javascript:void(0);"
                                        onClick={() => {
                                          openLightBox([
                                            { url: `${values.pan_pic}` },
                                          ]);
                                        }}
                                      >
                                        <img
                                          src={values.pan_pic}
                                          // crossOrigin="anonymous"
                                        />
                                      </a>
                                    )}
                                    <input
                                      type="file"
                                      class="form-control"
                                      id="customFile"
                                      accept="image/png,  image/jpeg"
                                      onChange={(e) => {
                                        fileUpload(e, "pan_pic", setFieldValue);
                                      }}
                                    />
                                    <input
                                      type="hidden"
                                      name="pan_pic"
                                      onChange={handleChange}
                                      value={values.pan_pic}
                                    />
                                    {errors.pan_pic && touched.pan_pic ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.pan_pic)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <h2 class="heading_style">
                      <a href="#">Account Details</a>
                    </h2>
                    <div class="card mb-3">
                      <div class="row g-0 p-1">
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item border-radius">
                            <div class="row">
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Account Number
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="accountNumber"
                                      onChange={handleChange}
                                      value={values.accountNumber}
                                    />
                                    {errors.accountNumber &&
                                    touched.accountNumber ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.accountNumber)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Bank Name
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="bankName"
                                      onChange={handleChange}
                                      value={values.bankName}
                                    />
                                    {errors.bankName && touched.bankName ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.bankName)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    IFSC Code
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="ifscCode"
                                      onChange={handleChange}
                                      value={values.ifscCode}
                                    />
                                    {errors.ifscCode && touched.ifscCode ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.ifscCode)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>

                          <li class="list-group-item border-radius">
                            <div class="row">
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Passbook/ cheque upload
                                  </label>
                                  <div class="col-sm-7 previewImage">
                                    {isEdit && values.bankDoc && (
                                      <a
                                        href="javascript:void(0);"
                                        onClick={() => {
                                          openLightBox([
                                            { url: `${values.bankDoc}` },
                                          ]);
                                        }}
                                      >
                                        <img
                                          src={values.bankDoc}
                                          // crossOrigin="anonymous"
                                        />
                                      </a>
                                    )}
                                    <input
                                      type="file"
                                      class="form-control"
                                      id="customFile"
                                      accept="image/png,  image/jpeg"
                                      onChange={(e) => {
                                        fileUpload(e, "bankDoc", setFieldValue);
                                      }}
                                    />
                                    <input
                                      type="hidden"
                                      name="bankDoc"
                                      onChange={handleChange}
                                      value={values.bankDoc}
                                    />
                                    {errors.bankDoc && touched.bankDoc ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.bankDoc)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="card-body text-center  p-3">
                      <div>
                        <button type="submit" class="btn btn-success">
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      {isOpen && (
        <ImageLightBox
          images={images.map((i) => i.url)}
          isOpen={isOpen}
          closeLightBox={closeLightBox}
        />
      )}
    </>
  );
}
