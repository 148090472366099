import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../config/api";
import Network from "../../helpers/network";
import moment from "moment";
import AppContext from "../../appContext";
import ExcelGenerator from "../../components/ExcelGenerator/ExcelGenerator";
import "./Attendance.scss";
import AttendenceModal from "../../components/modals/AttendenceModal";
import { Field, FieldArray, Formik } from "formik";
import classNames from "classnames";

// import "./Home.scss";

export default function AttendanceDashboard() {
  const [result, setResult] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sites, setSites] = useState([]);
  const [empList, setEmp] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedMonth, setSelectedMonth] = useState(
    moment().format("YYYY-MM")
  );
  const [selectedSite, setSelectedSite] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("A");
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const [totalAttendence, setTotalAttendence] = useState([]);
  const [monthDays, setMonthDays] = useState(moment().daysInMonth());
  const [isEdit, setEdit] = useState(false);

  const getDashboard = async () => {
    try {
      const res = await Network.get(api.attendence);
      console.log("dashboard", res);
      setResult(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getSites = async () => {
    try {
      const result = await Network.get(api.sites);
      setSites(result?.data?.data);
    } catch (err) {}
  };
  const goToPage = (page) => {
    navigate(page);
  };
  // const getEmployees = async () => {
  //   try {
  //     const result = await Network.get(api.employees);
  //     console.log("101 emp>>", result, result.data?.data);
  //     setEmp(result?.data?.data);
  //   } catch (err) {}
  // };
  useEffect(() => {
    // getDashboard();
    getSites();
    getAttendence();
    // attendenceExport();
    // getEmployees();
  }, []);
  useEffect(() => {
    getAttendence();
    // attendenceExport();
  }, [selectedSite]);
  useEffect(() => {
    getAttendence();
    // attendenceExport();
    setMonthDays(moment(selectedMonth).daysInMonth());
  }, [selectedMonth]);
  useEffect(() => {
    getAttendence();
    // attendenceExport();
  }, [search]);
  const getAttendence = async () => {
    try {
      let query = { gm: true };
      if (selectedSite) {
        query["siteId"] = selectedSite;
      }
      if (selectedMonth) {
        query["gm"] = selectedMonth;
      }
      if (search) {
        query["Q"] = search;
      }
      // let params = new URLSearchParams(query).toString();
      const employeeAttendence = await Network.get(api.attendenceCount, query);
      console.log("dataa", employeeAttendence);
      setResult(employeeAttendence?.data);
    } catch (err) {
      console.log(err, err?.response);
      // if (err?.response?.status === 401) alert(err?.response?.data?.message);
      appContext.showToast("error", err?.response?.data?.message);
      // navigate("/");
      // console.log("101", appContext);
    }
  };
  const submit = async (values, resetForm) => {
    try {
      // const selectedEmp = employees?.filter(
      //   (e) => e._id === selectedEmployeeId
      // );
      // if (selectedEmp.length > 0) {
      // let tempData = {
      //   // siteId: selectedEmp[0]?.siteId?._id,
      //   attendDate: values?.date,
      //   attendenceStatus: true,
      //   // userId: selectedEmployeeId,
      // };
      // console.log(tempData);
      // return;
      const attendance = await Network.post(api.attendence, {
        users: values.users,
      });
      console.log(attendance);
      appContext.showToast("success", attendance?.data?.message);
      resetForm();
      setEdit(false);
      getAttendence();
      // }
    } catch (err) {
      appContext.showToast("error", "Please try after some time");
    }
  };
  const attendenceExport = async () => {
    try {
      let query = { gm: true };
      if (selectedSite) {
        query["siteId"] = selectedSite;
      }
      if (selectedMonth) {
        query["gm"] = selectedMonth;
      }
      if (search) {
        query["Q"] = search;
      }
      let params = new URLSearchParams(query).toString();
      const employeeAttendence = await Network.get(
        api.attendenceExport + "?" + params
        // null,
        // {
        //   responseType: "blob", // important
        // }
      );
      setTotalAttendence(employeeAttendence.data?.data);
      // const url = window.URL.createObjectURL(
      //   new Blob([employeeAttendence?.data])
      // );
      // console.log("url>>>", url);
      // const link = document.createElement("a");
      // link.href = url;
      // link.setAttribute("download", `${Date.now()}.xls`);
      // document.body.appendChild(link);
      // link.click();
      // setResult(employeeAttendence?.data?.data);
    } catch (err) {
      console.log(err, err?.response);
      // if (err?.response?.status === 401) alert(err?.response?.data?.message);
      appContext.showToast("error", err?.response?.data?.message);
      // navigate("/");
      // console.log("101", appContext);
    }
  };

  const searching = (e) => {
    if (e.target.value.length > 3) {
      setSearch(e.target.value);
    } else {
      setSearch(null);
    }
  };
  const gotoEditAttendence = (id) => {
    // navigate("/attendance", { id: id });
  };
  const gotoDetailsAttendence = (id) => {
    navigate("/attendance-details", { id: id });
  };
  return (
    <>
      <div class="col-md-10 col-sm-12  bashboard_boxes_open no_mar">
        {/* <div class="bashboard_boxes ">
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="dashboard_box">
                <div class="prole_pic_2"></div>
                <a
                  href="javascript:void(0);"
                  onClick={() => goToPage("/users")}
                >
                  <p>Today</p>
                  {0}
                </a>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="dashboard_box border-color_blue">
                <div class="prole_pic_2"></div>
                <a
                  href="javascript:void(0);"
                  onClick={() => goToPage("/clients")}
                >
                  <p>Total This Month</p>
                  {0}
                </a>
              </div>
            </div>
          
          </div>
        </div> */}
        <div class="container-fluid pt-3">
          <div class="attendance table-responsive">
            <div className="row">
              <div class="col-sm-3">
                <div class="row form-group mb-0">
                  <label for="inputPassword" class="col-sm-4 col-form-label">
                    Select Month
                  </label>
                  <div class="col-sm-8">
                    <input
                      type="month"
                      class="form-control "
                      max={moment().format("YYYY-MM")}
                      value={selectedMonth}
                      onChange={(e) => setSelectedMonth(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div class="col-sm-3">
                <div class="row form-group mb-0">
                  <label
                    for="inputPassword"
                    class="col-sm-4 col-form-label text-end"
                  >
                    Site
                  </label>
                  <div class="col-sm-8">
                    <select
                      class="form-control "
                      onChange={(e) => setSelectedSite(e.target.value)}
                    >
                      <option>Select site</option>
                      {sites &&
                        sites.length > 0 &&
                        sites.map((s) => (
                          <option value={s?._id}>{s.site_name}</option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                {/* <div class="row form-group mb-0">
                  <label
                    for="inputPassword"
                    class="col-sm-4 col-form-label text-end"
                  >
                    Search
                  </label>
                  <div class="col-sm-8">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      value={search}
                      onChange={searching}
                    />
                  </div>
                </div> */}
                <button
                  class="btn btn-primary border-0"
                  onClick={() => setEdit(true)}
                >
                  Edit
                </button>
              </div>
              <div class="col-sm-3">
                {result.length > 0 ? (
                  <ExcelGenerator
                    excelData={result.map((e) => ({
                      EmpId: e?.user?.empId,
                      Name: `${e?.user?.firstName} ${e?.user?.lastName}`,
                      Designation: e?.user?.designation,
                      Present: e?.total_p,
                      Absent: e?.total_a,
                      WO: e?.total_wo,
                      NH: e?.total_nh,
                      HD: e?.total_hd,
                      TotalAttendence: e?.totalPresent,
                      OT: e?.totalOt,
                    }))}
                  />
                ) : null}
              </div>
            </div>
            <table class="table">
              <thead class="thead-dark">
                <tr>
                  <th>
                    <h6 style={{ width: 50 }}>
                      <strong>
                        {" "}
                        CODE
                        {/* <a href="#" class="text-dark">
                          <img
                            class="dropbtn"
                            onclick="myFunction()"
                            src="arrow1.png"
                          />
                        </a> */}
                        {/* <div class="dropdown">
                          <div id="myDropdown" class="dropdown-content">
                            <a href="#">
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="defaultCheck1"
                                />
                                <label
                                  class="form-check-label"
                                  for="defaultCheck1"
                                >
                                  Select 1
                                </label>
                              </div>
                            </a>
                            <a href="#">
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="defaultCheck1"
                                />
                                <label
                                  class="form-check-label"
                                  for="defaultCheck1"
                                >
                                  Select 2
                                </label>
                              </div>
                            </a>
                            <a href="#">
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="defaultCheck1"
                                />
                                <label
                                  class="form-check-label"
                                  for="defaultCheck1"
                                >
                                  Select 3
                                </label>
                              </div>
                            </a>
                          </div>
                        </div> */}
                      </strong>
                    </h6>
                  </th>

                  <th>
                    <h6 style={{ width: 100 }} class="position-relative">
                      <strong>
                        NAME
                        {/* <a href="#" class="text-dark">
                          <img src={`/assets/images/arrow1.png`} />
                        </a> */}
                      </strong>
                    </h6>
                  </th>

                  <th>
                    <h6 style={{ width: 100 }}>
                      <strong>
                        DESIGNATION
                        {/* <a href="#" class="text-dark">
                          <img src={`/assets/images/arrow1.png`} />
                        </a> */}
                      </strong>
                    </h6>
                    <div></div>
                  </th>

                  <th>
                    <h6 style={{ width: 50 }}>
                      <strong>
                        ATD/OT
                        {/* <a href="#" class="text-dark">
                          <img src={`/assets/images/arrow1.png`} />
                        </a> */}
                      </strong>
                    </h6>
                    <div></div>
                  </th>
                  <th>
                    <div class="d-flex position-relative">
                      {monthDays &&
                        Array(monthDays)
                          .fill(undefined)
                          .map((d, i) => {
                            return (
                              <h6 class="attendance_caln att_month">
                                <span>
                                  <a href="#" class="text-dark">
                                    <img src={`/assets/images/arrow1.png`} />
                                  </a>
                                  {moment(selectedMonth)
                                    .startOf("month")
                                    .add(i, "day")
                                    .format("DD-MMM")}
                                </span>
                              </h6>
                            );
                          })}
                      <h6 class="attendance_caln att_month">
                        <span>Present</span>
                      </h6>
                      <h6 class="attendance_caln att_month">
                        <span>Absent</span>
                      </h6>
                      <h6 class="attendance_caln att_month">
                        <span>W/O</span>
                      </h6>
                      <h6 class="attendance_caln att_month">
                        <span>NH</span>
                      </h6>
                      <h6 class="attendance_caln att_month">
                        <span>HD</span>
                      </h6>
                    </div>
                    {/* <div class="d-flex position-relative">
                      <h6 class="attendance_caln att_month">
                        <span>
                          <a href="#" class="text-dark">
                            <img src={`/assets/images/arrow1.png`} />
                          </a>
                          01-Apr
                        </span>
                      </h6>
                      <h6 class="attendance_caln att_month">
                        <span>
                          <a href="#" class="text-dark">
                            <img src={`/assets/images/arrow1.png`} />
                          </a>
                          02-Apr
                        </span>
                      </h6>
                      <h6 class="attendance_caln att_month">
                        <span>
                          <a href="#" class="text-dark">
                            <img src={`/assets/images/arrow1.png`} />
                          </a>
                          03-Apr
                        </span>
                      </h6>
                      <h6 class="attendance_caln att_month">
                        <span>
                          <a href="#" class="text-dark">
                            <img src={`/assets/images/arrow1.png`} />
                          </a>
                          04-Apr
                        </span>
                      </h6>
                      <h6 class="attendance_caln att_month">
                        <span>
                          <a href="#" class="text-dark">
                            <img src={`/assets/images/arrow1.png`} />
                          </a>
                          05-Apr
                        </span>
                      </h6>
                      <h6 class="attendance_caln att_month">
                        <span>
                          <a href="#" class="text-dark">
                            <img src={`/assets/images/arrow1.png`} />
                          </a>
                          06-Apr
                        </span>
                      </h6>
                      <h6 class="attendance_caln att_month">
                        <span>
                          <a href="#" class="text-dark">
                            <img src={`/assets/images/arrow1.png`} />
                          </a>
                          07-Apr
                        </span>
                      </h6>
                      <h6 class="attendance_caln att_month">
                        <span>
                          <a href="#" class="text-dark">
                            <img src={`/assets/images/arrow1.png`} />
                          </a>
                          08-Apr
                        </span>
                      </h6>
                      <h6 class="attendance_caln att_month">
                        <span>
                          <a href="#" class="text-dark">
                            <img src={`/assets/images/arrow1.png`} />
                          </a>
                          09-Apr
                        </span>
                      </h6>
                      <h6 class="attendance_caln att_month">
                        <span>
                          <a href="#" class="text-dark">
                            <img src={`/assets/images/arrow1.png`} />
                          </a>
                          10-Apr
                        </span>
                      </h6>
                      <h6 class="attendance_caln att_month">
                        <span>
                          <a href="#" class="text-dark">
                            <img src={`/assets/images/arrow1.png`} />
                          </a>
                          11-Apr
                        </span>
                      </h6>
                      <h6 class="attendance_caln att_month">
                        <span>
                          <a href="#" class="text-dark">
                            <img src={`/assets/images/arrow1.png`} />
                          </a>
                          12-Apr
                        </span>
                      </h6>
                      <h6 class="attendance_caln att_month">
                        <span>
                          <a href="#" class="text-dark">
                            <img src={`/assets/images/arrow1.png`} />
                          </a>
                          13-Apr
                        </span>
                      </h6>
                      <h6 class="attendance_caln att_month">
                        <span>
                          <a href="#" class="text-dark">
                            <img src={`/assets/images/arrow1.png`} />
                          </a>
                          14-Apr
                        </span>
                      </h6>
                      <h6 class="attendance_caln att_month">
                        <span>
                          <a href="#" class="text-dark">
                            <img src={`/assets/images/arrow1.png`} />
                          </a>
                          15-Apr
                        </span>
                      </h6>
                      <h6 class="attendance_caln att_month">
                        <span>
                          <a href="#" class="text-dark">
                            <img src={`/assets/images/arrow1.png`} />
                          </a>
                          16-Apr
                        </span>
                      </h6>
                      <h6 class="attendance_caln att_month">
                        <span>
                          <a href="#" class="text-dark">
                            <img src={`/assets/images/arrow1.png`} />
                          </a>
                          17-Apr
                        </span>
                      </h6>
                      <h6 class="attendance_caln att_month">
                        <span>
                          <a href="#" class="text-dark">
                            <img src={`/assets/images/arrow1.png`} />
                          </a>
                          18-Apr
                        </span>
                      </h6>
                      <h6 class="attendance_caln att_month">
                        <span>
                          <a href="#" class="text-dark">
                            <img src={`/assets/images/arrow1.png`} />
                          </a>
                          19-Apr
                        </span>
                      </h6>
                      <h6 class="attendance_caln att_month">
                        <span>
                          <a href="#" class="text-dark">
                            <img src={`/assets/images/arrow1.png`} />
                          </a>
                          20-Apr
                        </span>
                      </h6>
                      <h6 class="attendance_caln att_month">
                        <span>
                          <a href="#" class="text-dark">
                            <img src={`/assets/images/arrow1.png`} />
                          </a>
                          21-Apr
                        </span>
                      </h6>
                      <h6 class="attendance_caln att_month">
                        <span>
                          <a href="#" class="text-dark">
                            <img src={`/assets/images/arrow1.png`} />
                          </a>
                          22-Apr
                        </span>
                      </h6>
                      <h6 class="attendance_caln att_month">
                        <span>
                          <a href="#" class="text-dark">
                            <img src={`/assets/images/arrow1.png`} />
                          </a>
                          23-Apr
                        </span>
                      </h6>
                      <h6 class="attendance_caln att_month">
                        <span>
                          <a href="#" class="text-dark">
                            <img src={`/assets/images/arrow1.png`} />
                          </a>
                          24-Apr
                        </span>
                      </h6>
                      <h6 class="attendance_caln att_month">
                        <span>
                          <a href="#" class="text-dark">
                            <img src={`/assets/images/arrow1.png`} />
                          </a>
                          25-Apr
                        </span>
                      </h6>
                      <h6 class="attendance_caln att_month">
                        <span>
                          <a href="#" class="text-dark">
                            <img src={`/assets/images/arrow1.png`} />
                          </a>
                          26-Apr
                        </span>
                      </h6>
                      <h6 class="attendance_caln att_month">
                        <span>
                          <a href="#" class="text-dark">
                            <img src={`/assets/images/arrow1.png`} />
                          </a>
                          27-Apr
                        </span>
                      </h6>
                      <h6 class="attendance_caln att_month">
                        <span>
                          <a href="#" class="text-dark">
                            <img src={`/assets/images/arrow1.png`} />
                          </a>
                          28-Apr
                        </span>
                      </h6>
                      <h6 class="attendance_caln att_month">
                        <span>
                          <a href="#" class="text-dark">
                            <img src={`/assets/images/arrow1.png`} />
                          </a>
                          29-Apr
                        </span>
                      </h6>
                      <h6 class="attendance_caln att_month">
                        <span>
                          <a href="#" class="text-dark">
                            <img src={`/assets/images/arrow1.png`} />
                          </a>
                          30-Apr
                        </span>
                      </h6>
                    </div> */}
                  </th>
                  {/* <th>
                    <h6 style={{ width: 150 }}>
                      <strong>Action</strong>
                    </h6>
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {result && result.length > 0
                  ? result.map((e) => (
                      <tr>
                        <th scope="row">{e?.user?.empId}</th>
                        <td>{`${e?.user?.firstName} ${e?.user?.lastName}`} </td>
                        <td>{e?.user?.designation}</td>
                        <td>
                          <div>ATD</div>
                          <div>OT</div>
                        </td>
                        <td>
                          <div class="d-flex">
                            <>
                              {e?.attendence.map((d, i) => {
                                // let status="";
                                // if(d?.attendenceStatus){
                                //   if(d?.attendenceStatus==='present')
                                //     status = 'P';
                                //   else if(d?.attendenceStatus==='absent')
                                //   status = 'A';
                                // else if(d?.attendenceStatus==='helf day')
                                //  status = 'HF';
                                // else if(d?.attendenceStatus==='helf day')
                                //  status=
                                // }
                                const className = classNames({
                                  "bg-present": d?.attendenceStatus == "P",
                                  "bg-absent": d?.attendenceStatus == "A",
                                  "bg-hd": d?.attendenceStatus == "HD",
                                  "bg-nh": d?.attendenceStatus == "NH",
                                  "bg-wo": d?.attendenceStatus == "WO",
                                });
                                return (
                                  <div
                                    className={`attendance_caln ${className}`}
                                  >
                                    {/* {isEdit ? (
                                    <select class="form-control">
                                      <option value="P">P</option>
                                      <option value="A">A</option>
                                      <option value="HD">HD</option>
                                      <option value="NH">NH</option>
                                    </select>
                                  ) : ( */}
                                    <span>{d?.attendenceStatus}</span>
                                    {/* )} */}
                                  </div>
                                );
                              })}
                              <div className={`attendance_caln `}>
                                <span>{e?.total_p}</span>
                                {/* )} */}
                              </div>
                              <div className={`attendance_caln `}>
                                <span>{e?.total_a}</span>
                                {/* )} */}
                              </div>
                              <div className={`attendance_caln `}>
                                <span>{e?.total_wo}</span>
                                {/* )} */}
                              </div>
                              <div className={`attendance_caln `}>
                                <span>{e?.total_nh}</span>
                                {/* )} */}
                              </div>
                              <div className={`attendance_caln `}>
                                <span>{e?.total_hd}</span>
                                {/* )} */}
                              </div>
                            </>
                          </div>
                          <div class="d-flex mt-1">
                            <>
                              {e?.attendence.map((d, i) => {
                                return (
                                  <div class="attendance_caln">
                                    {" "}
                                    {d?.ot_hour}
                                    {/* <input class="form-control" type="text" /> */}
                                  </div>
                                );
                              })}
                              <div className={`attendance_caln `}>
                                <span>{e?.totalOt}</span>
                                {/* )} */}
                              </div>
                            </>
                          </div>
                          {/* <div class="d-flex">
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln bg-danger text-white">A</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln bg-danger text-white">A</div>
                      <div class="attendance_caln bg-danger text-white">A</div>
                      <div class="attendance_caln bg-danger text-white">A</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln bg-warning">H</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln bg-warning">H</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                    </div>
                    <div class="d-flex mt-1">
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                    </div> */}
                        </td>
                        {/* <td>
                          <div class="d-flex">
                            <button class="me-1 bg-danger border-0 text-white rounded">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="white"
                                class="bi bi-pencil-square"
                                viewBox="0 0 16 16"
                              >
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"></path>
                                <path
                                  fill-rule="evenodd"
                                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                                ></path>
                              </svg>
                            </button>
                            <button class="bg-dark border-0 text-white rounded">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-save"
                                viewBox="0 0 16 16"
                              >
                                <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1z"></path>
                              </svg>
                            </button>
                          </div>
                        </td> */}
                      </tr>
                    ))
                  : null}
                {/* <tr>
                  <th scope="row">#236598B1</th>
                  <td>Ravindranat Thakur</td>
                  <td>Web developer</td>
                  <td>
                    <div>ATD</div>
                    <div>OT</div>
                  </td>
                  <td>
                    <div class="d-flex">
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln bg-danger text-white">A</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln bg-danger text-white">A</div>
                      <div class="attendance_caln bg-danger text-white">A</div>
                      <div class="attendance_caln bg-danger text-white">A</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln bg-warning">H</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln bg-warning">H</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                    </div>
                    <div class="d-flex mt-1">
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex">
                      <button class="me-1 bg-danger border-0 text-white rounded">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="white"
                          class="bi bi-pencil-square"
                          viewBox="0 0 16 16"
                        >
                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"></path>
                          <path
                            fill-rule="evenodd"
                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                          ></path>
                        </svg>
                      </button>
                      <button class="bg-dark border-0 text-white rounded">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-save"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1z"></path>
                        </svg>
                      </button>
                    </div>
                  </td>
                </tr>

                <tr>
                  <th scope="row">#236598B1</th>
                  <td>Ravindranat Thakur</td>
                  <td>Web developer</td>
                  <td>
                    <div>ATD</div>
                    <div>OT</div>
                  </td>
                  <td>
                    <div class="d-flex">
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln bg-danger text-white">A</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln bg-danger text-white">A</div>
                      <div class="attendance_caln bg-danger text-white">A</div>
                      <div class="attendance_caln bg-danger text-white">A</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln bg-warning">H</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln bg-warning">H</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                    </div>
                    <div class="d-flex mt-1">
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex">
                      <button class="me-1 bg-danger border-0 text-white rounded">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="white"
                          class="bi bi-pencil-square"
                          viewBox="0 0 16 16"
                        >
                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"></path>
                          <path
                            fill-rule="evenodd"
                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                          ></path>
                        </svg>
                      </button>
                      <button class="bg-dark border-0 text-white rounded">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-save"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1z"></path>
                        </svg>
                      </button>
                    </div>
                  </td>
                </tr>

                <tr>
                  <th scope="row">#236598B1</th>
                  <td>Ravindranat Thakur</td>
                  <td>Web developer</td>
                  <td>
                    <div>ATD</div>
                    <div>OT</div>
                  </td>
                  <td>
                    <div class="d-flex">
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln bg-danger text-white">A</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln bg-danger text-white">A</div>
                      <div class="attendance_caln bg-danger text-white">A</div>
                      <div class="attendance_caln bg-danger text-white">A</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln bg-warning">H</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln bg-warning">H</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                    </div>
                    <div class="d-flex mt-1">
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex">
                      <button class="me-1 bg-danger border-0 text-white rounded">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="white"
                          class="bi bi-pencil-square"
                          viewBox="0 0 16 16"
                        >
                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"></path>
                          <path
                            fill-rule="evenodd"
                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                          ></path>
                        </svg>
                      </button>
                      <button class="bg-dark border-0 text-white rounded">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-save"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1z"></path>
                        </svg>
                      </button>
                    </div>
                  </td>
                </tr>

                <tr>
                  <th scope="row">#236598B1</th>
                  <td>Ravindranat Thakur</td>
                  <td>Web developer</td>
                  <td>
                    <div>ATD</div>
                    <div>OT</div>
                  </td>
                  <td>
                    <div class="d-flex">
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln bg-danger text-white">A</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln bg-danger text-white">A</div>
                      <div class="attendance_caln bg-danger text-white">A</div>
                      <div class="attendance_caln bg-danger text-white">A</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln bg-warning">H</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln bg-warning">H</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                    </div>
                    <div class="d-flex mt-1">
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex">
                      <button class="me-1 bg-danger border-0 text-white rounded">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="white"
                          class="bi bi-pencil-square"
                          viewBox="0 0 16 16"
                        >
                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"></path>
                          <path
                            fill-rule="evenodd"
                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                          ></path>
                        </svg>
                      </button>
                      <button class="bg-dark border-0 text-white rounded">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-save"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1z"></path>
                        </svg>
                      </button>
                    </div>
                  </td>
                </tr>

                <tr>
                  <th scope="row">#236598B1</th>
                  <td>Ravindranat Thakur</td>
                  <td>Web developer</td>
                  <td>
                    <div>ATD</div>
                    <div>OT</div>
                  </td>
                  <td>
                    <div class="d-flex">
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln bg-danger text-white">A</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln bg-danger text-white">A</div>
                      <div class="attendance_caln bg-danger text-white">A</div>
                      <div class="attendance_caln bg-danger text-white">A</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln bg-warning">H</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln bg-warning">H</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                      <div class="attendance_caln">P</div>
                    </div>
                    <div class="d-flex mt-1">
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                      <div class="attendance_caln">
                        <input class="form-control" type="text" />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex">
                      <button class="me-1 bg-danger border-0 text-white rounded">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="white"
                          class="bi bi-pencil-square"
                          viewBox="0 0 16 16"
                        >
                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"></path>
                          <path
                            fill-rule="evenodd"
                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                          ></path>
                        </svg>
                      </button>
                      <button class="bg-dark border-0 text-white rounded">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-save"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1z"></path>
                        </svg>
                      </button>
                    </div>
                  </td>
                </tr> */}
              </tbody>
            </table>
            {isEdit && (
              <AttendenceModal show={isEdit} setShow={setEdit}>
                <Formik
                  initialValues={{
                    users: result.map((r) => ({
                      firstName: r?.user?.firstName,
                      lastName: r?.user?.lastName,
                      userId: r?.user?._id,
                      attendenceStatus: selectedStatus,
                      ot_hour: 0,
                      isChecked: false,
                      attendDate: selectedDate,
                      siteId: r?.user?.siteId,
                    })),
                    selectedDate: moment(selectedMonth, "YYYY-MM").format(
                      "YYYY-MM-DD"
                    ),
                  }}
                  onSubmit={
                    (values, { resetForm }) => submit(values, resetForm)
                    // console.log(values)
                  }
                  // validationSchema={
                  //   // isEdit ? EditUserValidation : AddUserValidation
                  // }
                  enableReinitialize={true}
                >
                  {({
                    handleChange,
                    handleSubmit,
                    errors,
                    touched,
                    values,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-sm-4">
                          <input
                            type="date"
                            name="selectedDate"
                            value={values.attendDate}
                            onChange={(e) => {
                              setFieldValue("selectedDate", e.target.value);
                              setSelectedDate(e.target.value);
                            }}
                            className="form-control"
                            max={moment().format("YYYY-MM-DD")}
                            min={moment(selectedMonth, "YYYY-MM").format(
                              "YYYY-MM-DD"
                            )}
                          />
                        </div>
                        <div className="col-sm-4">
                          <select
                            className="form-control"
                            onChange={(e) => setSelectedStatus(e.target.value)}
                          >
                            <option value="A">A</option>
                            <option value="P">P</option>
                            <option value="NH"> NH</option>
                            <option value="HD">HD</option>
                            <option value="WO">WO</option>
                          </select>
                        </div>
                        <div className="col-sm-4">
                          <input class="form-control" type="text" />
                        </div>
                        {/* <div className="col-sm-3">
                    <input type="text" />
                  </div> */}
                      </div>
                      <div className="row">
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">
                                <input type="checkbox" />
                              </th>
                              <th scope="col">Name</th>
                              <th scope="col">Attendance</th>
                              <th scope="col">OT</th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* {(JSON.stringify(values), JSON.stringify(result))} */}
                            <FieldArray
                              name="users"
                              render={(arrayHelpers) => (
                                <>
                                  {values.users &&
                                    values.users.length > 0 &&
                                    values.users.map((user, index) => (
                                      <tr>
                                        <th scope="row">
                                          <input type="checkbox" />
                                        </th>
                                        <td>
                                          {`${user?.firstName} ${user?.lastName}`}{" "}
                                        </td>
                                        <td>
                                          <Field
                                            class="form-control"
                                            as="select"
                                            name={`users.${index}.attendenceStatus`}
                                          >
                                            <option value="A">A</option>
                                            <option value="P">P</option>
                                            <option value="NH"> NH</option>
                                            <option value="HD">HD</option>
                                            <option value="WO">WO</option>
                                          </Field>
                                        </td>
                                        <td>
                                          <Field
                                            class="form-control"
                                            type="number"
                                            name={`users.${index}.ot_hour`}
                                          />
                                        </td>
                                      </tr>
                                    ))}
                                </>
                              )}
                            />
                            {/* ))} */}
                          </tbody>
                        </table>
                      </div>
                      <button class="btn btn-primary" type="submit">
                        Submit
                      </button>
                    </form>
                  )}
                </Formik>
              </AttendenceModal>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
