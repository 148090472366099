import React, { Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthLayout from "./components/layouts/AuthLayout";
import Layout from "./components/layouts/Layout";
import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import { Navigate } from "react-router";

const toRouteComponent = (route) => {
  const renderProps = {
    // 	isAuthenticated,

    //	logoutInProgress,
    route,
  };
  //console.log("data>>>", route);
  //	const isExact = route.exact != null ? route.exact : true;
  const isExact = true;
  // const { route, match, location, staticContext } = this.props;
  return (
    <Route
      key={Math.random()}
      path={route.path}
      exact={isExact}
      //element={<route.component />}
      element={
        <RouteComponentContainer
          {...renderProps}
          //match={matchProps.match}
          //location={matchProps.location}
          //staticContext={matchProps.staticContext}
        />
      }
    />
  );
};

class RouteComponentRenderer extends React.Component {
  componentDidMount() {
    // Calling loadData on initial rendering (on client side).
    callLoadData(this.props);
    // handleLocationChanged(this.props.dispatch, this.props.location);
  }
  componentDidUpdate(prevProps) {
    // Call for handleLocationChanged affects store/state
    // and it generates an unnecessary update.
    // if (prevProps.location !== this.props.location) {
    //   // Calling loadData after initial rendering (on client side).
    //   // This makes it possible to use loadData as default client side data loading technique.
    //   // However it is better to fetch data before location change to avoid "Loading data" state.
    //   callLoadData(this.props);
    //   handleLocationChanged(this.props.dispatch, this.props.location);
    // }
  }
  render() {
    const { route, match, location, staticContext } = this.props;
    const canShow = canShowComponent(this.props);
    // console.log("props>>", this.props);
    return canShow ? (
      <route.component {...this.props} routes={route.routes} />
    ) : (
      <Navigate to={route.authPage} />
    );
  }
}
const mapStateToProps = (state) => {
  const { isAuthenticated, logoutInProgress, user } = state.auth;
  return { isAuthenticated, logoutInProgress, currentUser: user };
};
const RouteComponentContainer = compose(connect(mapStateToProps))(
  RouteComponentRenderer
);

const canShowComponent = (props) => {
  const { isAuthenticated, route, currentUser } = props;
  const { auth, restrict } = route;
  if (!auth) {
    return true;
  } else if (isAuthenticated) {
    if (restrict && restrict.length > 0) {
      const isExist = restrict.filter((r) => r == currentUser.role);
      if (isExist.length > 0) return true;
      else return false;
    } else {
      return true;
    }
  }
  // return !auth || isAuthenticated;
};

const callLoadData = (props) => {
  const { match, location, route, dispatch, logoutInProgress } = props;
  const { loadData, name } = route;
  const shouldLoadData = typeof loadData === "function";
  //   &&
  //   canShowComponent(props) &&
  //   !logoutInProgress;

  if (shouldLoadData) {
    dispatch(loadData())
      .then(() => {
        // eslint-disable-next-line no-console
        //  console.log(`loadData success for ${name} route`);
      })
      .catch((e) => {
        console.error(e, "load-data-failed", { routeName: name });
      });
  }
};

export default class Router extends Component {
  render() {
    const { routes } = this.props;
    const authRoutes = routes.filter((r) => !r?.auth);
    const restrictedRoutes = routes.filter((r) => r?.auth);
    return (
      <>
        <BrowserRouter>
          <Routes>
            <Route path="" element={<AuthLayout />}>
              {authRoutes.map(toRouteComponent)}
            </Route>
            <Route path="/" element={<Layout />}>
              {restrictedRoutes.map(toRouteComponent)}
            </Route>
          </Routes>
        </BrowserRouter>
      </>
    );
  }
}
