import React, { useEffect } from "react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import Network from "../../helpers/network";
import api from "../../config/api";
import { Formik } from "formik";
import { EmployeeSalaryBreakDownValidation } from "./Employee.validation";
import { bankAccountMasking, camelToTitle } from "../../helpers/data";

export default function EmployeeSalaryForm() {
  const [user, setUser] = useState(null);
  const [userId, setUserId] = useState(null);
  const [isError, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [message, setMessage] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const getUser = async (id) => {
    try {
      if (id) {
        setUserId(searchParams.get("id"));
        const result = await Network.get(api.getUser, { id: id });
        console.log("user >>", result);
        setUser(result?.data?.data);
        // setMessage("User has been fatched successfully");
        // setSuccess(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const addSalaryBreakdownHandler = async (values, resetForm) => {
    try {
      console.log(values);
      return;
      const res = await Network.post(api.addSalary, values);
      console.log(res);
    } catch (err) {}
  };
  useEffect(() => {
    // setUserId(searchParams.get("id"));
    getUser(searchParams.get("id"));
  }, [searchParams]);
  return (
    <div class="col-md-10 col-sm-12  bashboard_boxes_open no_mar mb-2">
      <div class="bg-white mt-3 rounded p-4 m-4`">
        <div class="row">
          <Formik
            initialValues={{
              basic: 0,
              da: 0,
              hra: 0,
              other_allowance: 0,
              all_cash_reimbursement: 0,
              lta: 0,
              medical: 0,
              arrears: 0,
              pf: 0,
              esi: 0,
              income_tax: 0,
              professional_tax: 0,
              loans_and_advances: 0,
              perquisitess: 0,
              total_deduction: 0,
              total_earning: 0,
              net_pay: 0,
              insurance_deduction: 0,
            }}
            onSubmit={(values, { resetForm }) => {
              addSalaryBreakdownHandler(values, resetForm);
            }}
            validationSchema={EmployeeSalaryBreakDownValidation}
            enableReinitialize={true}
          >
            {({
              handleChange,
              handleSubmit,
              errors,
              touched,
              values,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                {isError && <div class="alert alert-danger">{message}</div>}
                {isSuccess && <div class="alert alert-success">{message}</div>}
                <div class="col-md-12">
                  <div class=" lh-1 mb-2">
                    <h6 class="fw-bold">Pay structure</h6>{" "}
                    {/* <span class="fw-normal">
                Payment slip for the month of June 2023
              </span> */}
                  </div>
                  <div class="d-flex justify-content-end">
                    <span>
                      Working Branch:{user && user?.siteId?.site_name}{" "}
                    </span>{" "}
                  </div>
                  <div class="row">
                    <div class="col-md-10">
                      <div class="row">
                        <div class="col-md-6">
                          <div>
                            {" "}
                            <span class="fw-bolder">EMP Code</span>{" "}
                            <small class="ms-3">{user && user?.empId}</small>{" "}
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div>
                            {" "}
                            <span class="fw-bolder">EMP Name</span>{" "}
                            <small class="ms-3">
                              {user && user?.firstName + "" + user?.lastName}
                            </small>{" "}
                          </div>
                        </div>
                      </div>
                      {/* <div class="row">
                  <div class="col-md-6">
                    <div>
                      {" "}
                      <span class="fw-bolder">PF No.</span>{" "}
                      <small class="ms-3">101523065714</small>{" "}
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div>
                      {" "}
                      <span class="fw-bolder">NOD</span>{" "}
                      <small class="ms-3">28</small>{" "}
                    </div>
                  </div>
                </div> */}
                      <div class="row">
                        <div class="col-md-6">
                          <div>
                            {" "}
                            <span class="fw-bolder">UAN No.</span>{" "}
                            <small class="ms-3">{user && user?.UAN_no}</small>{" "}
                          </div>
                        </div>
                        {/* <div class="col-md-6">
                    <div>
                      {" "}
                      <span class="fw-bolder">Mode of Pay</span>{" "}
                      <small class="ms-3">SBI</small>{" "}
                    </div>
                  </div> */}
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div>
                            {" "}
                            <span class="fw-bolder">Designation</span>{" "}
                            <small class="ms-3">{user && user?.role}</small>{" "}
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div>
                            {" "}
                            <span class="fw-bolder">Ac No.</span>{" "}
                            <small class="ms-3">
                              {user &&
                                bankAccountMasking(
                                  user?.accountDetails?.accountNumber
                                )}
                            </small>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                    <table class="mt-4 table table-bordered">
                      <thead class="bg-light  text-danger">
                        <tr>
                          <th
                            style={{ width: "30%" }}
                            class="text-danger"
                            scope="col"
                          >
                            Earnings
                          </th>
                          <th
                            style={{ width: "20%" }}
                            class="text-danger"
                            scope="col"
                          >
                            Amount
                          </th>
                          <th
                            style={{ width: "30%" }}
                            class="text-danger"
                            scope="col"
                          >
                            Deductions
                          </th>
                          <th
                            style={{ width: "20%" }}
                            class="text-danger"
                            scope="col"
                          >
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">Basic</th>
                          <td>
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              onChange={handleChange("basic")}
                              value={values.basic}
                            />
                            {errors.basic && touched.basic ? (
                              <div class="text-danger">
                                {camelToTitle(errors.basic)}
                              </div>
                            ) : null}
                          </td>
                          <td>PF</td>
                          <td>
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              onChange={handleChange("pf")}
                              value={values.pf}
                            />
                            {errors.pf && touched.pf ? (
                              <div class="text-danger">
                                {camelToTitle(errors.pf)}
                              </div>
                            ) : null}
                          </td>
                        </tr>

                        <tr>
                          <th scope="row">HRA</th>
                          <td>
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              onChange={handleChange("hra")}
                              value={values.hra}
                            />{" "}
                            {errors.hra && touched.hra ? (
                              <div class="text-danger">
                                {camelToTitle(errors.hra)}
                              </div>
                            ) : null}
                          </td>
                          <td>E.S.I</td>
                          <td>
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              onChange={handleChange("esi")}
                              value={values.esi}
                            />
                            {errors.esi && touched.esi ? (
                              <div class="text-danger">
                                {camelToTitle(errors.esi)}
                              </div>
                            ) : null}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Medical Allowance</th>
                          <td>
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              onChange={handleChange("medical")}
                              value={values.medical}
                            />
                            {errors.medical && touched.medical ? (
                              <div class="text-danger">
                                {camelToTitle(errors.medical)}
                              </div>
                            ) : null}
                          </td>
                          <td>Income tax</td>
                          <td>
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              onChange={handleChange("income_tax")}
                              value={values.income_tax}
                            />
                            {errors.income_tax && touched.income_tax ? (
                              <div class="text-danger">
                                {camelToTitle(errors.income_tax)}
                              </div>
                            ) : null}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Other allowance</th>
                          <td>
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              onChange={handleChange("other_allowance")}
                              value={values.other_allowance}
                            />{" "}
                            {errors.other_allowance &&
                            touched.other_allowance ? (
                              <div class="text-danger">
                                {camelToTitle(errors.other_allowance)}
                              </div>
                            ) : null}
                          </td>
                          <td>Professional tax</td>
                          <td>
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              onChange={handleChange("professional_tax")}
                              value={values.professional_tax}
                            />
                            {errors.professional_tax &&
                            touched.professional_tax ? (
                              <div class="text-danger">
                                {camelToTitle(errors.professional_tax)}
                              </div>
                            ) : null}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Loans and advances</th>
                          <td>
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              onChange={handleChange("loans_and_advances")}
                              value={values.loans_and_advances}
                            />
                            {errors.loans_and_advances &&
                            touched.loans_and_advances ? (
                              <div class="text-danger">
                                {camelToTitle(errors.loans_and_advances)}
                              </div>
                            ) : null}
                          </td>
                          <td>SPL. Deduction</td>
                          <td>
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              onChange={handleChange("perquisites")}
                              value={values.perquisitess}
                            />
                            {errors.perquisitess && touched.perquisitess ? (
                              <div class="text-danger">
                                {camelToTitle(errors.perquisitess)}
                              </div>
                            ) : null}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">DA</th>
                          <td>
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              onChange={handleChange("da")}
                              value={values.da}
                            />
                          </td>
                          <td>Insurance Deduction</td>
                          <td>
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              onChange={handleChange("insurance_deduction")}
                              value={values.insurance_deduction}
                            />
                            {errors.insurance_deduction &&
                            touched.insurance_deduction ? (
                              <div class="text-danger">
                                {camelToTitle(errors.insurance_deduction)}
                              </div>
                            ) : null}
                          </td>
                        </tr>
                        <tr class="border-top">
                          <th class="text-danger fw-bold" scope="row">
                            Total Earning
                          </th>
                          <td class="text-danger fw-bold">
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              readOnly={true}
                              onChange={handleChange("total_earning")}
                              value={
                                (parseFloat(values.basic)
                                  ? parseFloat(values.basic)
                                  : 0) +
                                (parseFloat(values.hra)
                                  ? parseFloat(values.hra)
                                  : 0) +
                                (parseFloat(values.medical)
                                  ? parseFloat(values.medical)
                                  : 0) +
                                (parseFloat(values.other_allowance)
                                  ? parseFloat(values.other_allowance)
                                  : 0) +
                                (parseFloat(values.loans_and_advances)
                                  ? parseFloat(values.loans_and_advances)
                                  : 0)
                              }
                            />
                          </td>
                          <td class="text-danger fw-bold">Total Deductions</td>
                          <td class="text-danger fw-bold">
                            <input
                              class="form-control"
                              placeholder="00"
                              type="text"
                              readOnly={true}
                              onChange={handleChange("total_deduction")}
                              value={
                                (parseFloat(values.pf)
                                  ? parseFloat(values.pf)
                                  : 0) +
                                (parseFloat(values.esi)
                                  ? parseFloat(values.esi)
                                  : 0) +
                                (parseFloat(values.income_tax)
                                  ? parseFloat(values.income_tax)
                                  : 0) +
                                (parseFloat(values.professional_tax)
                                  ? parseFloat(values.professional_tax)
                                  : 0) +
                                (parseFloat(values.perquisitess)
                                  ? parseFloat(values.perquisitess)
                                  : 0) +
                                (parseFloat(values.insurance_deduction)
                                  ? parseFloat(values.insurance_deduction)
                                  : 0)
                              }
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      {" "}
                      <br />{" "}
                      <span class="fw-bold text-danger d-flex">
                        Net Pay :{" "}
                        <input
                          style={{ width: 200 }}
                          class="form-control ms-2"
                          placeholder="00"
                          type="text"
                          readOnly={true}
                          onChange={handleChange("net_pay")}
                          value={
                            (parseFloat(values.basic)
                              ? parseFloat(values.basic)
                              : 0) +
                            (parseFloat(values.hra)
                              ? parseFloat(values.hra)
                              : 0) +
                            (parseFloat(values.medical)
                              ? parseFloat(values.medical)
                              : 0) +
                            (parseFloat(values.other_allowance)
                              ? parseFloat(values.other_allowance)
                              : 0) +
                            (parseFloat(values.loans_and_advances)
                              ? parseFloat(values.loans_and_advances)
                              : 0) -
                            ((parseFloat(values.pf)
                              ? parseFloat(values.pf)
                              : 0) +
                              (parseFloat(values.esi)
                                ? parseFloat(values.esi)
                                : 0) +
                              (parseFloat(values.income_tax)
                                ? parseFloat(values.income_tax)
                                : 0) +
                              (parseFloat(values.professional_tax)
                                ? parseFloat(values.professional_tax)
                                : 0) +
                              (parseFloat(values.perquisitess)
                                ? parseFloat(values.perquisitess)
                                : 0) +
                              (parseFloat(values.insurance_deduction)
                                ? parseFloat(values.insurance_deduction)
                                : 0))
                          }
                        />
                      </span>{" "}
                    </div>
                    {/* <div class="border col-md-8">
                      <div class="d-flex flex-column fw-bold pt-3 pb-3">
                        <label class="b-block mb-2" for="">
                          In Words
                        </label>
                        <textarea
                          class="form-control"
                          placeholder=""
                        ></textarea>
                      </div>
                    </div> */}

                    <div class="text-end mt-4">
                      <button type="submit" class="btn btn-primary">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <div style={{ clear: "both" }}></div>
    </div>
  );
}
