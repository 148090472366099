import Home from "./containers/Home/Home";
import Login from "./containers/Login/Login";
import ForgotPassword from "./containers/ForgotPassword/ForgotPassword";
import Otpvarify from "./containers/Otpvarify/Otpvarify";
import Users from "./containers/Users/Users";
import UserForm from "./containers/Users/UserForm";

import Settings from "./containers/Settings/Settings";
import SettingsForm from "./containers/Settings/SettingsForm";

import ResetPassword from "./containers/ResetPassword/ResetPassword";
import Roles from "./containers/Roles/Roles";
import RolesForm from "./containers/Roles/RolesForm";
import Sites from "./containers/Sites/Sites";
import SiteForm from "./containers/Sites/SiteForm";
import Vendors from "./containers/Vendors/Vendors";
import VendorForm from "./containers/Vendors/VendorForm";
import UserDetails from "./containers/Users/UserDetails";
import Attendance from "./containers/Attendance/Attendance";
import Invoices from "./containers/Invoices/Invoices";
import Clients from "./containers/Clients/Client";
import ClientForm from "./containers/Clients/ClientForm";
import AttendanceDashboard from "./containers/Attendance/AttendanceDashboard";
import UserRole from "./containers/UserRole/UserRole";
import UserRoleForm from "./containers/UserRole/UserRoleForm";
import SalaryInfo from "./containers/Salary/SalaryInfo";
import SalaryView from "./containers/Salary/SalaryView";
import SalaryAdd from "./containers/Salary/SalaryAdd";
import Employee from "./containers/Employees/Employees";
import EmployeeDetails from "./containers/Employees/EmployeeDetails";
import EmployeeSalaryBreakDown from "./containers/Employees/EmployeeSalaryBreakDown";
import EmployeeSalaryForm from "./containers/Employees/EmployeeSalaryForm";
import EmployeeIDUploadForm from "./containers/Employees/EmployeeIDUploadForm";
import EmployeeSalaryPayForm from "./containers/Employees/EmployeeSalaryPayForm";
import AttendanceForm from "./containers/Attendance/AttendanceForm";
import InvoiceDetails from "./containers/Invoices/InvoiceDetails";
import EmployeeCard from "./containers/Employees/EmployeeCard";
import Payments from "./containers/Payments/Payments";
import Expenses from "./containers/Expenses/Expenses";
import ExpenseForm from "./containers/Expenses/ExpenseForm";
// import EmployeeIDUploadForm from "./containers/Employees/EmployeeIDUploadForm";

const routeConfig = () => {
  return [
    { path: "", component: Login },
    { path: "/home", component: Home, auth: true, authPage: "/" },
    { path: "/forgot-password", component: ForgotPassword },
    { path: "/otp-varify", component: Otpvarify },

    { path: "/reset-password", component: ResetPassword },
    { path: "/users", component: Users, auth: true, authPage: "/" },
    {
      path: "/user-details",
      component: UserDetails,
      auth: true,
      authPage: "/",
    },
    { path: "/user-add", component: UserForm, auth: true, authPage: "/" },
    { path: "/user-edit", component: UserForm, auth: true, authPage: "/" },
    // { path: "/roles", component: Roles, auth: true, authPage: "/" },
    // { path: "/role-add", component: RolesForm, auth: true, authPage: "/" },
    // { path: "/role-edit", component: RolesForm, auth: true, authPage: "/" },
    { path: "/sites", component: Sites, auth: true, authPage: "/" },
    { path: "/site-add", component: SiteForm, auth: true, authPage: "/" },
    { path: "/site-edit", component: SiteForm, auth: true, authPage: "/" },
    { path: "/clients", component: Clients, auth: true, authPage: "/" },
    { path: "/client-add", component: ClientForm, auth: true, authPage: "/" },
    { path: "/client-edit", component: ClientForm, auth: true, authPage: "/" },
    { path: "/vendors", component: Vendors, auth: true, authPage: "/" },
    { path: "/vendor-add", component: VendorForm, auth: true, authPage: "/" },
    { path: "/vendor-edit", component: VendorForm, auth: true, authPage: "/" },
    { path: "/employees", component: Employee, auth: true, authPage: "/" },
    {
      path: "/employees-other-details-upload",
      component: EmployeeIDUploadForm,
      auth: true,
      authPage: "/",
    },
    {
      path: "/employee-details",
      component: EmployeeDetails,
      auth: true,
      authPage: "/",
    },
    {
      path: "/employee-salary-details",
      component: EmployeeSalaryBreakDown,
      auth: true,
      authPage: "/",
    },
    {
      path: "/employee-salary-pay",
      component: EmployeeSalaryPayForm,
      auth: true,
      authPage: "/",
    },
    {
      path: "/employee-card",
      component: EmployeeCard,
      auth: true,
      authPage: "/",
    },
    {
      path: "/employee-salary-details-edit",
      component: EmployeeSalaryForm,
      auth: true,
      authPage: "/",
    },

    {
      path: "/attendances",
      component: AttendanceDashboard,
      auth: true,
      authPage: "/",
    },
    {
      path: "/attendance",
      component: AttendanceForm,
      auth: true,
      authPage: "/",
    },
    {
      path: "/payments",
      component: Payments,
      auth: true,
      authPage: "/",
    },
    {
      path: "/expenses",
      component: Expenses,
      auth: true,
      authPage: "/",
    },
    {
      path: "/expenses-add",
      component: ExpenseForm,
      auth: true,
      authPage: "/",
    },
    {
      path: "/attendance-details",
      component: Attendance,
      auth: true,
      authPage: "/",
    },
    { path: "/invoices", component: Invoices, auth: true, authPage: "/" },
    {
      path: "/invoices-details",
      component: InvoiceDetails,
      auth: true,
      authPage: "/",
    },
    { path: "/roles", component: UserRole, auth: true, authPage: "/" },
    {
      path: "/role-edit",
      component: UserRoleForm,
      auth: true,
      authPage: "/",
    },
    { path: "/salary", component: SalaryInfo, auth: true, authPage: "/" },
    { path: "/view", component: SalaryView, auth: true, authPage: "/" },
    { path: "/add", component: SalaryAdd, auth: true, authPage: "/" },
    { path: "/salary-edit", component: SalaryAdd, auth: true, authPage: "/" },
  ];
};
export default routeConfig;
