import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { camelToTitle, employeeRoleOpetions } from "../../helpers/data";
import MultiSelectOption from "../../components/MultiSelectOption/MultiSelectOption";
import Network from "../../helpers/network";
import api from "../../config/api";
import Loader from "../../components/Loader/Loader";

export default function UserRoleForm() {
  const [isEdit, setEdit] = useState(false);
  const [isError, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [message, setMessage] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [userId, setUserId] = useState(null);
  const [user, setUser] = useState(null);
  const [sites, setSites] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();
  const goToSites = () => {
    navigate("/roles");
  };
  // const getEmployees = async () => {
  //   try {
  //     const res = await Network.get(api.employees, { limit: -1 });
  //     console.log(res);
  //     setUsers(res?.data?.data?.users);
  //   } catch (err) {}
  // };
  const clearStatus = () => {
    setError(false);
    setSuccess(false);
    setMessage(null);
  };
  const getSites = async () => {
    try {
      const res = await Network.get(api.sites, { limit: -1 });
      console.log(res);
      setSites(res?.data?.data);
    } catch (err) {}
  };
  const submit = async (values) => {
    console.log("value>>", values);
    try {
      clearStatus();
      const updateUser = await Network.put(api.updateUser, values);
      setMessage("User has been updated successfully");
      setSuccess(true);
    } catch (err) {}
  };
  const getUser = async (id) => {
    try {
      if (id && !isEdit) {
        setUserId(searchParams.get("id"));
        setEdit(true);
        // clearStatus();
        const result = await Network.get(api.getUser, { id: id });
        console.log(result);
        setUser(result?.data?.data);
        // setMessage("User has been fatched successfully");
        // setSuccess(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    // getEmployees();
    getSites();
  }, []);
  useEffect(() => {
    getUser(searchParams.get("id"));
  }, [searchParams]);
  return (
    <>
      <div class="col-md-10 col-sm-12  bashboard_boxes_open no_mar mb-2">
        <div class="purchase_order_buttom_nav mt-0">
          <div class=" mt-0 mb-0">
            <nav class="navbar bg-body-tertiary">
              <div class="container-fluid">
                <form class="d-flex ">
                  <h6 class="navbar-brand text-white">Sites </h6>
                </form>
                <ul class="d-flex nav justify-content-end">
                  {/* <li class="nav-item">
                    <a href="#">
                      <button class="nav-link bg-primary text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined">
                          add_circle
                        </span>
                      </button>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="flow_through_details.html">
                      <button class="nav-link bg-success text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined">edit_note</span>
                      </button>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="flow_through_details.html">
                      <button class="nav-link bg-success text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined d-block">
                          {" "}
                          save{" "}
                        </span>
                      </button>
                    </a>
                  </li> */}
                  <li class="nav-item">
                    <a href="javascript:void(0);" onClick={goToSites}>
                      <button class="nav-link bg-danger text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined">cancel</span>{" "}
                      </button>
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div class="container-fluid pt-3">
          {isLoading && <Loader />}
          <div class="row">
            <Formik
              initialValues={{
                id: user?._id,
                role: user?.role,
                site: user?.siteId?._id,
              }}
              onSubmit={(values, { resetForm }) => submit(values, resetForm)}
              // validationSchema={isEdit ? EditSiteValidation : AddSiteValidation}
              enableReinitialize={true}
            >
              {({
                handleChange,
                handleSubmit,
                errors,
                touched,
                values,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  {isError && <div class="alert alert-danger">{message}</div>}
                  {isSuccess && (
                    <div class="alert alert-success">{message}</div>
                  )}
                  <div class="col-sm-12">
                    <h2 class="heading_style">
                      <a href="#">Role assigned</a>
                    </h2>
                    <div class="card mb-3">
                      <div class="row g-0 p-1">
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item border-radius">
                            <div class="row">
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Employee
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="text"
                                      value={
                                        user?.firstName + " " + user?.lastName
                                      }
                                      class="form-control"
                                      readOnly
                                    />
                                    {errors.site_name && touched.site_name ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.site_name)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Role
                                  </label>
                                  <div class="col-sm-7">
                                    <Field
                                      as="select"
                                      class="form-control form-control-sm"
                                      name="role"
                                      onChange={handleChange}
                                      value={values.role}
                                    >
                                      <option>Select Role</option>
                                      {employeeRoleOpetions().map((r) => (
                                        <option value={r?.key}>
                                          {r?.value}
                                        </option>
                                      ))}
                                    </Field>
                                    {errors.role && touched.role ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.role)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Site
                                  </label>
                                  <div class="col-sm-7">
                                    <Field
                                      as="select"
                                      class="form-control form-control-sm"
                                      name="site"
                                      onChange={handleChange}
                                      value={values.site}
                                    >
                                      <option>Select Site</option>
                                      {sites &&
                                        sites.map((s) => (
                                          <option value={s?._id}>
                                            {s?.site_name}
                                          </option>
                                        ))}
                                    </Field>
                                    {errors.site && touched.site ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.site)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="card-body text-center">
                      <div>
                        {/* <button type="button" class="btn btn-success">
                    <span class="material-symbols-outlined float-start me-1">
                      add_circle
                    </span>{" "}
                    Add More Child
                  </button> */}
                        <button type="submit" class="btn btn-success">
                          {" "}
                          Submit{" "}
                          <span class="material-symbols-outlined float-end ms-1">
                            send
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}
