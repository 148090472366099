import * as Yup from "yup";

export const AddClientValidation = Yup.object().shape({
  client_name: Yup.string().trim().required("Client name is required"),
  client_contact_no: Yup.string().trim().required("Contact number is required"),
  client_city: Yup.string().trim().required("City is required"),
  client_address: Yup.string().trim().required("Address is required"),
  sites: Yup.array().of(Yup.string()),
});

export const EditClientValidation = Yup.object().shape({
  client_name: Yup.string().trim().required(),
  client_contact_no: Yup.string().trim().required(),
  client_city: Yup.string().trim().required(),
  client_address: Yup.string().trim().required(),
  sites: Yup.array().of(Yup.string()),
});
