import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Network from "../../helpers/network";
import api from "../../config/api";
import moment from "moment";
import ExcelGenerator from "../../components/ExcelGenerator/ExcelGenerator";

export default function Attendance() {
  const [userDetails, setUserDetails] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedMonth, setSelectedMonth] = useState();
  const [result, setResult] = useState([]);
  const getUserDetails = async () => {
    try {
      const res = await Network.get(api.getUser, {
        id: searchParams.get("id"),
      });
      console.log("dashboard", res);
      setUserDetails(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getMonthWiseAttendence = async () => {
    try {
      const res = await Network.get(api.attendenceMonthWise, {
        gm: selectedMonth,
        userId: searchParams.get("id"),
      });
      console.log("dashboard2", res);
      setResult(res?.data?.data);
      // setUserDetails(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getUserDetails();
    setSelectedMonth(searchParams.get("m"));
  }, [searchParams]);
  useEffect(() => {
    getMonthWiseAttendence();
  }, [selectedMonth]);
  return (
    <>
      <div class="col-md-10 col-sm-12  bashboard_boxes_open no_mar mb-2">
        <div class="purchase_order_buttom_nav mt-0">
          <div class=" mt-0 mb-0">
            <nav class="navbar bg-body-tertiary">
              <div class="container-fluid">
                <form class="d-flex ">
                  <h6 class="navbar-brand text-white">Attendance</h6>
                </form>
                <ul class="d-flex nav justify-content-end">
                  <li class="nav-item">
                    <a href="#">
                      <button class="nav-link bg-primary text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined">
                          add_circle
                        </span>
                      </button>
                    </a>
                  </li>
                  {/* <li class="nav-item">
                    <a href="flow_through_details.html">
                      <button class="nav-link bg-success text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined">edit_note</span>
                      </button>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="#">
                      <button class="nav-link bg-success text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined d-block">
                          {" "}
                          save{" "}
                        </span>
                      </button>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="#">
                      <button class="nav-link bg-danger text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined">cancel</span>{" "}
                      </button>
                    </a>
                  </li> */}
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div class="container-fluid pt-3">
          <div class="row">
            <div class="col-sm-4">
              <div class="card p-3">
                <div className="row">
                  <div className="col-sm-3">
                    <div
                      style={{
                        backgroundColor: "lightblue",
                        width: "80px",
                        height: "80px",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        style={{ width: "100%" }}
                        src="https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG-Image.png"
                      />
                    </div>
                  </div>
                  <div className="col-sm-9">
                    {userDetails && (
                      <>
                        <h5>
                          {userDetails?.firstName} {userDetails?.lastName}
                        </h5>
                        <p className="mb-1">
                          Employee ID - {userDetails?.empId}
                        </p>
                        <p className="mb-1">Mobile No - {userDetails?.phone}</p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-8">
              <div class="card gate_entry_details2">
                <div class="table-content  tabile-responsive card-body">
                  <div class="row mb-2">
                    <div class="col-sm-6">
                      <div class="row form-group mb-0">
                        <label
                          for="inputPassword"
                          class="col-sm-4 col-form-label"
                        >
                          Select Month
                        </label>
                        <div class="col-sm-5">
                          <input
                            type="month"
                            class="form-control form-control-sm"
                            value={selectedMonth}
                            onChange={(e) => setSelectedMonth(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 text-end">
                      {result && result.length > 0 && (
                        <ExcelGenerator
                          excelData={result.map((r) => ({
                            name:
                              r?.userId?.firstName + " " + r?.userId?.lastName,
                            date: moment(r.attendDate).format("DD-MM-YYYY"),
                            status:
                              r.attendenceStatus === "true"
                                ? "Present"
                                : "Absent",
                          }))}
                          fileName={Date.now()}
                        />
                      )}
                    </div>
                  </div>
                  <table
                    id="example"
                    class="table   dt-responsive "
                    width="100%"
                  >
                    <thead>
                      <tr>
                        <th class="table-primary" width="50%">
                          Name
                        </th>
                        <th class="table-primary">Date</th>
                        <th class="table-primary">Attendance</th>
                      </tr>
                    </thead>
                    <tbody>
                      {result && result.length > 0 ? (
                        <>
                          {result.map((r) => (
                            <tr>
                              <td>
                                {r?.userId?.firstName +
                                  " " +
                                  r?.userId?.lastName}
                              </td>
                              <td>
                                {moment(r?.attendDate).format("DD-MM-YYYY")}
                              </td>
                              <td>Present</td>
                            </tr>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                      {/* <tr>
                        <td>Rahul Das</td>
                        <td>02-01-2024</td>
                        <td>Present</td>
                      </tr>
                      <tr className="bg-dark">
                        <td className="text-end text-white">
                          <b>Total Days</b>
                        </td>
                        <td className="text-white">
                          <b>02</b>
                        </td>
                        <td></td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* <div class="card-body mt-3">
                <nav aria-label="Page navigation example">
                  <ul class="pagination justify-content-center">
                    <li class="page-item disabled ">
                      <a class="page-link bg-success text-white">Previous</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">
                        1
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">
                        2
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">
                        3
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link bg-success text-white" href="#">
                        Next
                      </a>
                    </li>
                  </ul>
                </nav>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
