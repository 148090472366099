import logo from "./logo.svg";
import "./App.css";
import Router from "./Router";
import routeConfig from "./routerConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppContext from "./appContext";

function App() {
  const showToast = (type, msg) => {
    switch (type) {
      case "success":
        toast.success(msg);
        break;
      case "error":
        toast.error(msg);
        break;
    }
  };
  return (
    <>
      <AppContext.Provider value={{ showToast }}>
        <Router routes={routeConfig()} />
      </AppContext.Provider>
      <ToastContainer />
    </>
  );
}

export default App;
