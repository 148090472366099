import React, { useEffect, useState } from "react";
import moment from 'moment';
import Network from "../../helpers/network";
import api from "../../config/api";
import { useSearchParams } from "react-router-dom";
import { camelToTitle } from "../../helpers/data";

export default function EmployeeDetails() {
  const [user, setUser] = useState(null);
  const [userId, setUserId] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const getUser = async (id) => {
    try {
      if (id) {
        setUserId(searchParams.get("id"));
        const result = await Network.get(api.getUser, { id: id });
        setUser(result?.data?.data);
        console.log(result);
        // setMessage("User has been fatched successfully");
        // setSuccess(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    // setUserId(searchParams.get("id"));
    getUser(searchParams.get("id"));
  }, [searchParams]);
  return (
    <div className="col-md-10 col-sm-12  bashboard_boxes_open no_mar mb-2">
      <div className="purchase_order_buttom_nav mt-0">
        <div className=" mt-0 mb-0">
          <nav className="navbar bg-body-tertiary">
            <div className="container-fluid">
              <form className="d-flex ">
                <h6 className="navbar-brand text-white">Users Details</h6>
              </form>
              <ul className="d-flex nav justify-content-end">
                <li className="nav-item">
                  <a href="#">
                    <button className="nav-link bg-primary text-white rounded-5 me-1 px-2 border-0">
                      <span className="material-symbols-outlined">
                        add_circle
                      </span>
                    </button>
                  </a>
                </li>
                <li className="nav-item">
                  <a href="flow_through_details.html">
                    <button className="nav-link bg-success text-white rounded-5 me-1 px-2 border-0">
                      <span className="material-symbols-outlined">
                        edit_note
                      </span>
                    </button>
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#">
                    <button className="nav-link bg-success text-white rounded-5 me-1 px-2 border-0">
                      <span className="material-symbols-outlined d-block">
                        {" "}
                        save{" "}
                      </span>
                    </button>
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#">
                    <button className="nav-link bg-danger text-white rounded-5 me-1 px-2 border-0">
                      <span className="material-symbols-outlined">cancel</span>{" "}
                    </button>
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <div className="container-fluid">
        <div className="card mt-3">
          <section style={{ backgroundColor: "#eee" }}>
            <div className="container py-5">
              <div className="row">
                <div className="col-lg-4">
                  <div className="card mb-4">
                    <div className="card-body text-center">
                      <img
                        src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp"
                        alt="avatar"
                        className="rounded-circle img-fluid"
                        style={{ width: 150 }}
                      />
                      <h5 className="my-3">
                        {user?.firstName} {user?.lastName}
                      </h5>
                      <p className="">
                        {user?.role ? camelToTitle(user?.role) : ""}
                      </p>
                      <p className="">{`${user?.permanentAddress?.address},${user?.permanentAddress?.city},${user?.permanentAddress?.state},${user?.permanentAddress?.pincode} `}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div>
                    <h2 className="heading_style">
                      <a href="#">Personal Details</a>
                    </h2>
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">Full Name:</p>
                          </div>
                          <div className="col-sm-9">
                            <p className="text-muted mb-0">
                              {user?.firstName + " " + user?.lastName}
                            </p>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">Email:</p>
                          </div>
                          <div className="col-sm-9">
                            <p className="text-muted mb-0">{user?.email}</p>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">Phone:</p>
                          </div>
                          <div className="col-sm-9">
                            <p className="text-muted mb-0">{user?.phone}</p>
                          </div>
                        </div>
                        <hr />
                        {/* <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">Mobile</p>
                          </div>
                          <div className="col-sm-9">
                            <p className="text-muted mb-0">(098) 765-4321</p>
                          </div>
                        </div> */}
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">Address:</p>
                          </div>
                          <div className="col-sm-9">
                            <p className="text-muted mb-0">
                              {`${user?.permanentAddress?.address},${user?.permanentAddress?.city},${user?.permanentAddress?.state},${user?.permanentAddress?.pincode} `}
                            </p>
                          </div>
                        </div>
                        <hr/>
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">DOB:</p>
                          </div>
                          <div className="col-sm-9">
                            <p className="text-muted mb-0">
                            {user?.dob ? moment(user.dob).format('MMMM Do YYYY') : ""}
                            </p>
                          </div>
                        </div>
                        <hr/>
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">Gender:</p>
                          </div>
                          <div className="col-sm-9">
                            <p className="text-muted mb-0">
                            {user?.gender}
                            </p>
                          </div>
                        </div>
                        <hr/>
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">Married Status</p>
                          </div>
                          <div className="col-sm-9">
                            <p className="text-muted mb-0">
                            {user?.marital_status}
                            </p>
                          </div>
                        </div>
                        <hr/>

                      </div>
                    </div>
                  </div>

                  <div>
                    <h2 className="heading_style">
                      <a href="#">ID card Details</a>
                    </h2>
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">Aadhaar Number:</p>
                          </div>
                          <div className="col-sm-9">
                            <p className="text-muted mb-0">
                            {user?.kyc?.aadhaar_number}
                            </p>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">Aadhar card (front):</p>
                          </div>
                          <div className="col-sm-2">
                            <div class="bg-white p-2 border me-1">
                                <img
                                  width={"50px"}
                                  src={user?.kyc?.aadhaar_fpic}
                                />
                              </div>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">Aadhar card (back):</p>
                          </div>
                          <div className="col-sm-2">
                             <div class="bg-white p-2 border me-1">
                                <img
                                  width={"50px"}
                                  src={user?.kyc?.aadhaar_bpic}
                                />
                              </div>
                           
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">PAN Number:</p>
                          </div>
                          <div className="col-sm-9">
                            <p className="text-muted mb-0">
                              {user?.kyc?.pan_number}
                              </p>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">Pan card Image</p>
                          </div>
                          <div className="col-sm-2">
                          <div class="bg-white p-2 border me-1">
                                <img
                                  width={"50px"}
                                  src={user?.kyc?.pan_pic}
                                />
                              </div>
                            
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">Employee Id card</p>
                          </div>
                          <div className="col-sm-2">
                          <div class="bg-white p-2 border me-1">
                                <img
                                  width={"50px"}
                                  src={user?.emp_id_card}
                                />
                              </div>
                            
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">Employee Insurance</p>
                          </div>
                          <div className="col-sm-2">
                          <div class="bg-white p-2 border me-1">
                                <img
                                  width={"50px"}
                                  src={user?.emp_insurance}
                                />
                              </div>
                            
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">Employee ESI</p>
                          </div>
                          <div className="col-sm-2">
                          <div class="bg-white p-2 border me-1">
                                <img
                                  width={"50px"}
                                  src={user?.emp_esi}
                                />
                              </div>
                            
                          </div>
                        </div>
                        <hr />
                      </div>
                    </div>
                  </div>

                  <div>
                    <h2 className="heading_style">
                      <a href="#">Others Information</a>
                    </h2>
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">Site:</p>
                          </div>
                          <div className="col-sm-9">
                            <p className="text-muted mb-0">
                            {user?.siteId?.site_name}
                              </p>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">Work Location:</p>
                          </div>
                          <div className="col-sm-9">
                            <p className="text-muted mb-0">
                              {user?.work_location}
                            </p>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">Work State</p>
                          </div>
                          <div className="col-sm-9">
                            <p className="text-muted mb-0">
                            {user?.work_state}
                              </p>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">Employee Prev UAN</p>
                          </div>
                          <div className="col-sm-9">
                            <p className="text-muted mb-0">
                              {user?.UAN_no}
                              </p>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">Employee Prev UAN upload</p>
                          </div>
                         
                            <div className="col-sm-2">
                            <div class="bg-white p-2 border me-1">
                                  <img
                                    width={"50px"}
                                    src= {user?.UAN_pic}
                                  />
                                </div>
                              
                            </div>
                        </div>
                        <hr/>
                       
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">Previous ESI image upload</p>
                          </div>
                          <div className="col-sm-2">
                            <div class="bg-white p-2 border me-1">
                                  <img
                                    width={"50px"}
                                    src= {user?.ESIC_pic}
                                  />
                                </div>
                              
                            </div>
                        </div>
                       
                        
                      </div>
                    </div>
                  </div>

                  <div>
                    <h2 className="heading_style">
                      <a href="#">Guardian Information</a>
                    </h2>
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">Father Name</p>
                          </div>
                          <div className="col-sm-9">
                            <p className="text-muted mb-0">{user?.father?.name}</p>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">DOB</p>
                          </div>
                          <div className="col-sm-9">
                            <p className="text-muted mb-0">
                            {user?.father?.dob}
                            </p>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">Aadhaar No</p>
                          </div>
                          <div className="col-sm-9">
                            <p className="text-muted mb-0">
                            {user?.father?.aadhaar_number}
                              </p>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">Mother Name</p>
                          </div>
                          <div className="col-sm-9">
                            <p className="text-muted mb-0">{user?.mother?.name}</p>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">DOB</p>
                          </div>
                          <div className="col-sm-9">
                            <p className="text-muted mb-0">
                            {user?.mother?.dob}
                            </p>
                          </div>
                        </div><hr/>
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">Aadhaar No</p>
                          </div>
                          <div className="col-sm-9">
                            <p className="text-muted mb-0">
                            {user?.mother?.aadhaar_number}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                  <div>
                    <h2 className="heading_style">
                      <a href="#">Account Details</a>
                    </h2>
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">Account Number</p>
                          </div>
                          <div className="col-sm-9">
                            <p className="text-muted mb-0">{user?.accountDetails?.accountNumber}</p>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">Bank PassBook</p>
                          </div>
                          <div className="col-sm-2">
                            <div class="bg-white p-2 border me-1">
                                  <img
                                    width={"50px"}
                                    src= {user?.accountDetails?.bankDoc}
                                  />
                                </div>
                              
                            </div>
                         
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">Bank Name</p>
                          </div>
                          <div className="col-sm-9">
                            <p className="text-muted mb-0">
                            {user?.accountDetails?.bankName}
                              </p>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">IFSC Code</p>
                          </div>
                          <div className="col-sm-9">
                            <p className="text-muted mb-0">{user?.accountDetails?.ifscCode}</p>
                          </div>
                        </div>
                        <hr />
                      </div>
                    </div>
                  </div>




                  <div>
                    <h2 className="heading_style">
                      <a href="#">Permanent Address</a>
                    </h2>
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">Address</p>
                          </div>
                          <div className="col-sm-9">
                            <p className="text-muted mb-0">{user?.permanentAddress?.address}</p>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">City</p>
                          </div>
                          <div className="col-sm-9">
                            <p className="text-muted mb-0">
                            {user?.permanentAddress?.city}
                            </p>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">pincode</p>
                          </div>
                          <div className="col-sm-9">
                            <p className="text-muted mb-0">
                            {user?.permanentAddress?.pincode}
                              </p>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">State</p>
                          </div>
                          <div className="col-sm-9">
                            <p className="text-muted mb-0">{user?.permanentAddress?.state}</p>
                          </div>
                        </div>
                        <hr />
                        
                      </div>
                    </div>
                  </div>



                  <div>
                    <h2 className="heading_style">
                      <a href="#">Present Address</a>
                    </h2>
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">Address</p>
                          </div>
                          <div className="col-sm-9">
                            <p className="text-muted mb-0">{user?.presentAddress?.address}</p>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">City</p>
                          </div>
                          <div className="col-sm-9">
                            <p className="text-muted mb-0">
                            {user?.presentAddress?.city}
                            </p>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">Pincode</p>
                          </div>
                          <div className="col-sm-9">
                            <p className="text-muted mb-0">
                            {user?.presentAddress?.pincode}
                              </p>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">State</p>
                          </div>
                          <div className="col-sm-9">
                            <p className="text-muted mb-0">{user?.presentAddress?.state}</p>
                          </div>
                        </div>
                        <hr />
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
