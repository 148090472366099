import React, { useEffect, useState } from "react";
import { Field, Formik } from "formik";
import { camelToTitle } from "../../helpers/data";
import ImageLightBox from "../../components/ImageLightBox/ImageLightBox";
import Network from "../../helpers/network";
import api from "../../config/api";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

export default function EmployeeIDUploadForm() {
  const [isEdit, setEdit] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [isError, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [message, setMessage] = useState(null);
  const params = useParams();
  const [userId, setUserId] = useState(null);
  const [user, setUser] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigation = useNavigate();

  const openLightBox = (images) => {
    console.log("101 images>>>", images);
    setOpen(true);
    setImages(images);
  };

  const closeLightBox = () => {
    setOpen(false);
    setImages([]);
  };
  const fileUpload = async (e, name, setFieldValue) => {
    try {
      const frmData = new FormData();
      let res;
      setLoading(true);

      frmData.append("file", e.target.files[0]);
      res = await Network.upload(frmData);
      setFieldValue(name, res?.data?.data?.path);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(true);
      setMessage("File upload faild! Please try after sometime.");
    }
  };
  const submitFormHandler = async (values, resetForm) => {
    try {
      const res = await Network.post(api.employees, {
        user_id: userId,
        ...values,
      });
      console.log(res);
      alert(res?.data?.message);
      navigation("/employees");
    } catch (err) {
      console.log(err);
    }
  };
  const getUser = async (id) => {
    try {
      if (id) {
        setUserId(searchParams.get("id"));
        const result = await Network.get(api.getUser, { id: id });
        console.log("user >>", result);
        setUser(result?.data?.data);
        // setMessage("User has been fatched successfully");
        // setSuccess(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getUser(searchParams.get("id"));
    // setUserId(searchParams.get("id"));
  }, [searchParams]);
  return (
    <div class="col-md-10 col-sm-12  bashboard_boxes_open no_mar mb-2">
      <div class="purchase_order_buttom_nav mt-0">
        <div class=" mt-0 mb-0">
          <nav class="navbar bg-body-tertiary">
            <div class="container-fluid">
              <form class="d-flex ">
                <h6 class="navbar-brand text-white">
                  Employee ID card/ Insurance/ ESI details upload
                </h6>

                <ul class="d-flex nav justify-content-end">
                  {/* <li class="nav-item">
                    <a href="#">
                      <button class="nav-link bg-primary text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined">
                          add_circle
                        </span>
                      </button>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="flow_through_details.html">
                      <button class="nav-link bg-success text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined">edit_note</span>
                      </button>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="flow_through_details.html">
                      <button class="nav-link bg-success text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined d-block">
                          {" "}
                          save{" "}
                        </span>
                      </button>
                    </a>
                  </li> */}
                  <li class="nav-item">
                    <a href="javascript:void(0);">
                      <button class="nav-link bg-danger text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined">cancel</span>{" "}
                      </button>
                    </a>
                  </li>
                </ul>
              </form>
            </div>
          </nav>
        </div>
      </div>
      <div class="container-fluid">
        {/* <h1 class="h3 mb-2 text-gray-800">Users</h1>*/}

        <div class="container-fluid pt-3">
          <Formik
            initialValues={{
              emp_id_card: user?.emp_id_card ? user?.emp_id_card : "",
              emp_insurance: user?.emp_insurance ? user?.emp_insurance : "",
              emp_esi: user?.emp_esi ? user?.emp_esi : "",
            }}
            onSubmit={(values, { resetForm }) =>
              submitFormHandler(values, resetForm)
            }
            enableReinitialize={true}
          >
            {({
              handleChange,
              handleSubmit,
              errors,
              touched,
              values,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <div class="row">
                  <div class="col-sm-12">
                    <h2 class="heading_style">
                      <a href="#">ID card Details</a>
                    </h2>
                    <div class="card mb-3">
                      <div class="row g-0 p-1">
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item border-radius">
                            <div class="row">
                              <div class="col-sm-12">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Upload ID card
                                  </label>
                                  <div class="col-sm-7 previewImage">
                                    {values.emp_id_card && (
                                      <a
                                        href="javascript:void(0);"
                                        onClick={() => {
                                          openLightBox([
                                            { url: `${values.emp_id_card}` },
                                          ]);
                                        }}
                                      >
                                        <img
                                          src={values.emp_id_card}
                                          className="pic"
                                          // crossOrigin="anonymous"
                                        />
                                      </a>
                                    )}
                                    <input
                                      type="file"
                                      class="form-control"
                                      id="customFile"
                                      accept="image/png,  image/jpeg"
                                      onChange={(e) => {
                                        fileUpload(
                                          e,
                                          "emp_id_card",
                                          setFieldValue
                                        );
                                      }}
                                    />
                                    <input
                                      type="hidden"
                                      class="form-control"
                                      id="customFile"
                                      name="emp_id_card"
                                      onChange={handleChange}
                                      value={values?.emp_id_card}
                                    />
                                    {errors.emp_id_card &&
                                    touched.emp_id_card ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.emp_id_card)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class="list-group-item border-radius">
                            <div class="row">
                              <div class="col-sm-12">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Upload Insurance Doc
                                  </label>
                                  <div class="col-sm-7 previewImage">
                                    {values.emp_insurance && (
                                      <a
                                        href="javascript:void(0);"
                                        onClick={() => {
                                          openLightBox([
                                            { url: `${values.emp_insurance}` },
                                          ]);
                                        }}
                                      >
                                        <img
                                          className="pic"
                                          src={values.emp_insurance}
                                          // crossOrigin="anonymous"
                                        />
                                      </a>
                                    )}
                                    <input
                                      type="file"
                                      class="form-control"
                                      id="customFile"
                                      accept="image/png,  image/jpeg"
                                      onChange={(e) => {
                                        fileUpload(
                                          e,
                                          "emp_insurance",
                                          setFieldValue
                                        );
                                      }}
                                    />
                                    <input
                                      type="hidden"
                                      class="form-control"
                                      id="customFile"
                                      name="emp_insurance"
                                      onChange={handleChange}
                                      value={values?.emp_insurance}
                                    />
                                    {errors.emp_insurance &&
                                    touched.emp_insurance ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.emp_insurance)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class="list-group-item border-radius">
                            <div class="row">
                              <div class="col-sm-12">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Upload ESIC Doc
                                  </label>
                                  <div class="col-sm-7 previewImage">
                                    {values.emp_esi && (
                                      <a
                                        href="javascript:void(0);"
                                        onClick={() => {
                                          openLightBox([
                                            { url: `${values.emp_esi}` },
                                          ]);
                                        }}
                                      >
                                        <img
                                          src={values.emp_esi}
                                          className="pic"
                                          // crossOrigin="anonymous"
                                        />
                                      </a>
                                    )}
                                    <input
                                      type="file"
                                      class="form-control"
                                      id="customFile"
                                      accept="image/png,  image/jpeg"
                                      onChange={(e) => {
                                        fileUpload(e, "emp_esi", setFieldValue);
                                      }}
                                    />
                                    <input
                                      type="hidden"
                                      class="form-control"
                                      id="customFile"
                                      name="emp_esi"
                                      onChange={handleChange}
                                      value={values?.emp_esi}
                                    />
                                    {errors.emp_esi && touched.emp_esi ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.emp_esi)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="card-body text-center">
                      <div>
                        {/* <button type="button" class="btn btn-success">
                          <span class="material-symbols-outlined float-start me-1">
                            add_circle
                          </span>{" "}
                          Add More Child
                        </button> */}

                        <button type="submit" class="btn btn-success">
                          {" "}
                          Submit{" "}
                          <span class="material-symbols-outlined float-end ms-1">
                            Save
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
        {isOpen && (
          <ImageLightBox
            images={images.map((i) => i.url)}
            isOpen={isOpen}
            closeLightBox={closeLightBox}
          />
        )}
      </div>
    </div>
  );
}
