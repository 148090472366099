export default {
  login: "/user/login",
  forgotPassword: "/user/forgot-password",
  otpVarify: "/user/otp-verify",
  resetPassword: "/user/reset-password",
  getUsers: "/user",
  getUser: "/user/single-user",
  createUser: "/user/add",
  updateUser: "/user",
  deleteUser: "/user",
  roles: "/roles",
  sites: "/sites",
  getSiteById: "/sites/single-site",
  dashboard: "/dashboard",
  client: "/clients",
  getClientById: "/clients/single-client",
  vendors: "/vendors",
  getVendorById: "/vendors/single-vendor",
  attendence: "/attendence",
  attendenceCount: "/attendence/monthWiseAttendence",
  attendenceExport: "/attendence/attendenceexport",
  attendenceMonthWise: "/attendence/showMonthWise",
  invoices: "/invoices",
  employees: "/user/employees",
  salaryList: "/salary",
  getSalaryById: "salary/singleDetails",
  addSalary: "salary/addSalary",
  paySalary: "salary/pay",
  updateSalary: "salary/updateDetails",
  approveSalary: "salary/approved",
  employees: "/employees",
  pettycash: "/pettycash",
  expenseCategory: "/util/expense-category",
  paymentHistory: "/salary/history",
};
