import { Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { camelToTitle } from "../../helpers/data";
import moment from "moment";
import MultiSelectOption from "../../components/MultiSelectOption/MultiSelectOption";
import Network from "../../helpers/network";
import api from "../../config/api";
import AppContext from "../../appContext";
const minDate = moment()
  .subtract(1, "month")
  .startOf("month")
  .format("YYYY-MM-DD");
const maxDate = moment().format("YYYY-MM-DD");

export default function AttendanceForm() {
  const [isError, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [message, setMessage] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const goToSites = () => {
    navigate("/attendances");
  };
  const getEmployees = async () => {
    try {
      const res = await Network.get(api.employees, {
        per_page: 20,
      });
      console.log("dashboard", res);
      setEmployees(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getEmployees();
  }, []);
  useEffect(() => {
    setSelectedDate(searchParams.get("m"));
    setSelectedEmployeeId(searchParams.get("id"));
  }, [searchParams]);
  const submit = async (values, resetForm) => {
    try {
      const selectedEmp = employees?.filter(
        (e) => e._id === selectedEmployeeId
      );
      if (selectedEmp.length > 0) {
        let tempData = {
          siteId: selectedEmp[0]?.siteId?._id,
          attendDate: values?.date,
          attendenceStatus: true,
          userId: selectedEmployeeId,
        };
        // console.log(tempData);
        // return;
        const attendance = await Network.post(api.attendence, {
          users: [tempData],
        });
        console.log(attendance);
        appContext.showToast("success", attendance?.data?.message);
        resetForm();
      }
    } catch (err) {
      appContext.showToast("error", "Please try after some time");
    }
  };
  return (
    <div class="col-md-10 col-sm-12  bashboard_boxes_open no_mar mb-2">
      <div class="purchase_order_buttom_nav mt-0">
        <div class=" mt-0 mb-0">
          <nav class="navbar bg-body-tertiary">
            <div class="container-fluid">
              <form class="d-flex ">
                <h6 class="navbar-brand text-white">Attendance</h6>
              </form>
              <ul class="d-flex nav justify-content-end">
                {/* <li class="nav-item">
                <a href="#">
                  <button class="nav-link bg-primary text-white rounded-5 me-1 px-2 border-0">
                    <span class="material-symbols-outlined">
                      add_circle
                    </span>
                  </button>
                </a>
              </li>
              <li class="nav-item">
                <a href="flow_through_details.html">
                  <button class="nav-link bg-success text-white rounded-5 me-1 px-2 border-0">
                    <span class="material-symbols-outlined">edit_note</span>
                  </button>
                </a>
              </li>
              <li class="nav-item">
                <a href="flow_through_details.html">
                  <button class="nav-link bg-success text-white rounded-5 me-1 px-2 border-0">
                    <span class="material-symbols-outlined d-block">
                      {" "}
                      save{" "}
                    </span>
                  </button>
                </a>
              </li> */}
                <li class="nav-item">
                  <a href="javascript:void(0);" onClick={goToSites}>
                    <button class="nav-link bg-danger text-white rounded-5 me-1 px-2 border-0">
                      <span class="material-symbols-outlined">cancel</span>{" "}
                    </button>
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <div class="container-fluid pt-3">
        <div class="row">
          <Formik
            initialValues={{
              user: selectedEmployeeId,
              date: selectedDate,
            }}
            onSubmit={(values, { resetForm }) => submit(values, resetForm)}
            // validationSchema={isEdit ? EditSiteValidation : AddSiteValidation}
            enableReinitialize={true}
          >
            {({
              handleChange,
              handleSubmit,
              errors,
              touched,
              values,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                {isError && <div class="alert alert-danger">{message}</div>}
                {isSuccess && <div class="alert alert-success">{message}</div>}
                <div class="col-sm-12">
                  <h2 class="heading_style">
                    <a href="#">Attendance</a>
                  </h2>
                  <div class="card mb-3">
                    <div class="row g-0 p-1">
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item border-radius">
                          <div class="row">
                            <div class="col-sm-4">
                              <div class="row form-group mb-0">
                                <label
                                  for="inputPassword"
                                  class="col-sm-5 col-form-label"
                                >
                                  Employee
                                </label>
                                <div class="col-sm-7">
                                  <select
                                    class="form-control form-control-sm"
                                    onChange={(e) =>
                                      setSelectedEmployeeId(e.target.value)
                                    }
                                    value={values?.user}
                                  >
                                    <option>Select Employee</option>
                                    {employees &&
                                      employees.length > 0 &&
                                      employees.map((e) => (
                                        <option value={e?._id}>
                                          {e.firstName + " " + e.lastName}
                                        </option>
                                      ))}
                                  </select>
                                  {errors.site_name && touched.site_name ? (
                                    <div class="text-danger">
                                      {camelToTitle(errors.site_name)}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-4">
                              <div class="row form-group mb-0">
                                <label
                                  for="inputPassword"
                                  class="col-sm-5 col-form-label"
                                >
                                  Date
                                </label>
                                <div class="col-sm-7">
                                  <input
                                    type="date"
                                    class="form-control form-control-sm"
                                    name="date"
                                    min={minDate}
                                    max={maxDate}
                                    onChange={handleChange}
                                    value={values.date}
                                  />
                                  {errors.site_name && touched.site_name ? (
                                    <div class="text-danger">
                                      {camelToTitle(errors.site_name)}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="card-body text-center">
                    <div>
                      {/* <button type="button" class="btn btn-success">
                <span class="material-symbols-outlined float-start me-1">
                  add_circle
                </span>{" "}
                Add More Child
              </button> */}
                      <button type="submit" class="btn btn-success">
                        {" "}
                        Submit{" "}
                        <span class="material-symbols-outlined float-end ms-1">
                          send
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
