import React, { useEffect, useState } from "react";
import { Formik, ErrorMessage } from "formik";
import OtpValidation from "./Otpvarify.validation";
import { camelToTitle } from "./../../helpers/data";
import { login } from "./../../redux/reducers/authReducer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Network from "./../../helpers/network";
import api from "./../../config/api";

export default function Otpvarify(props) {
    
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isError, setError] = useState(false);
  const [message, setMessage] = useState(null);
  
  const submit = async (values) => {
    setError(false);
    console.log(values);
    setMessage(null);
    try {
      const res = await Network.post(api.otpVarify, values);
       console.log(res, props);
       localStorage.setItem('token',res.data.data.token) 
      navigate("/reset-password", { state: { email: values.email } });
    } catch (error) {
      console.log("error>>>", error?.response);
      setError(true);
      setMessage(error?.response?.data?.message);
    }
  };

  const goLogin = () => {
    navigate("/");
  };
  return (
    <>
      <section
        class="vh-100 gradient-custom login_bg"
        style={{ backgroundImage: `url("/assets/images/login_bg.jpg")` }}
      >
        <div class=" container py-4 h-100">
          <div class="row d-flex justify-content-center align-items-center h-100">
            <div class="col-12 col-md-8 col-lg-6 col-xl-5">
              <div
                class="card bg_opacity text-white"
                style={{ borderRadius: "1rem" }}
              >
                <Formik
                    initialValues={{
                      email:localStorage.getItem('email'),
                      otp:"",
                      mode:"varify"
                    }}
                    validationSchema={OtpValidation}
                    onSubmit={(values) => {
                      submit(values);
                    }}
                  >
                
                  {({
                    handleSubmit,
                    handleChange,
                    errors,
                    touched,
                    values,
                  }) => (
                    <form class="user" onSubmit={handleSubmit}>
                      <div class="card-body p-3 text-center">
                        <div class="mb-md-5 mt-md-4 pb-5">
                          <div class="login_logo bg-white p-2 text-center mb-4 rounded-4">
                            <img
                              class="img-responsive"
                              src="/assets/images/logo_login.png"
                            />
                          </div>
                          <h2 class="fw-bold mb-2 text-uppercase">Otp Varify</h2>
                          <p class="text-white mb-2">
                            Please enter your Otp !
                          </p>
                          {isError && message != "" && (
                            <div class="alert alert-danger">{message}</div>
                          )}
                          <div class="form-outline form-white p-4">
                          <input
                            type="text"
                            class="form-control form-control-user"
                            id="exampleInputEmail"
                            aria-describedby="emailHelp"
                            placeholder="Enter Otp"
                            onChange={handleChange}
                            name="otp"
                            value={values.otp}
                          />
                          
                          {errors.otp && touched.otp ? (
                            <div class="text-danger">
                              {camelToTitle(errors.otp)}
                            </div>
                          ) : null}
                          </div>

                          <button
                            class="btn btn-lg px-5 bg-success text-white"
                            type="submit"
                          >
                            submit
                          </button><hr/>
                          <p class="small mb-1 ">
                          <a
                            class="small"
                            href="javascript:void(0);"
                            onClick={goLogin}
                          >
                            Login
                          </a>
                        </p>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
}
