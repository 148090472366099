import React, { Component, PropTypes, useEffect, useState } from "react";
// import RichTextEditor from "react-rte";
import { Editor } from "react-draft-wysiwyg";
import { ContentState, convertFromHTML, EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import _ from "lodash";
import { Field } from "formik";
import htmlToDraft from "html-to-draftjs";

// function Editor(props) {
//   const [editorValue, setEditorValue] = useState(
//     RichTextEditor.createEmptyValue()
//   );
//   const setInitialValue = () => {
//     console.log(
//       "value dsdfasd",
//       props,
//       RichTextEditor.createValueFromString(props.value, "html")
//     );
//     setEditorValue(RichTextEditor.createValueFromString(props.value, "html"));
//   };
//   useEffect(() => {
//     // setEditorValue();
//     setInitialValue();
//     // console.log("value1>>", value, typeof value, editorValue);
//   }, []);
//   const handleChange = (value) => {
//     setEditorValue(value);
//     // setValue(value.toString("markdown"));
//     props.onChange(value.toString("html"));
//   };

//   return (
//     // <RichTextEditor
//     //   value={editorValue}
//     //   onChange={handleChange}
//     //   // required
//     //   // id="body-text"
//     //   // name="bodyText"
//     //   // type="string"
//     //   multiline
//     //   // variant="filled"
//     //   style={{ minHeight: 410 }}
//     // />
//     <Field as="textarea" />
//   );
// }
// function InputEditor({ value = undefined, onChange }) {
//   console.log("test>>", value, _.isEmpty(value));
//   const [editorState, setEditorState] = useState(() => {
//     // const blocksFromHTML = convertFromHTML(
//     //   value ? `Test only`.toString() : "VALUE"
//     // );
//     const blocksFromHTML = htmlToDraft(
//       value ? `Test only`.toString() : "VALUE"
//     );
//     const contentState = ContentState.createFromBlockArray(
//       blocksFromHTML.contentBlocks,
//       blocksFromHTML.entityMap
//     );

//     return EditorState.createWithContent(contentState);
//   });
//   useEffect(() => {
//     console.log(editorState);
//   }, [editorState]);
//   return (
//     <div>
//       <div
//         // class="form-control-user"
//         style={{
//           border: "1px solid #d1d3e2",
//           borderRadius: 10,
//           padding: "5px",
//           minHeight: "400px",
//         }}
//       >
//         <Editor
//           editorState={editorState}
//           onEditorStateChange={(e) => {
//             console.log("state>>", e);
//             setEditorState(e);
//           }}
//         />
//       </div>
//     </div>
//   );
// }

class InputEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML("<p>My initial content.</p>")
        )
      ),
    };
  }
  componentDidMount() {
    this.setState = {
      editorState: EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(this.props.value))
      ),
    };
    console.log("props>>", this.props, this.state);
  }

  onChange(e) {
    this.setState({
      editorState: e,
    });
  }
  render() {
    return (
      <div
        // class="form-control-user"
        style={{
          border: "1px solid #d1d3e2",
          borderRadius: 10,
          padding: "5px",
          minHeight: "400px",
        }}
      >
        <Editor
          editorState={this.state.editorState}
          onEditorStateChange={(e) => {
            console.log("state>>", e);
            this.props.onChange(e);
          }}
        />
      </div>
    );
  }
}

export default InputEditor;
