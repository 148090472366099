import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../config/api";
import Network from "../../helpers/network";
import "./Home.scss";

export default function Home() {
  const [result, setResult] = useState([]);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const getDashboard = async () => {
    try {
      const res = await Network.get(api.dashboard);
      console.log("dashboard", res);
      setResult(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const goToPage = (page) => {
    navigate(page);
  };
  useEffect(() => {
    getDashboard();
  }, []);
  return (
    <>
      <div class="col-md-10 col-sm-12  bashboard_boxes_open no_mar">
        <div class="bashboard_boxes ">
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="dashboard_box">
                <div class="prole_pic_2"></div>
                <a
                  href="javascript:void(0);"
                  onClick={() => goToPage("/users")}
                >
                  <p>Employee</p>
                  {result?.totalEmployee}
                </a>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="dashboard_box border-color_blue">
                <div class="prole_pic_2"></div>
                <a
                  href="javascript:void(0);"
                  onClick={() => goToPage("/clients")}
                >
                  <p>Clients</p>
                  {result?.totalClient}
                </a>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="dashboard_box border-color_green">
                <div class="prole_pic_2"></div>
                <a
                  href="javascript:void(0);"
                  onClick={() => goToPage("/sites")}
                >
                  <p>Sites</p>
                  {result?.totalSite}
                </a>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="dashboard_box">
                <div class="prole_pic_2"></div>
                <a
                  href="javascript:void(0);"
                  onClick={() => goToPage("/vendors")}
                >
                  <p>Vendors</p>
                  {result?.totalVendor}
                </a>
              </div>
            </div>
            {/* <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="dashboard_box border-color_grey">
                <div class="prole_pic_2"></div>
                <div>
                  <p>Make a Sale</p>
                  share details to publish app
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="dashboard_box">
                <div class="prole_pic_2"></div>
                <div>
                  <p>Enter Purchases</p>
                  launch your webiste now
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="dashboard_box">
                <div class="prole_pic_2"></div>
                <div>
                  <p>Enable Native POS</p>
                  Configure your store App
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="dashboard_box">
                <div class="prole_pic_2"></div>
                <div>
                  <p>Enable Door Delivery </p>
                  Configure your delivery App
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="dashboard_box border-color_black">
                <div class="prole_pic_2"></div>
                <div>
                  <p>Set Business Rules</p>
                  share details to publish app
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
