import React, { useContext, useEffect, useState } from "react";
import config from "../../config/config";
import Network from "../../helpers/network";
import api from "../../config/api";
import { timeFormated } from "../../helpers/time";
import { Navigate, useNavigate, createSearchParams } from "react-router-dom";
import Pagination from "../../components/Pagination/Pagination.js";
import { employeeStatusOpetions, userStatusOpetions } from "../../helpers/data";
import Dropdown from "react-bootstrap/Dropdown";
import AppContext from "../../appContext.js";
import "./Employee.scss";
export default function Employee(props) {
  const [result, setResult] = useState([]);
  const [page, setPage] = useState(1);
  const [totalUser, setTotalUser] = useState(0);
  const [isError, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [message, setMessage] = useState(null);
  const [search, setSearch] = useState(null);
  const [status, setStatus] = useState("approved");
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const getUserList = async () => {
    try {
      let res;
      if (search && search.length > 3) {
        res = await Network.get(api.employees, {
          per_page: config.per_page,
          page: page,
          q: search,
          status: status,
        });
      } else {
        res = await Network.get(api.employees, {
          per_page: config.per_page,
          page: page,
          status: status,
        });
      }

      console.log(res);
      setResult(res?.data?.data);
      // setTotalUser(res?.data?.data?.totalUsers);
    } catch (err) {
      appContext.showToast("error", err?.response?.data?.message);
      navigate("/");
    }
  };
  const goToAddUser = () => {
    console.log("navigate");
    navigate("/user-add");
  };
  const goToDetailsUser = (id) => {
    navigate({
      pathname: "/user-details",
      search: `?${createSearchParams({
        id: id,
      })}`,
    });
  };
  const clearStatus = () => {
    setError(false);
    setSuccess(false);
    setMessage(null);
  };

  const gotoEditUser = (id) => {
    navigate({
      pathname: "/user-edit",
      search: `?${createSearchParams({
        id: id,
      })}`,
    });
  };

  const deleteUser = async (id) => {
    try {
      clearStatus();
      if (window.confirm("Are you sure?")) {
        const result = await Network.delete(api.deleteUser, { id });
        console.log(result);
        setMessage("User has been updated successfully");
        setSuccess(true);
        getUserList();
      }
    } catch (error) {
      setMessage(error?.response?.message);
      setSuccess(false);
      setError(true);
      console.log(error);
    }
  };
  const searchHandler = () => {
    getUserList();
  };

  useEffect(() => {
    getUserList();
  }, [page]);

  useEffect(() => {
    getUserList();
  }, []);
  useEffect(() => {
    getUserList();
  }, [status, search]);
  return (
    <>
      <div class="col-md-10 col-sm-12  bashboard_boxes_open no_mar mb-2">
        <div class="purchase_order_buttom_nav mt-0">
          <div class=" mt-0 mb-0">
            <nav class="navbar bg-body-tertiary">
              <div class="container-fluid">
                <form class="d-flex ">
                  <h6 class="navbar-brand text-white">Users</h6>
                </form>
                <ul class="d-flex nav justify-content-end">
                  <li class="nav-item">
                    <a href="javascript:void(0);" onClick={goToAddUser}>
                      <button class="nav-link bg-primary text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined">
                          add_circle
                        </span>
                      </button>
                    </a>
                  </li>
                  {/* <li class="nav-item">
                    <a href="flow_through_details.html">
                      <button class="nav-link bg-success text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined">edit_note</span>
                      </button>
                    </a>
                  </li> */}
                  {/* <li class="nav-item">
                    <a href="#">
                      <button class="nav-link bg-success text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined d-block">
                          {" "}
                          save{" "}
                        </span>
                      </button>
                    </a>
                  </li> */}
                  {/* <li class="nav-item">
                    <a href="#">
                      <button class="nav-link bg-danger text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined">cancel</span>{" "}
                      </button>
                    </a>
                  </li> */}
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div class="container-fluid pt-3">
          <div class="row">
            <div class="col-sm-12">
              <div class="card gate_entry_details2">
                <div class="table-content  tabile-responsive card-body">
                  <div class="row mb-2 d-flex justify-content-between">
                    <div class="col-sm-5">
                      <div class="row form-group mb-0">
                        <label
                          for="inputPassword"
                          class="col-sm-3 col-form-label"
                        >
                          Status
                        </label>
                        <div class="col-sm-5">
                          <select
                            className="form-control"
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                          >
                            <option>Select</option>
                            {employeeStatusOpetions &&
                              employeeStatusOpetions().map((o) => (
                                <option value={o.key}>{o.value}</option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-5">
                      <div class="row form-group mb-0">
                        <label
                          for="inputPassword"
                          class="col-sm-3 col-form-label"
                        >
                          Search
                        </label>
                        <div class="col-sm-6">
                          <input
                            className="form-control"
                            type="text"
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <table
                    id="example"
                    class="table   dt-responsive "
                    width="100%"
                  >
                    <thead>
                      <tr>
                        <th class="table-primary" width="15%">
                          Name
                        </th>
                        <th class="table-primary">Emp Code</th>
                        <th class="table-primary">Role</th>
                        <th class="table-primary">Contact number</th>
                        <th class="table-primary" width="15%">
                          Address
                        </th>
                        <th class="table-primary">Sites</th>
                        <th class="table-primary  text-center">Status</th>
                        <th class="table-primary">Payment</th>
                        <th class="table-primary">Office Work</th>
                        <th class="table-primary">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {result &&
                        result.length > 0 &&
                        result.map((r) => (
                          <tr>
                            <td>{r?.firstName + " " + r?.lastName}</td>
                            <td>{r?.empId}</td>
                            <td>{r?.role}</td>
                            <td>{r?.phone}</td>
                            <td>{r?.presentAddress?.address}</td>
                            <td>{r?.siteId?.site_name}</td>
                            <td>{r?.status}</td>
                            <td>
                              {r?.status === "approved" && (
                                <a
                                  href={`/employee-salary-pay?id=${r?._id}`}
                                  className="btn btn-success"
                                >
                                  PAY
                                </a>
                              )}
                            </td>
                            <td>
                              {r?.status === "approved" && (
                                <a
                                  href={`/employee-card?empId=${r?._id}`}
                                  className="btn btn-success"
                                  target="_blank"
                                >
                                  Card
                                </a>
                              )}
                            </td>
                            <td>
                              {r?.status === "approved" && (
                                <>
                                  {r?.emp_id_card &&
                                  r?.emp_insurance &&
                                  r?.emp_esi ? (
                                    <div class="alert alert-success">
                                      All office Doc uploaded
                                    </div>
                                  ) : (
                                    <button
                                      type="button"
                                      class="btn text-danger lh-1"
                                      data-toggle="tooltip"
                                      data-placement="left"
                                      title={`${
                                        !r?.emp_id_card &&
                                        "ID card not uploaded "
                                      } ${
                                        !r?.emp_insurance &&
                                        "Insurance not uploaded "
                                      }${
                                        !r?.emp_esi && "ESI card not uploaded "
                                      }`}
                                    >
                                      All Doc not uploaded
                                    </button>
                                  )}
                                </>
                              )}
                            </td>
                            <td class="text-center">
                              {" "}
                              {/* <button
                                class=" bg-primary text-white  border-0 "
                                onClick={() => goToDetailsUser(r?._id)}
                              >
                                <span class="material-symbols-outlined">
                                  visibility
                                </span>
                              </button> */}
                              <Dropdown>
                                {/* <a
                          class="nav-link dropdown-toggle mb-0"
                          href="#"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        > */}
                                <Dropdown.Toggle
                                  //   id="dropdown-basic"
                                  className="bg-info text-white rounded-0 border-0 p-0 mb-1"
                                >
                                  <span class="material-symbols-outlined">
                                    manage_accounts
                                  </span>
                                  {/* </a> */}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    href={`/role-edit?id=${r?._id}`}
                                  >
                                    Role Modification
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    href={`/employee-details?id=${r?._id}`}
                                  >
                                    Details
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    href={`/employee-salary-details?id=${r?._id}`}
                                  >
                                    Salary details
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    href={`/employees-other-details-upload?id=${r?._id}`}
                                  >
                                    Other details
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                                {/* <ul class="dropdown-menu-1">
                          <li>
                            <a class="dropdown-item" href="#">
                              User Name
                            </a>
                          </li>
                          <li>
                            <a class="dropdown-item" href="#">
                              Logout
                            </a>
                          </li>
                        </ul> */}
                              </Dropdown>
                              {status !== "deleted" && status !== "blocked" && (
                                <button
                                  class=" bg-success text-white  border-0 mb-1"
                                  onClick={() => gotoEditUser(r?._id)}
                                >
                                  <span class="material-symbols-outlined">
                                    edit
                                  </span>
                                </button>
                              )}
                              {status !== "deleted" && (
                                <button
                                  class=" bg-danger text-white  border-0 "
                                  onClick={() => {
                                    deleteUser(r?._id);
                                  }}
                                >
                                  <span class="material-symbols-outlined">
                                    delete
                                  </span>
                                </button>
                              )}
                            </td>
                          </tr>
                        ))}
                      {result && result.length === 0 && (
                        <tr>
                          <td colSpan={6} class="text-center">
                            No record found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {totalUser > 0 && (
                    <Pagination
                      currentPage={page}
                      totalCount={totalUser}
                      onPageChange={(e) => {
                        console.log(e);
                        // setPage((p) => p + 1);
                        setPage(e);
                      }}
                      pageSize={10}
                    />
                  )}
                </div>
              </div>
              {/* <div class="card-body mt-3">
                <nav aria-label="Page navigation example">
                  <ul class="pagination justify-content-center">
                    <li class="page-item disabled ">
                      <a class="page-link bg-success text-white">Previous</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">
                        1
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">
                        2
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">
                        3
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link bg-success text-white" href="#">
                        Next
                      </a>
                    </li>
                  </ul>
                </nav>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
