import React, { useState } from "react";
import "./Login.css";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import LoginValidation from "./Login.validation";
import { camelToTitle } from "./../../helpers/data";
import { login } from "./../../redux/reducers/authReducer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Login(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isError, setError] = useState(false);
  const [message, setMessage] = useState(null);
  const submit = async (values) => {
    setError(false);
    setMessage(null);
    try {
      const res = await dispatch(login(values.email, values.password));
      console.log(res, props);
      navigate("/home");
    } catch (error) {
      console.log("error>>>", error?.response);
      setError(true);
      setMessage(error?.response?.data?.message);
    }
  };

  const goToForgotPassword = () => {
    navigate("/forgot-password");
  };
  return (
    <>
      <section
        class="vh-100 gradient-custom login_bg"
        style={{ backgroundImage: `url("/assets/images/login_bg.jpg")` }}
      >
        <div class=" container py-4 h-100">
          <div class="row d-flex justify-content-center align-items-center h-100">
            <div class="col-12 col-md-8 col-lg-6 col-xl-5">
              <div
                class="card bg_opacity text-white"
                style={{ borderRadius: "1rem" }}
              >
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validationSchema={LoginValidation}
                  onSubmit={(values) => {
                    submit(values);
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    errors,
                    touched,
                    values,
                  }) => (
                    <form class="user" onSubmit={handleSubmit}>
                      <div class="card-body p-3 text-center">
                        <div class="mb-md-5 mt-md-4 pb-5">
                          <div class="login_logo bg-white p-2 text-center mb-4 rounded-4">
                            <img
                              class="img-responsive"
                              src="/assets/images/logo_login.png"
                            />
                          </div>
                          <h2 class="fw-bold mb-2 text-uppercase">Login</h2>
                          <p class="text-white mb-2">
                            Please enter your login and password!
                          </p>
                          {isError && message != "" && (
                            <div class="alert alert-danger">{message}</div>
                          )}
                          <div class="form-outline form-white p-4">
                            <input
                              type="text"
                              id="typeEmailX"
                              class="form-control form-control-lg"
                              placeholder="Your Email Id"
                              onChange={handleChange}
                              name="email"
                              value={values.email}
                            />
                            {errors.email && touched.email ? (
                              <div class="text-danger">
                                {camelToTitle(errors.email)}
                              </div>
                            ) : null}
                          </div>

                          <div class="form-outline form-white p-4 pt-0">
                            <input
                              type="password"
                              id="typePasswordX"
                              class="form-control form-control-lg"
                              placeholder="Your Password"
                              onChange={handleChange}
                              name="password"
                              value={values.password}
                            />
                            {errors.password && touched.password ? (
                              <div class="text-danger">
                                {camelToTitle(errors.password)}
                              </div>
                            ) : null}
                          </div>

                          <p class="small mb-1 ">
                            <a class="text-white" onClick={goToForgotPassword}>
                              Forgot password?
                            </a>
                          </p>

                          <button
                            class="btn btn-lg px-5 bg-success text-white"
                            type="submit"
                          >
                            Login
                          </button>
                        </div>

                        {/* <div>
                    <p class="mb-0 text-white">
                      Don't have an account?{" "}
                      <a href="#!" class="text-white fw-bold">
                        Sign Up
                      </a>
                    </p>
                  </div> */}
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
