import React, { useState, useEffect } from "react";
import { Field, Formik } from "formik";
import { AddClientValidation, EditClientValidation } from "./Client.validation";
import Network from "../../helpers/network";
import api from "../../config/api";
import { camelToTitle, generatePassword } from "../../helpers/data";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./Client.scss";
import MultiSelectOption from "../../components/MultiSelectOption/MultiSelectOption";

export default function ClientForm() {
  const [isEdit, setEdit] = useState(false);
  const [isShowPassword, setShowPassword] = useState(false);
  const [autoPassword, setAutoPassword] = useState(null);
  const [isError, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [message, setMessage] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [siteId, setSiteId] = useState(null);
  const [site, setSite] = useState(null);
  const [sites, setSites] = useState([]);
  const navigate = useNavigate();

  const submit = async (values, resetForm) => {
    try {
      clearStatus();
      console.log("value>>", values);
      if (isEdit) {
        const result = await Network.put(api.client, {
          ...values,
          id: siteId,
        });
        console.log(result);
        setMessage("Client has been updated successfully");
        setSuccess(true);
      } else {
        const result = await Network.post(api.client, values);
        console.log(result);
        setMessage("Client has been created successfully");
        setSuccess(true);
        resetForm();
      }
    } catch (error) {
      console.log("error", error);
      setError(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
    }
  };
  const goToClients = () => {
    navigate("/clients");
  };
  const clearStatus = () => {
    setError(false);
    setSuccess(false);
    setMessage(null);
  };
  const generatePasswordHandler = () => {
    const pass = generatePassword();
    setAutoPassword(pass);
  };
  const getSites = async () => {
    try {
      const result = await Network.get(api.sites);
      setSites(result?.data?.data);
    } catch (err) {}
  };
  const getClient = async (id) => {
    try {
      if (id && !isEdit) {
        setSiteId(searchParams.get("id"));
        setEdit(true);
        clearStatus();
        const result = await Network.get(api.getClientById, { id: id });
        console.log(result);
        setSite(result?.data?.data);
        // setMessage("User has been fatched successfully");
        // setSuccess(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    // setUserId(searchParams.get("id"));
    getClient(searchParams.get("id"));
  }, [searchParams]);
  useEffect(() => {
    getSites();
  }, []);
  return (
    <>
      <div class="col-md-10 col-sm-12  bashboard_boxes_open no_mar mb-2">
        <div class="purchase_order_buttom_nav mt-0">
          <div class=" mt-0 mb-0">
            <nav class="navbar bg-body-tertiary">
              <div class="container-fluid">
                <form class="d-flex ">
                  <h6 class="navbar-brand text-white">Clients </h6>
                </form>
                <ul class="d-flex nav justify-content-end">
                  {/* <li class="nav-item">
                    <a href="#">
                      <button class="nav-link bg-primary text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined">
                          add_circle
                        </span>
                      </button>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="flow_through_details.html">
                      <button class="nav-link bg-success text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined">edit_note</span>
                      </button>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="flow_through_details.html">
                      <button class="nav-link bg-success text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined d-block">
                          {" "}
                          save{" "}
                        </span>
                      </button>
                    </a>
                  </li> */}
                  <li class="nav-item">
                    <a href="javascript:void(0);" onClick={goToClients}>
                      <button class="nav-link bg-danger text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined">cancel</span>{" "}
                      </button>
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div class="container-fluid pt-3">
          <div class="row">
            <Formik
              initialValues={{
                client_name: isEdit ? site?.client_name : "",
                client_contact_no: isEdit ? site?.client_contact_no : "",
                client_city: isEdit ? site?.client_city : "",
                client_address: isEdit ? site?.client_address : "",
                sites: isEdit ? site?.sites : [],
              }}
              onSubmit={(values, { resetForm }) => submit(values, resetForm)}
              validationSchema={
                isEdit ? EditClientValidation : AddClientValidation
              }
              enableReinitialize={true}
            >
              {({
                handleChange,
                handleSubmit,
                errors,
                touched,
                values,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  {isError && <div class="alert alert-danger">{message}</div>}
                  {isSuccess && (
                    <div class="alert alert-success">{message}</div>
                  )}
                  <div class="col-sm-12">
                    <h2 class="heading_style">
                      <a href="#">Client Details</a>
                    </h2>
                    <div class="card mb-3">
                      <div class="row g-0 p-1">
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item border-radius">
                            <div class="row">
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Client Name
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="client_name"
                                      onChange={handleChange}
                                      value={values.client_name}
                                    />
                                    {errors.client_name &&
                                    touched.client_name ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.client_name)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Contact Number
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="client_contact_no"
                                      onChange={handleChange}
                                      value={values.client_contact_no}
                                    />
                                    {errors.client_contact_no &&
                                    touched.client_contact_no ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.client_contact_no)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Address
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="client_address"
                                      onChange={handleChange}
                                      value={values.client_address}
                                    />
                                    {errors.client_address &&
                                    touched.client_address ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.client_address)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    City
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="client_city"
                                      onChange={handleChange}
                                      value={values.client_city}
                                    />
                                    {errors.client_city &&
                                    touched.client_city ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.client_city)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              {/* <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    State
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="site_city"
                                      onChange={handleChange}
                                      value={values.site_city}
                                    />
                                    {errors.site_city && touched.site_city ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.site_city)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div> */}
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Sites
                                  </label>
                                  <div class="col-sm-7">
                                    <MultiSelectOption
                                      className="form-control form-control-sm"
                                      options={sites?.map((s) => ({
                                        name: s?.site_name,
                                        value: s?._id,
                                      }))}
                                      onSelect={(data) =>
                                        setFieldValue(
                                          "sites",
                                          data.map((d) => d?.value)
                                        )
                                      }
                                    />
                                    {errors.site_pincode &&
                                    touched.site_pincode ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.site_pincode)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="card-body text-center">
                      <div>
                        {/* <button type="button" class="btn btn-success">
                    <span class="material-symbols-outlined float-start me-1">
                      add_circle
                    </span>{" "}
                    Add More Child
                  </button> */}
                        <button type="submit" class="btn btn-success">
                          {" "}
                          Submit{" "}
                          <span class="material-symbols-outlined float-end ms-1">
                            send
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}
