import * as Yup from "yup";

export const AddUserValidation = Yup.object().shape({
	firstName: Yup.string().trim().required(),
	lastName: Yup.string().trim().required(),
	email: Yup.string().email().trim().required(),
	password: Yup.string().trim().required(),
});

export const EditUserValidation = Yup.object().shape({
	firstName: Yup.string().trim().required(),
	lastName: Yup.string().trim().required(),
	email: Yup.string().email().trim().required(),
	password: Yup.string(),
});
