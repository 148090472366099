import * as Yup from "yup";

// export const AddContentValidation = Yup.object().shape({
//   title: Yup.string().trim().required(),
//   details: Yup.string().trim().required("Description is a required field"),
// });
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const updateSettingsValidation = Yup.object().shape({
  contactUsEmail1: Yup.string().email().trim().required("Email id is required"),
  contactUsEmail2: Yup.string().email().trim(),
  contactUsPhone1: Yup.string()
    .trim()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Phone number is required"),
  contactUsPhone2: Yup.string()
    .trim()
    .matches(phoneRegExp, "Phone number is not valid"),
  standByDay: Yup.number().min(0).max(3),
  bookingDay: Yup.number().min(0).max(5),

  // id: Yup.string().trim().required(),
});
