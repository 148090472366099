//var generator = require('generate-password');

export const camelToTitle = (text) => {
  const result = text.replace(/([A-Z])/g, " $1");
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

export const generatePassword = () => {
  var chars =
    "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var passwordLength = 8;
  var password = "";
  for (var i = 0; i <= passwordLength; i++) {
    var randomNumber = Math.floor(Math.random() * chars.length);
    password += chars.substring(randomNumber, randomNumber + 1);
  }
  return password;
};

export const filetypeCheck = (fileUrl) => {
  if (/\.png|\.jpg|\.JPG/.test(fileUrl)) {
    return "image";
  } else if (/\.mp4|\.mov/.test(fileUrl)) {
    return "video";
  } else return "youtube";
};
export function removeTags(str) {
  str = str.toString();
  return str
    .replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, " ")
    .trim();
}
export function genderOptions() {
  return [
    { key: "male", value: "Male" },
    { key: "female", value: "Female" },
  ];
}
export function maritalStatusOptions() {
  return [
    { key: "married", value: "Married" },
    { key: "unmarried", value: "Unmarried" },
    { key: "divorced", value: "Divorced" },
    { key: "widowed", value: "Widowed" },
  ];
}
export function userStatusOpetions() {
  return [
    { key: "pending", value: "Pending" },
    { key: "approved", value: "Approved" },
    { key: "rejected", value: "Rejected" },
    { key: "blocked", value: "Blocked" },
    { key: "deleted", value: "Deleted" },
  ];
}
export function venderStatusOptions() {
  return [
    { key: "active", value: "Active" },

    { key: "blocked", value: "Blocked" },
    { key: "deleted", value: "Deleted" },
  ];
}
export function employeeStatusOpetions() {
  return [
    { key: "approved", value: "Approved" },
    { key: "blocked", value: "Blocked" },
    { key: "deleted", value: "Deleted" },
  ];
}

export function employeeRoleOpetions() {
  return [
    { key: "employee", value: "Employee" },
    { key: "manager", value: "Manager" },
    { key: "superVisor", value: "Super Visor" },
    { key: "admin", value: "Admin" },
  ];
}
export function bankAccountMasking(str) {
  const trailingCharsIntactCount = 4;
  return (
    new Array(str.length - trailingCharsIntactCount + 1).join("x") +
    str.slice(-trailingCharsIntactCount)
  );
}

export function expenseCategory() {
  return [
    { key: "Dairy", value: "Dairy" },
    { key: "Vegetable", value: "Vegetable" },
    { key: "Fruits", value: "Fruits" },
    { key: "HK", value: "HK" },
    { key: "Grocery", value: "Grocery" },
    { key: "Meat", value: "Meat" },
    { key: "Egg", value: "Egg" },
    { key: "Purchase BF/Sn/Sw", value: "Purchase BF/Sn/Sw" },
    { key: "Auto", value: "Auto" },
    { key: "Petrol", value: "Petrol" },
    { key: "Tata Sky", value: "Tata Sky" },
    { key: "Sweet", value: "Sweet" },
    { key: "Gas", value: "Gas" },
    { key: "Others", value: "Others" },
  ];
}
export function designations() {
  return [
    "DRIVER",
    "COMPUTER OPERATOR",
    "OFFICE BOY",
    "ME",
    "HOUSEKEEPER",
    "ADMIN EXECUTIVE",
  ];
}
