import React, { Component } from "react";
import Multiselect from "multiselect-react-dropdown";

export default class MultiSelectOption extends Component {
  render() {
    // console.log("this.props>", this.props);
    return (
      <Multiselect
        options={this.props?.options} // Options to display in the dropdown
        selectedValues={this.props?.selectedValues} // Preselected value to persist in dropdown
        onSelect={this.props.onSelect} // Function will trigger on select event
        onRemove={this.props.onRemove} // Function will trigger on remove event
        displayValue={"name"} // Property name to display in the dropdown options
        showCheckbox={true}
        // hideSelectedList={true}
        singleSelect={this.props?.singleSelect || false}
        style={{
          justifyContent: "start",
          searchBox: {
            minHeight: 20,
            fontSize: 10,
          },
        }}
        // className={this.props?.className || "form-control form-control-sm"}
      />
    );
  }
}
