import React, { useEffect, useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
// import FsLightbox from "fslightbox-react-cors";
import { filetypeCheck } from "../../helpers/data";

export default function ImageLightBox(props) {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setOpen] = useState(true);
  const [images, setImages] = useState([]);
  const [toggler, setToggler] = useState(false);
  //   const { isOpen, images } = props;
  console.log("props>>", props);
  const init = () => {
    setToggler(props.isOpen);
    setImages(props.images);
  };

  useEffect(() => {
    init();
  }, [props]);
  return (
    <>
      {/* {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => props.closeLightBox()}
          imageCrossOrigin="anonymous"
          onMovePrevRequest={() =>
            // this.setState({
            //   photoIndex: (photoIndex + images.length - 1) % images.length,
            // })
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={
            () => setPhotoIndex((photoIndex + 1) % images.length)
            // this.setState({
            //   photoIndex: (photoIndex + 1) % images.length,
            // })
          }
        />
      )} */}
      {/* <button onClick={() => setToggler(!toggler)}>Toggle Lightbox</button> */}

      {/* <FsLightbox
        toggler={toggler}
        sources={
          images
          //   [
          //   "https://i.imgur.com/fsyrScY.jpg",
          //   "https://www.youtube.com/watch?v=xshEZzpS4CQ",
          //   "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
          // ]
        }
        // type="image"
        types={images.map((i) => filetypeCheck(i))}
        // types={[...new Array(images).fill("image")]}
        disableLocalStorage={true}
        // crossorigin="anonymous"
        onClose={() => props.closeLightBox()}
        customAttributes={[
          {
            // crossOrigin: null,
            crossOrigin: "anonymous",
          },
        ]}
      /> */}
    </>
  );
}
