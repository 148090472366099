import React, { useEffect, useState } from "react";
import "./EmployeeCard.scss";
import { useSearchParams } from "react-router-dom";
import api from "../../config/api";
import Network from "../../helpers/network";
export default function EmployeeCard() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [user, setUser] = useState(null);
  const getUser = async (id) => {
    try {
      if (id) {
        // setUserId(searchParams.get("id"));
        // getSalaryBreakDown(searchParams.get("id"));
        const result = await Network.get(api.getUser, { id: id });
        console.log("user >>", result);
        setUser(result?.data?.data);
        // setMessage("User has been fatched successfully");
        // setSuccess(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    // setUserId(searchParams.get("id"));
    getUser(searchParams.get("empId"));
  }, [searchParams]);

  return (
    <>
      <div className="id-container col-sm-10">
        <div class="id-card-tag"></div>
        <div class="id-card-tag-strip"></div>
        <div class="id-card-hook"></div>
        <div class="id-card-holder">
          <div class="id-card">
            <div class="logo_photo">
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5oDvP12lnQLvYKtGgfPMmNeNOB5thiX60zef7t2t5iQ&s" />
              <p>KS SOLUTIONS</p>
            </div>
            <div class="header profile_pic">
              <img src={`${user?.profile_pic}`} />
              {/* <img src="https://pbs.twimg.com/media/FjU2lkcWYAgNG6d.jpg" /> */}
            </div>

            <h2>{`${user?.firstName} ${user?.lastName}`}</h2>
            <h3>Ph: {`${user?.emergency_contact_number}`}</h3>
            <h3>Email: {`${user?.email}`}</h3>
            <h3>Employee Id: {`${user?.empId}`}</h3>
            <h3>Designation: {`${user?.designation}`}</h3>
            {/* <h3>Blood group: B+</h3> */}
            <hr />
            <div class="id_card_footer">
              <p>
                <strong>{`${user?.siteId?.site_name}`}</strong>
              </p>
              <p>
                {`${user?.siteId?.site_address}`} ,
                {`${user?.siteId?.site_city}`}
                <br />
                <strong>{`${user?.siteId?.site_pincode}`}</strong>
              </p>
              {/* <p>
                Ph: 9446062493 <br />
                E-ail: info@onetikk.info
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
