import React, { useEffect, useState } from "react";
import Network from "../../helpers/network";
import api from "../../config/api";
import { useSearchParams } from "react-router-dom";

export default function UserDetails() {
  const [user, setUser] = useState(null);
  const [userId, setUserId] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const getUser = async (id) => {
    try {
      if (id) {
        setUserId(searchParams.get("id"));
        const result = await Network.get(api.getUser, { id: id });
        setUser(result?.data?.data);
        // setMessage("User has been fatched successfully");
        // setSuccess(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    // setUserId(searchParams.get("id"));
    getUser(searchParams.get("id"));
  }, [searchParams]);
  return (
    <div class="col-md-10 col-sm-12  bashboard_boxes_open no_mar mb-2">
      <div class="purchase_order_buttom_nav mt-0">
        <div class=" mt-0 mb-0">
          <nav class="navbar bg-body-tertiary">
            <div class="container-fluid">
              <form class="d-flex ">
                <h6 class="navbar-brand text-white">Users Details</h6>
              </form>
              <ul class="d-flex nav justify-content-end">
                <li class="nav-item">
                  <a href="#">
                    <button class="nav-link bg-primary text-white rounded-5 me-1 px-2 border-0">
                      <span class="material-symbols-outlined">add_circle</span>
                    </button>
                  </a>
                </li>
                <li class="nav-item">
                  <a href="flow_through_details.html">
                    <button class="nav-link bg-success text-white rounded-5 me-1 px-2 border-0">
                      <span class="material-symbols-outlined">edit_note</span>
                    </button>
                  </a>
                </li>
                <li class="nav-item">
                  <a href="#">
                    <button class="nav-link bg-success text-white rounded-5 me-1 px-2 border-0">
                      <span class="material-symbols-outlined d-block">
                        {" "}
                        save{" "}
                      </span>
                    </button>
                  </a>
                </li>
                <li class="nav-item">
                  <a href="#">
                    <button class="nav-link bg-danger text-white rounded-5 me-1 px-2 border-0">
                      <span class="material-symbols-outlined">cancel</span>{" "}
                    </button>
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <div class="container-fluid">
        <div class="card mt-3">
          <section style={{ backgroundColor: "#eee" }}>
            <div class="container py-5">
              <div class="row">
                <div class="col-lg-4">
                  <div class="card mb-4">
                    <div class="card-body text-center">
                      <img
                        src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp"
                        alt="avatar"
                        class="rounded-circle img-fluid"
                        style={{ width: 150 }}
                      />
                      <h5 class="my-3">John Smith</h5>
                      <p class="">Full Stack Developer</p>
                      <p class="">Bay Area, San Francisco, CA</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-8">
                  <div>
                    <h2 class="heading_style">
                      <a href="#">Personal Details</a>
                    </h2>
                    <div class="card mb-4">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-sm-3">
                            <p class="mb-0">Full Name</p>
                          </div>
                          <div class="col-sm-9">
                            <p class="text-muted mb-0">Johnatan Smith</p>
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <p class="mb-0">Email</p>
                          </div>
                          <div class="col-sm-9">
                            <p class="text-muted mb-0">example@example.com</p>
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <p class="mb-0">Phone</p>
                          </div>
                          <div class="col-sm-9">
                            <p class="text-muted mb-0">(097) 234-5678</p>
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <p class="mb-0">Mobile</p>
                          </div>
                          <div class="col-sm-9">
                            <p class="text-muted mb-0">(098) 765-4321</p>
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <p class="mb-0">Address</p>
                          </div>
                          <div class="col-sm-9">
                            <p class="text-muted mb-0">
                              Bay Area, San Francisco, CA
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <h2 class="heading_style">
                      <a href="#">ID card Details</a>
                    </h2>
                    <div class="card mb-4">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-sm-3">
                            <p class="mb-0">Full Name</p>
                          </div>
                          <div class="col-sm-9">
                            <p class="text-muted mb-0">Johnatan Smith</p>
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <p class="mb-0">Email</p>
                          </div>
                          <div class="col-sm-9">
                            <p class="text-muted mb-0">example@example.com</p>
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <p class="mb-0">Phone</p>
                          </div>
                          <div class="col-sm-9">
                            <p class="text-muted mb-0">(097) 234-5678</p>
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <p class="mb-0">Mobile</p>
                          </div>
                          <div class="col-sm-9">
                            <p class="text-muted mb-0">(098) 765-4321</p>
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <p class="mb-0">Address</p>
                          </div>
                          <div class="col-sm-9">
                            <p class="text-muted mb-0">
                              Bay Area, San Francisco, CA
                            </p>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-3">
                            <p class="mb-0">Aadhar card photo</p>
                          </div>
                          <div class="col-sm-9">
                            <a href="#">
                              <div
                                class="idcard border border-2"
                                style={{
                                  backgroundImage:
                                    "url('images/profile_img.png')",
                                }}
                              ></div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <h2 class="heading_style">
                      <a href="#">Others Information</a>
                    </h2>
                    <div class="card mb-4">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-sm-3">
                            <p class="mb-0">Full Name</p>
                          </div>
                          <div class="col-sm-9">
                            <p class="text-muted mb-0">Johnatan Smith</p>
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <p class="mb-0">Email</p>
                          </div>
                          <div class="col-sm-9">
                            <p class="text-muted mb-0">example@example.com</p>
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <p class="mb-0">Phone</p>
                          </div>
                          <div class="col-sm-9">
                            <p class="text-muted mb-0">(097) 234-5678</p>
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <p class="mb-0">Mobile</p>
                          </div>
                          <div class="col-sm-9">
                            <p class="text-muted mb-0">(098) 765-4321</p>
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <p class="mb-0">Address</p>
                          </div>
                          <div class="col-sm-9">
                            <p class="text-muted mb-0">
                              Bay Area, San Francisco, CA
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <h2 class="heading_style">
                      <a href="#">Guardian Information</a>
                    </h2>
                    <div class="card mb-4">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-sm-3">
                            <p class="mb-0">Full Name</p>
                          </div>
                          <div class="col-sm-9">
                            <p class="text-muted mb-0">Johnatan Smith</p>
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <p class="mb-0">Email</p>
                          </div>
                          <div class="col-sm-9">
                            <p class="text-muted mb-0">example@example.com</p>
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <p class="mb-0">Phone</p>
                          </div>
                          <div class="col-sm-9">
                            <p class="text-muted mb-0">(097) 234-5678</p>
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <p class="mb-0">Mobile</p>
                          </div>
                          <div class="col-sm-9">
                            <p class="text-muted mb-0">(098) 765-4321</p>
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <p class="mb-0">Address</p>
                          </div>
                          <div class="col-sm-9">
                            <p class="text-muted mb-0">
                              Bay Area, San Francisco, CA
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
