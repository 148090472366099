import * as Yup from "yup";
import { genderOptions } from "../../helpers/data";

export const AddVendorValidation = Yup.object().shape({
  vendor_name: Yup.string().trim().required(),
  contact_person: Yup.string().trim().required(),
  phone: Yup.string().trim().required(),
  products: Yup.string().trim().required(),
  sites: Yup.string().trim().required(),
  district: Yup.string().trim().required(),
  state: Yup.string().trim().required(),
  aadhaar_number: Yup.string().trim().required(),
  aadhaar_pic: Yup.string().trim().required(),
  gst_number: Yup.string().trim().required(),
  gst_pic: Yup.string().trim().required(),
  pan_number: Yup.string().trim().required(),
  pan_pic: Yup.string().trim().required(),
  accountNumber: Yup.string().trim().required(),
  bankName: Yup.string().trim().required(),
  ifscCode: Yup.string().trim().required(),
  bankDoc: Yup.string().trim().required(),
});

export const EditVendorValidation = Yup.object().shape({
  // _id: Yup.string().trim().required(),
  vendor_name: Yup.string().trim().required(),
  contact_person: Yup.string().trim().required(),
  phone: Yup.string().trim().required(),
  products: Yup.string().trim().required(),
  sites: Yup.string().trim().required(),
  district: Yup.string().trim().required(),
  state: Yup.string().trim().required(),
  aadhaar_number: Yup.string().trim().required(),
  aadhaar_fpic: Yup.string().trim().required(),
  aadhaar_bpic: Yup.string().trim().required(),
  gst_number: Yup.string().trim().required(),
  gst_pic: Yup.string().trim().required(),
  pan_number: Yup.string().trim().required(),
  pan_pic: Yup.string().trim().required(),
  accountNumber: Yup.string().trim().required(),
  bankName: Yup.string().trim().required(),
  ifscCode: Yup.string().trim().required(),
  bankDoc: Yup.string().trim().required(),
});
