import React from "react";
import { Watch } from "react-loader-spinner";
import "./Loader.scss";

export default function Loader(props) {
  return (
    <div className="centered ">
      <Watch
        height="80"
        width="80"
        radius="48"
        color="#4fa94d"
        ariaLabel="watch-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={props.isLoading}
      />
    </div>
  );
}
