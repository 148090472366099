import axios from "axios";
import config from "../config/config";
// import { getAsyncStorage } from "../util/Helper";

// const token = getAsyncStorage("token");

const http = axios.create({
  baseURL: config.API_URL + "/api",
  headers: {
    "Content-Type": "application/json",
  },
});

// Set the AUTH token for any request
http.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});
export function setToken(token) {
  console.log("token>>", token);
  http.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("token")}`;
}
const Network = {
  http: http,
  get: (url, params, header = null) => {
    if (header) return http.get(url, { params: params }, header);
    else return http.get(url, { params: params });
  },
  post: (url, data, header = null) => {
    if (header) return http.post(url, data, header);
    else return http.post(url, data);
  },
  put: (url, data, header = null) => {
    if (header) return http.put(url, data, header);
    else return http.put(url, data);
  },
  delete: (url, params) => {
    return http.delete(url, { params: params });
  },
  upload: (data, options = null) => {
    if (options) return http.post("/util/file-upload", data, options);
    else return http.post("/util/file-upload", data);
  },
  uploads: (data, options = null) => {
    if (options) return http.post("/util/file-uploads", data, options);
    else return http.post("/util/file-uploads", data);
  },
};
export default Network;
