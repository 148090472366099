import * as Yup from "yup";
import { genderOptions } from "../../helpers/data";

export const AddUserValidation = Yup.object().shape({
  firstName: Yup.string().trim().required(),
  lastName: Yup.string().trim().required(),
  phone: Yup.string().trim().required(),
  dob: Yup.date().nullable().min(new Date(1900, 0, 1)),
  gender: Yup.mixed()
    .oneOf([...genderOptions().map((g) => g.key)])
    .defined(),
  maritalStatus: Yup.string().trim().required(),
  aadhaar_number: Yup.string().trim().required(),
  // aadhaar_pic: Yup.string().trim().required(),
  pan_number: Yup.string().trim().required(),
  // pan_pic: Yup.string().trim().required(),
  client: Yup.string().trim().required(),
  site: Yup.string().trim().required(),
  work_location: Yup.string().trim().required(),
  work_state: Yup.string().trim().required(),
  UAN_no: Yup.string().trim().nullable(),
  UAN_pic: Yup.string().trim().nullable(),
  ESIC_pic: Yup.string().trim().nullable(),
  emergency_contact_number: Yup.string().trim().required(),
  accountNumber: Yup.string().trim().required(),
  bankName: Yup.string().trim().required(),
  ifscCode: Yup.string().trim().required(),
  // bankDoc: Yup.string().trim().required(),
  presentAddress: Yup.object().shape({
    address: Yup.string().trim().required("Address is required"),
    city: Yup.string().trim().required("City is required"),
    state: Yup.string().trim().required("State is required"),
    pincode: Yup.date().required("Pincode is required"),
  }),
  permanentAddress: Yup.object().shape({
    address: Yup.string().trim().required("Address is required"),
    city: Yup.string().trim().required("City is required"),
    state: Yup.string().trim().required("State is required"),
    pincode: Yup.date().required("Pincode is required"),
  }),

  father: Yup.object().shape({
    name: Yup.string().trim().required("Father name is required"),
    relation: Yup.string().trim().required(),
    aadhaar_number: Yup.string()
      .trim()
      .required("Father's aadhaar number is required"),
    dob: Yup.date().required("Father's Date of birth is required"),
  }),
  mother: Yup.object().shape({
    name: Yup.string().trim().required("Mother name is required"),
    relation: Yup.string().trim().required(),
    aadhaar_number: Yup.string()
      .trim()
      .required("Mother's aadhaar number is required"),
    dob: Yup.date().required("Mother's Date of birth is required"),
  }),
  spouse: Yup.object().when("maritalStatus", {
    is: "married",
    then: Yup.object().shape({
      name: Yup.string().trim().required("Spouse name is required"),
      relation: Yup.string().trim().required(),
      aadhaar_number: Yup.string()
        .trim()
        .required("Spouse's aadhaar number is required"),
      dob: Yup.date().required("Spouse's Date of birth is required"),
    }),
    otherwise: Yup.object().shape({
      name: Yup.string().trim(),
      relation: Yup.string().trim(),
      aadhaar_number: Yup.string().trim(),
      dob: Yup.date(),
    }),
  }),
  children: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().trim(),
        relation: Yup.string().trim(),
        aadhaar_number: Yup.string().trim(),
        dob: Yup.date(),
        gender: Yup.string(),
      })
    )
    .nullable(),
});
const IdValidation = Yup.object().shape({
  aadhaar_number: Yup.string().trim().required(),
  aadhaar_pic: Yup.string().trim().required(),
  pan_number: Yup.string().trim().required(),
  pan_pic: Yup.string().trim().required(),
});

export const EditUserValidation = Yup.object().shape({
  firstName: Yup.string().trim().required(),
  lastName: Yup.string().trim().required(),
  phone: Yup.string().trim().required(),
  dob: Yup.date().nullable().min(new Date(1900, 0, 1)),
  gender: Yup.mixed()
    .oneOf([...genderOptions().map((g) => g.key)])
    .defined(),
  maritalStatus: Yup.string().trim().required(),
  aadhaar_number: Yup.string().trim().required(),
  // aadhaar_pic: Yup.string().trim().required(),
  pan_number: Yup.string().trim().required(),
  // pan_pic: Yup.string().trim().required(),
  client: Yup.string().trim().required(),
  site: Yup.string().trim().required(),
  work_location: Yup.string().trim().required(),
  work_state: Yup.string().trim().required(),
  UAN_no: Yup.string().trim().nullable(),
  UAN_pic: Yup.string().trim().nullable(),
  ESIC_pic: Yup.string().trim().nullable(),
  emergency_contact_number: Yup.string().trim().required(),
  accountNumber: Yup.string().trim().required(),
  bankName: Yup.string().trim().required(),
  ifscCode: Yup.string().trim().required(),
  // bankDoc: Yup.string().trim().required(),
  presentAddress: Yup.object().shape({
    address: Yup.string().trim().required("Address is required"),
    city: Yup.string().trim().required("City is required"),
    state: Yup.string().trim().required("State is required"),
    pincode: Yup.number().required("Pincode is required"),
  }),
  permanentAddress: Yup.object().shape({
    address: Yup.string().trim().required("Address is required"),
    city: Yup.string().trim().required("City is required"),
    state: Yup.string().trim().required("State is required"),
    pincode: Yup.number().required("Pincode is required"),
  }),
  // father: Yup.object().shape({
  //   name: Yup.string().trim().required("Father name is required"),
  //   relation: Yup.string().trim().required(),
  //   aadhaar_number: Yup.string()
  //     .trim()
  //     .required("Father's aadhaar number is required"),
  //   dob: Yup.date().required("Father's Date of birth is required"),
  // }),
  father: Yup.object().shape({
    name: Yup.string().trim().required("Father name is required"),
    relation: Yup.string().trim().required(),
    aadhaar_number: Yup.string()
      .trim()
      .required("Father's aadhaar number is required"),
    dob: Yup.date().required("Father's Date of birth is required"),
  }),
  mother: Yup.object().shape({
    name: Yup.string().trim().required("Mother name is required"),
    relation: Yup.string().trim().required(),
    aadhaar_number: Yup.string()
      .trim()
      .required("Mother's aadhaar number is required"),
    dob: Yup.date().required("Mother's Date of birth is required"),
  }),
  spouse: Yup.object().when("maritalStatus", {
    is: "married",
    then: Yup.object().shape({
      name: Yup.string().trim(),
      relation: Yup.string().trim(),
      aadhaar_number: Yup.string().trim(),
      dob: Yup.date(),
    }),
    otherwise: Yup.object().shape({
      name: Yup.string().trim(),
      relation: Yup.string().trim(),
      aadhaar_number: Yup.string().trim(),
      dob: Yup.date(),
    }),
  }),
  children: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().trim(),
        relation: Yup.string().trim(),
        aadhaar_number: Yup.string().trim(),
        dob: Yup.date(),
        gender: Yup.string(),
      })
    )
    .nullable(),
});

export const EmployeeSalaryBreakDownValidation = Yup.object().shape({
  basic: Yup.number().required().positive(),
  da: Yup.number().min(0),
  hra: Yup.number().required().positive(),
  other_allowance: Yup.number().min(0),
  // all_cash_reimbursement: Yup.number().min(0),
  // loans_and_advances: Yup.number().min(0),
  // medical: Yup.number().min(0),
  // arrears: Yup.number().min(0),
  // pf: Yup.number().min(0),
  // esi: Yup.number().min(0),
  // income_tax: Yup.number().min(0),
  // professional_tax: Yup.number().required().min(0),
  // loans_and_advances: Yup.number().min(0),
  // perquisites: Yup.number().min(0),
  // total_deduction: Yup.number().min(0),
  total_earning: Yup.number().min(0),
});
export const EmployeeSalaryPaymentValidation = Yup.object().shape({
  basic: Yup.number().required().positive(),
  da: Yup.number().min(0),
  hra: Yup.number().required().positive(),
  other_allowance: Yup.number().min(0),
  all_cash_reimbursement: Yup.number().min(0),
  loans_and_advances: Yup.number().min(0),
  medical: Yup.number().min(0),
  arrears: Yup.number().min(0),
  pf: Yup.number().min(0),
  esi: Yup.number().min(0),
  income_tax: Yup.number().min(0),
  professional_tax: Yup.number().required().min(0),
  loans_and_advances: Yup.number().min(0),
  perquisites: Yup.number().min(0),
  total_deduction: Yup.number().min(0),
  total_earning: Yup.number().min(0),
  totalWorkingDays: Yup.number().min(0),
  totalAttendence: Yup.number().min(0),
});
