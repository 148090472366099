import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Network from "../../helpers/network";
import api from "../../config/api";
import config from "./../../config/config";
import { timeFormated } from "../../helpers/time";
import ImageLightBox from "../../components/ImageLightBox/ImageLightBox";

export default function Invoices() {
  const [result, setResult] = useState([]);
  const [totalResult, setTotalResult] = useState([]);
  const [page, setPage] = useState(1);
  const [isError, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [message, setMessage] = useState(null);
  const [search, setSearch] = useState(null);
  const [status, setStatus] = useState("pending");
  const [images, setImages] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();
  const openLightBox = (images) => {
    console.log("101 images>>>", images);
    setOpen(true);
    setImages(images);
  };
  const closeLightBox = () => {
    setOpen(false);
    setImages([]);
  };
  const getInvoices = async () => {
    try {
      let res;
      if (search && search.length > 3) {
        res = await Network.get(api.invoices, {
          per_page: config.per_page,
          page: page,
          q: search,
          status: status,
        });
      } else {
        res = await Network.get(api.invoices, {
          per_page: config.per_page,
          page: page,
          status: status,
        });
      }

      console.log(res);
      setResult(res?.data?.data?.invoices);
      setTotalResult(res?.data?.data?.total);
    } catch (error) {
      console.log(error);
    }
  };
  const gotoDetails = (id) => {
    navigate("/invoices-details", { id: id });
  };
  useEffect(() => {
    getInvoices();
  }, []);
  return (
    <>
      <div class="col-md-10 col-sm-12  bashboard_boxes_open no_mar mb-2">
        <div class="purchase_order_buttom_nav mt-0">
          <div class=" mt-0 mb-0">
            <nav class="navbar bg-body-tertiary">
              <div class="container-fluid">
                <form class="d-flex ">
                  <h6 class="navbar-brand text-white">Invoices</h6>
                </form>
                <ul class="d-flex nav justify-content-end">
                  <li class="nav-item">
                    <a href="#">
                      <button class="nav-link bg-primary text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined">
                          add_circle
                        </span>
                      </button>
                    </a>
                  </li>
                  {/* <li class="nav-item">
                    <a href="flow_through_details.html">
                      <button class="nav-link bg-success text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined">edit_note</span>
                      </button>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="#">
                      <button class="nav-link bg-success text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined d-block">
                          {" "}
                          save{" "}
                        </span>
                      </button>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="#">
                      <button class="nav-link bg-danger text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined">cancel</span>{" "}
                      </button>
                    </a>
                  </li> */}
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div class="container-fluid pt-3">
          <div class="row">
            <div class="col-sm-12">
              <div class="card gate_entry_details2">
                <div class="table-content  tabile-responsive card-body">
                  <div class="row mb-2">
                    <div class="col-sm-5">
                      <div class="row form-group mb-0">
                        <label
                          for="inputPassword"
                          class="col-sm-3 col-form-label"
                        >
                          Select Date
                        </label>
                        <div class="col-sm-5">
                          <input
                            type="date"
                            class="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <table
                    id="example"
                    class="table   dt-responsive "
                    width="100%"
                  >
                    <thead>
                      <tr>
                        <th class="table-primary" width="30%">
                          Title
                        </th>
                        <th class="table-primary">Vendor</th>
                        <th class="table-primary">Amount</th>
                        <th class="table-primary">Site</th>
                        <th class="table-primary">Note</th>
                        <th class="table-primary">Created At</th>
                        <th class="table-primary">Image</th>
                        <th class="table-primary  text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {result &&
                        result.length > 0 &&
                        result.map((r) => (
                          <tr>
                            <td>{r?.invoice_title}</td>
                            <td>{r?.vendor?.vendor_name}</td>
                            <td>{r?.total_amount}</td>
                            <td>{r?.site?.site_name}</td>
                            <td>{r?.invoice_note}</td>
                            <td>{timeFormated(r?.createdAt, "DD/MM/YYYY")}</td>
                            <td class="text-center">
                              <a
                                href="javascript:void(0);"
                                onClick={() => {
                                  openLightBox([{ url: `${r?.invoice_pic}` }]);
                                }}
                              >
                                <img
                                  src={r?.invoice_pic}
                                  width="100"
                                  height={120}
                                />
                              </a>
                            </td>
                            <td>
                              <button
                                class=" bg-success text-white  border-0 "
                                onClick={() => gotoDetails(r?._id)}
                              >
                                <span class="material-symbols-outlined">
                                  visibility
                                </span>
                              </button>
                            </td>
                          </tr>
                        ))}
                      {result && result?.length === 0 && (
                        <tr>
                          <td colSpan={6} className="text-center">
                            No record found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="card-body mt-3">
                <nav aria-label="Page navigation example">
                  <ul class="pagination justify-content-center">
                    <li class="page-item disabled ">
                      <a class="page-link bg-success text-white">Previous</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">
                        1
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">
                        2
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">
                        3
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link bg-success text-white" href="#">
                        Next
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOpen && (
        <ImageLightBox
          images={images.map((i) => i.url)}
          isOpen={isOpen}
          closeLightBox={closeLightBox}
        />
      )}
    </>
  );
}
