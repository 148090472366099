import React, { useState, useEffect } from "react";
import { Field, FieldArray, Formik } from "formik";
import { AddUserValidation, EditUserValidation } from "./User.validation";
import Network from "./../../helpers/network";
import api from "./../../config/api";
import {
  camelToTitle,
  designations,
  genderOptions,
  generatePassword,
  maritalStatusOptions,
} from "./../../helpers/data";
import { timeFormated } from "./../../helpers/time";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./User.scss";
import Loader from "../../components/Loader/Loader";
import ImageLightBox from "../../components/ImageLightBox/ImageLightBox";
import moment from "moment";

export default function UserForm() {
  const [isEdit, setEdit] = useState(false);
  const [isShowPassword, setShowPassword] = useState(false);
  const [autoPassword, setAutoPassword] = useState(null);
  const [isError, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [message, setMessage] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [userId, setUserId] = useState(null);
  const [user, setUser] = useState(null);
  const [clients, setClients] = useState([]);
  const [sites, setSites] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [images, setImages] = useState([]);
  const navigation = useNavigate();
  const goToBack = () => {
    navigation("/users");
  };
  const openLightBox = (images) => {
    console.log("101 images>>>", images);
    setOpen(true);
    setImages(images);
  };

  const closeLightBox = () => {
    setOpen(false);
    setImages([]);
  };
  const fileUpload = async (e, name, setFieldValue) => {
    try {
      const frmData = new FormData();
      let res;
      setLoading(true);

      frmData.append("file", e.target.files[0]);
      res = await Network.upload(frmData);
      console.log("100 fileupload>>", res);
      setFieldValue(name, res?.data?.data?.path);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(true);
      setMessage("File upload faild! Please try after sometime.");
    }
  };
  const statusChange = async (status) => {
    try {
      if (window.confirm(`Are you sure you want to ${status} this user?`)) {
        const result = await Network.put(api.updateUser, {
          status,
          id: userId,
        });
        getUser(userId);
        setMessage(`User has been ${status} successfully`);
        setSuccess(true);
      }
    } catch (error) {
      setError(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
    }
  };
  const submit = async (values, resetForm) => {
    try {
      clearStatus();
      console.log("value>>", values);
      if (isEdit) {
        const result = await Network.put(api.updateUser, {
          ...values,
          id: userId,
        });
        console.log(result);
        setMessage("User has been updated successfully");
        setSuccess(true);
      } else {
        const result = await Network.post(api.createUser, values);
        console.log(result);
        setMessage("User has been created successfully");
        setSuccess(true);
        // resetForm();
      }
    } catch (error) {
      console.log("error", error);
      setError(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
    }
  };
  const getClientList = async () => {
    try {
      const res = await Network.get(api.client);
      setClients(res?.data?.data);
    } catch (err) {}
  };
  const getSiteList = async () => {
    try {
      const res = await Network.get(api.sites);
      console.log("sites>>", res);
      setSites(res?.data?.data);
    } catch (err) {}
  };
  const clearStatus = () => {
    setError(false);
    setSuccess(false);
    setMessage(null);
  };
  const generatePasswordHandler = () => {
    const pass = generatePassword();
    setAutoPassword(pass);
  };
  const getUser = async (id) => {
    try {
      if (id && !isEdit) {
        setUserId(searchParams.get("id"));
        setEdit(true);
        clearStatus();
        const result = await Network.get(api.getUser, { id: id });
        console.log(result);
        setUser(result?.data?.data);
        // setMessage("User has been fatched successfully");
        // setSuccess(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    // setUserId(searchParams.get("id"));
    getUser(searchParams.get("id"));
  }, [searchParams]);
  useEffect(() => {
    getClientList();
    getSiteList();
  }, []);
  return (
    <>
      <div class="col-md-10 col-sm-12  bashboard_boxes_open no_mar mb-2">
        <div class="purchase_order_buttom_nav mt-0">
          <div class=" mt-0 mb-0">
            <nav class="navbar bg-body-tertiary">
              <div class="container-fluid">
                <form class="d-flex ">
                  <h6 class="navbar-brand text-white">Employee On Boarding</h6>
                </form>
                <ul class="d-flex nav justify-content-end">
                  <li class="nav-item">
                    <a href="javascript:void(0);" onClick={goToBack}>
                      <button class="nav-link bg-danger text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined">cancel</span>{" "}
                      </button>
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div class="container-fluid pt-3">
          {isLoading && <Loader />}
          <Formik
            initialValues={{
              firstName: isEdit ? user?.firstName : "",
              lastName: isEdit ? user?.lastName : "",
              phone: isEdit ? user?.phone : "",
              email: isEdit ? user?.email : "",
              gender: isEdit ? user?.gender : "",
              profile_pic: isEdit ? user?.profile_pic : "",
              dob: isEdit ? timeFormated(user?.dob, "YYYY-MM-DD") : "",
              maritalStatus: isEdit ? user?.marital_status : "",
              aadhaar_number: isEdit ? user?.kyc?.aadhaar_number : "",
              aadhaar_fpic: isEdit ? user?.kyc?.aadhaar_fpic : "",
              aadhaar_bpic: isEdit ? user?.kyc?.aadhaar_bpic : "",
              pan_number: isEdit ? user?.kyc?.pan_number : "",
              pan_pic: isEdit ? user?.kyc?.pan_pic : "",
              client: isEdit ? user?.client : "",
              site: isEdit ? user?.siteId?._id : "",
              work_location: isEdit ? user?.work_location : "",
              work_state: isEdit ? user?.work_state : "",
              designation: isEdit ? user?.designation : "",
              UAN_no: isEdit ? user?.UAN_no : "",
              ESIC_no: isEdit ? user?.ESIC_no : "",
              joiningDate: isEdit
                ? moment(user?.joiningDate).format("YYYY-MM-DD")
                : new Date(),
              emergency_contact_number: isEdit
                ? user?.emergency_contact_number
                : "",
              accountNumber: isEdit ? user?.accountDetails?.accountNumber : "",
              bankName: isEdit ? user?.accountDetails?.bankName : "",
              ifscCode: isEdit ? user?.accountDetails?.ifscCode : "",
              bankDoc: isEdit ? user?.accountDetails?.bankDoc : "",
              isAddressSame: false,
              presentAddress: {
                address: isEdit ? user?.presentAddress?.address : "",
                city: isEdit ? user?.presentAddress?.city : "",
                state: isEdit ? user?.presentAddress?.state : "",
                pincode: isEdit ? user?.presentAddress?.pincode : "",
              },
              permanentAddress: {
                address: isEdit ? user?.permanentAddress?.address : "",
                city: isEdit ? user?.permanentAddress?.city : "",
                state: isEdit ? user?.permanentAddress?.state : "",
                pincode: isEdit ? user?.permanentAddress?.pincode : "",
              },
              father: {
                name: isEdit ? user?.father?.name : "",
                dob: isEdit
                  ? timeFormated(user?.father?.dob, "YYYY-MM-DD")
                  : "",
                relation: "father",
                aadhaar_number: isEdit ? user?.father?.aadhaar_number : "",
              },
              mother: {
                name: isEdit ? user?.mother?.name : "",
                dob: isEdit
                  ? timeFormated(user?.mother?.dob, "YYYY-MM-DD")
                  : "",
                relation: "mother",
                aadhaar_number: isEdit ? user?.mother?.aadhaar_number : "",
              },
              spouse: {
                name: isEdit ? user?.spouse?.name : "",
                dob: isEdit
                  ? timeFormated(user?.spouse?.dob, "YYYY-MM-DD")
                  : "",
                relation: "spouse",
                aadhaar_number: isEdit ? user?.spouse?.aadhaar_number : "",
              },
              children: isEdit
                ? user?.children.length > 0
                  ? user?.children.map((c) => ({
                      name: c?.name,
                      dob: timeFormated(c?.dob, "YYYY-MM-DD"),
                      aadhaar_number: c?.aadhaar_number,
                    }))
                  : [
                      {
                        name: "",
                        dob: "",
                        aadhaar_number: "",
                        relation: "child",
                      },
                    ]
                : [
                    {
                      name: "",
                      dob: "",
                      aadhaar_number: "",
                      relation: "child",
                    },
                  ],
            }}
            onSubmit={(values, { resetForm }) => submit(values, resetForm)}
            validationSchema={isEdit ? EditUserValidation : AddUserValidation}
            enableReinitialize={true}
          >
            {({
              handleChange,
              handleSubmit,
              errors,
              touched,
              values,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                {isSuccess && (
                  <div className="alert alert-success">{message}</div>
                )}
                {isError && <div className="alert alert-danger">{message}</div>}
                <div class="row">
                  <div class="col-sm-12">
                    <h2 class="heading_style">
                      <a href="#">Personal Details</a>
                    </h2>
                    <div class="card mb-3">
                      <div class="row g-0 p-1">
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item border-radius">
                            <div class="row">
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    First Name
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="firstName"
                                      onChange={handleChange}
                                      value={values.firstName}
                                    />
                                    {errors.firstName && touched.firstName ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.firstName)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Last Name
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="lastName"
                                      onChange={handleChange}
                                      value={values.lastName}
                                    />
                                    {errors.lastName && touched.lastName ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.lastName)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Mobile Number
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="phone"
                                      onChange={handleChange}
                                      value={values.phone}
                                    />
                                    {errors.phone && touched.phone ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.phone)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Email
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="email"
                                      onChange={handleChange}
                                      value={values.email}
                                    />
                                    {errors.email && touched.email ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.email)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Choose Profile pic
                                  </label>
                                  <div class="col-sm-7 previewImage">
                                    {isEdit && values.profile_pic && (
                                      <a
                                        href="javascript:void(0);"
                                        onClick={() => {
                                          openLightBox([
                                            { url: `${values.profile_pic}` },
                                          ]);
                                        }}
                                      >
                                        <img
                                          src={values.profile_pic}
                                          // crossOrigin="anonymous"
                                        />
                                      </a>
                                    )}
                                    <input
                                      type="file"
                                      class="form-control"
                                      id="customFile"
                                      accept="image/png,  image/jpeg"
                                      onChange={(e) => {
                                        fileUpload(
                                          e,
                                          "profile_pic",
                                          setFieldValue
                                        );
                                      }}
                                    />
                                    <input
                                      type="hidden"
                                      class="form-control"
                                      id="customFile"
                                      name="profile_pic"
                                      onChange={handleChange}
                                      value={values.profile_pic}
                                    />
                                    {errors.profile_pic &&
                                    touched.profile_pic ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.profile_pic)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>

                          <li class="list-group-item border-radius">
                            <div class="row">
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Date of Birth
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="date"
                                      class="form-control form-control-sm"
                                      format="dd-MM-yyyy"
                                      name="dob"
                                      onChange={handleChange}
                                      value={values.dob}
                                    />
                                    {errors.dob && touched.dob ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.dob)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Gender
                                  </label>
                                  <div class="col-sm-7">
                                    <select
                                      class="form-control form-control-sm"
                                      name="gender"
                                      onChange={handleChange}
                                      value={values.gender}
                                    >
                                      <option>select </option>
                                      {genderOptions().map((g) => (
                                        <option value={g.key}>{g.value}</option>
                                      ))}
                                    </select>
                                    {errors.gender && touched.gender ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.gender)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Marital Status
                                  </label>
                                  <div class="col-sm-7">
                                    <select
                                      class="form-control form-control-sm"
                                      name="maritalStatus"
                                      onChange={handleChange}
                                      value={values.maritalStatus}
                                    >
                                      <option>select </option>
                                      {maritalStatusOptions().map((g) => (
                                        <option value={g.key}>{g.value}</option>
                                      ))}
                                    </select>
                                    {errors.maritalStatus &&
                                    touched.maritalStatus ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.maritalStatus)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <h2 class="heading_style">
                      <a href="#">ID card Details</a>
                    </h2>
                    <div class="card mb-3">
                      <div class="row g-0 p-1">
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item border-radius">
                            <div class="row">
                              <div class="col-sm-6">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Aadhaar Number
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="aadhaar_number"
                                      onChange={handleChange}
                                      value={values.aadhaar_number}
                                    />
                                    {errors.aadhaar_number &&
                                    touched.aadhaar_number ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.aadhaar_number)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Choose Aadhar card (front)
                                  </label>
                                  <div class="col-sm-7 previewImage">
                                    {isEdit && values.aadhaar_fpic && (
                                      <a
                                        href="javascript:void(0);"
                                        onClick={() => {
                                          openLightBox([
                                            { url: `${values.aadhaar_fpic}` },
                                          ]);
                                        }}
                                      >
                                        <img
                                          src={values.aadhaar_fpic}
                                          // crossOrigin="anonymous"
                                        />
                                      </a>
                                    )}
                                    <input
                                      type="file"
                                      class="form-control"
                                      id="customFile"
                                      accept="image/png,  image/jpeg"
                                      onChange={(e) => {
                                        fileUpload(
                                          e,
                                          "aadhaar_fpic",
                                          setFieldValue
                                        );
                                      }}
                                    />
                                    <input
                                      type="hidden"
                                      class="form-control"
                                      id="customFile"
                                      name="aadhaar_fpic"
                                      onChange={handleChange}
                                      value={values.aadhaar_fpic}
                                    />
                                    {errors.aadhaar_fpic &&
                                    touched.aadhaar_fpic ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.aadhaar_fpic)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Choose Aadhar card (back)
                                  </label>
                                  <div class="col-sm-7 previewImage">
                                    {isEdit && values.aadhaar_bpic && (
                                      <a
                                        href="javascript:void(0);"
                                        onClick={() => {
                                          openLightBox([
                                            { url: `${values.aadhaar_bpic}` },
                                          ]);
                                        }}
                                      >
                                        <img
                                          src={values.aadhaar_bpic}
                                          // crossOrigin="anonymous"
                                        />
                                      </a>
                                    )}
                                    <input
                                      type="file"
                                      class="form-control"
                                      id="customFile"
                                      accept="image/png,  image/jpeg"
                                      onChange={(e) => {
                                        fileUpload(
                                          e,
                                          "aadhaar_bpic",
                                          setFieldValue
                                        );
                                      }}
                                    />
                                    <input
                                      type="hidden"
                                      class="form-control"
                                      id="customFile"
                                      name="aadhaar_fpic"
                                      onChange={handleChange}
                                      value={values.aadhaar_bpic}
                                    />
                                    {errors.aadhaar_bpic &&
                                    touched.aadhaar_bpic ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.aadhaar_bpic)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>

                          <li class="list-group-item border-radius">
                            <div class="row">
                              <div class="col-sm-6">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    PAN Number
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="pan_number"
                                      onChange={handleChange}
                                      value={values.pan_number}
                                    />
                                    {errors.pan_number && touched.pan_number ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.pan_number)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Choose pan card
                                  </label>
                                  <div class="col-sm-7 previewImage">
                                    {isEdit && values.pan_pic && (
                                      <a
                                        href="javascript:void(0);"
                                        onClick={() => {
                                          openLightBox([
                                            { url: `${values.pan_pic}` },
                                          ]);
                                        }}
                                      >
                                        <img
                                          src={values.pan_pic}
                                          // crossOrigin="anonymous"
                                        />
                                      </a>
                                    )}
                                    <input
                                      type="file"
                                      class="form-control"
                                      id="customFile"
                                      accept="image/png,  image/jpeg"
                                      onChange={(e) => {
                                        fileUpload(e, "pan_pic", setFieldValue);
                                      }}
                                    />
                                    <input
                                      type="hidden"
                                      class="form-control"
                                      id="customFile"
                                      name="pan_pic"
                                      onChange={handleChange}
                                      value={values.pan_pic}
                                    />
                                    {errors.pan_pic && touched.pan_pic ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.pan_pic)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <h2 class="heading_style">
                      <a href="#">Address Information</a>
                    </h2>
                    <div class="card mb-3">
                      <div class="row g-0 p-1">
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item border-radius">
                            <h2 class="heading_style">
                              <a href="#">Permanent Address</a>
                            </h2>
                            <div class="row">
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Address
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="permanentAddress.address"
                                      onChange={handleChange}
                                      value={values.permanentAddress?.address}
                                    />
                                    {errors.permanentAddress?.address &&
                                    touched.permanentAddress?.address ? (
                                      <div class="text-danger">
                                        {camelToTitle(
                                          errors.permanentAddress?.address
                                        )}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    City
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="permanentAddress.city"
                                      onChange={handleChange}
                                      value={values.permanentAddress?.city}
                                    />
                                    {errors.permanentAddress?.city &&
                                    touched.permanentAddress?.city ? (
                                      <div class="text-danger">
                                        {camelToTitle(
                                          errors.permanentAddress?.city
                                        )}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    State
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="permanentAddress.state"
                                      onChange={handleChange}
                                      value={values.permanentAddress?.state}
                                    />
                                    {errors.permanentAddress?.state &&
                                    touched.permanentAddress?.state ? (
                                      <div class="text-danger">
                                        {camelToTitle(
                                          errors.permanentAddress?.state
                                        )}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Pincode
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="permanentAddress.pincode"
                                      onChange={handleChange}
                                      value={values.permanentAddress?.pincode}
                                    />
                                    {errors.permanentAddress?.pincode &&
                                    touched.permanentAddress?.pincode ? (
                                      <div class="text-danger">
                                        {camelToTitle(
                                          errors.permanentAddress?.pincode
                                        )}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class="list-group-item border-radius">
                            <h2 class="heading_style">
                              <a href="#">Present Address</a>
                            </h2>
                            <h2 class="heading_style">
                              <span>Same as above</span>
                              <input
                                type="checkbox"
                                className="m-2"
                                name="isAddressSame"
                                // onChange={(e) => {
                                //   console.log(e);
                                //   setFieldValue(
                                //     "isAddressSame",
                                //     e.target?.value
                                //   );
                                // }}
                                checked={values.isAddressSame}
                                onClick={() => {
                                  setFieldValue(
                                    "isAddressSame",
                                    !values.isAddressSame
                                  );
                                  console.log(values.isAddressSame);
                                  if (!values.isAddressSame) {
                                    setFieldValue(
                                      "presentAddress.address",
                                      values.permanentAddress.address
                                    );
                                    setFieldValue(
                                      "presentAddress.city",
                                      values.permanentAddress.city
                                    );
                                    setFieldValue(
                                      "presentAddress.state",
                                      values.permanentAddress.state
                                    );
                                    setFieldValue(
                                      "presentAddress.pincode",
                                      values.permanentAddress.pincode
                                    );
                                  }
                                }}
                              />
                            </h2>
                            <div class="row">
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Address
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="presentAddress.address"
                                      onChange={handleChange}
                                      value={values.presentAddress?.address}
                                    />
                                    {errors.presentAddress?.address &&
                                    touched.presentAddress?.address ? (
                                      <div class="text-danger">
                                        {camelToTitle(
                                          errors.presentAddress.address
                                        )}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    City
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="presentAddress.city"
                                      onChange={handleChange}
                                      value={values.presentAddress?.city}
                                    />
                                    {errors.presentAddress?.city &&
                                    touched.presentAddress?.city ? (
                                      <div class="text-danger">
                                        {camelToTitle(
                                          errors.presentAddress?.city
                                        )}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    State
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="presentAddress.state"
                                      onChange={handleChange}
                                      value={values.presentAddress?.state}
                                    />
                                    {errors.presentAddress?.state &&
                                    touched.presentAddress?.state ? (
                                      <div class="text-danger">
                                        {camelToTitle(
                                          errors.presentAddress?.state
                                        )}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Pincode
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="presentAddress.pincode"
                                      onChange={handleChange}
                                      value={values.presentAddress?.pincode}
                                    />
                                    {errors.presentAddress?.pincode &&
                                    touched.presentAddress?.pincode ? (
                                      <div class="text-danger">
                                        {camelToTitle(
                                          errors.presentAddress?.pincode
                                        )}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <h2 class="heading_style">
                      <a href="#">Others Information</a>
                    </h2>
                    <div class="card mb-3">
                      <div class="row g-0 p-1">
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item border-radius">
                            <div class="row">
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Client
                                  </label>
                                  <div class="col-sm-7">
                                    <Field
                                      as="select"
                                      class="form-control form-control-sm"
                                      name="client"
                                      onChange={handleChange}
                                      value={values.client}
                                    >
                                      <option>select</option>
                                      {clients &&
                                        clients.length > 0 &&
                                        clients.map((c) => (
                                          <option
                                            value={c?._id}
                                            // selected={c?._id == values.client}
                                          >
                                            {c.client_name}
                                          </option>
                                        ))}
                                    </Field>
                                    {errors.client && touched.client ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.client)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Site
                                  </label>
                                  <div class="col-sm-7">
                                    <Field
                                      as="select"
                                      class="form-control form-control-sm"
                                      name="site"
                                      onChange={handleChange}
                                      value={values.site}
                                    >
                                      <option>select</option>
                                      {sites &&
                                        sites.length > 0 &&
                                        sites.map((s) => (
                                          <option
                                            value={s?._id}
                                            // selected={s?._id == values.site}
                                          >
                                            {s.site_name}
                                          </option>
                                        ))}
                                    </Field>
                                    {errors.site && touched.site ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.site)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Work Location
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="work_location"
                                      onChange={handleChange}
                                      value={values.work_location}
                                    />
                                    {errors.work_location &&
                                    touched.work_location ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.work_location)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Work State
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="work_state"
                                      onChange={handleChange}
                                      value={values.work_state}
                                    />
                                    {errors.work_state && touched.work_state ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.work_state)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Employee Prev UAN
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="UAN_no"
                                      onChange={handleChange}
                                      value={values.UAN_no}
                                    />
                                    {errors.UAN_no && touched.UAN_no ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.UAN_no)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Employee Prev ESI No
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="ESIC_no"
                                      onChange={handleChange}
                                      value={values.ESIC_no}
                                    />
                                    {errors.ESIC_no && touched.ESIC_no ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.ESIC_no)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Designation
                                  </label>
                                  <div class="col-sm-7">
                                    <Field
                                      as="select"
                                      class="form-control form-control-sm"
                                      name="designation"
                                      onChange={handleChange}
                                      value={values.designation}
                                    >
                                      <option>select</option>
                                      {designations() &&
                                        designations().length > 0 &&
                                        designations().map((s) => (
                                          <option
                                            value={s}
                                            // selected={s?._id == values.site}
                                          >
                                            {s}
                                          </option>
                                        ))}
                                    </Field>
                                    {errors.designation &&
                                    touched.designation ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.designation)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Joining Date
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="date"
                                      class="form-control form-control-sm"
                                      name="joiningDate"
                                      onChange={handleChange}
                                      value={values.joiningDate}
                                    />
                                    {errors.joiningDate &&
                                    touched.joiningDate ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.joiningDate)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>

                          <li class="list-group-item border-radius">
                            <div class="row">
                              <div class="col-sm-6">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-6 col-form-label"
                                  >
                                    Employee Prev UAN upload
                                  </label>
                                  <div class="col-sm-6 previewImage">
                                    {isEdit && values.UAN_pic && (
                                      <a
                                        href="javascript:void(0);"
                                        onClick={() => {
                                          openLightBox([
                                            { url: `${values.UAN_pic}` },
                                          ]);
                                        }}
                                      >
                                        <img
                                          src={values.UAN_pic}
                                          // crossOrigin="anonymous"
                                        />
                                      </a>
                                    )}
                                    <input
                                      type="file"
                                      class="form-control"
                                      accept="image/png,  image/jpeg"
                                      id="customFile"
                                      onChange={(e) => {
                                        fileUpload(e, "UAN_pic", setFieldValue);
                                      }}
                                    />
                                    <input
                                      type="hidden"
                                      name="UAN_pic"
                                      onChange={handleChange}
                                      value={values.UAN_pic}
                                    />
                                    {errors.UAN_pic && touched.UAN_pic ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.UAN_pic)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-6 col-form-label"
                                  >
                                    Previous ESI image upload
                                  </label>
                                  <div class="col-sm-6 previewImage">
                                    {isEdit && values.ESIC_pic && (
                                      <a
                                        href="javascript:void(0);"
                                        onClick={() => {
                                          openLightBox([
                                            { url: `${values.ESIC_pic}` },
                                          ]);
                                        }}
                                      >
                                        <img
                                          src={values.ESIC_pic}
                                          // crossOrigin="anonymous"
                                        />
                                      </a>
                                    )}
                                    <input
                                      type="file"
                                      class="form-control"
                                      id="customFile"
                                      accept="image/png,  image/jpeg"
                                      onChange={(e) => {
                                        fileUpload(
                                          e,
                                          "ESIC_pic",
                                          setFieldValue
                                        );
                                      }}
                                    />
                                    <input
                                      type="hidden"
                                      name="ESIC_pic"
                                      onChange={handleChange}
                                      value={values.ESIC_pic}
                                    />
                                    {errors.ESIC_pic && touched.ESIC_pic ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.ESIC_pic)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <h2 class="heading_style">
                      <a href="#">Account Details</a>
                    </h2>
                    <div class="card mb-3">
                      <div class="row g-0 p-1">
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item border-radius">
                            <div class="row">
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Account Number
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="accountNumber"
                                      onChange={handleChange}
                                      value={values.accountNumber}
                                    />
                                    {errors.accountNumber &&
                                    touched.accountNumber ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.accountNumber)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Bank Name
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="bankName"
                                      onChange={handleChange}
                                      value={values.bankName}
                                    />
                                    {errors.bankName && touched.bankName ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.bankName)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    IFSC Code
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="ifscCode"
                                      onChange={handleChange}
                                      value={values.ifscCode}
                                    />
                                    {errors.ifscCode && touched.ifscCode ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.ifscCode)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>

                          <li class="list-group-item border-radius">
                            <div class="row">
                              <div class="col-sm-4">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-5 col-form-label"
                                  >
                                    Passbook or cheque upload
                                  </label>
                                  <div class="col-sm-7 previewImage">
                                    {isEdit && values.bankDoc && (
                                      <a
                                        href="javascript:void(0);"
                                        onClick={() => {
                                          openLightBox([
                                            { url: `${values.bankDoc}` },
                                          ]);
                                        }}
                                      >
                                        <img
                                          src={values.bankDoc}
                                          // crossOrigin="anonymous"
                                        />
                                      </a>
                                    )}
                                    <input
                                      type="file"
                                      class="form-control"
                                      id="customFile"
                                      accept="image/png,  image/jpeg"
                                      onChange={(e) => {
                                        fileUpload(e, "bankDoc", setFieldValue);
                                      }}
                                    />
                                    <input
                                      type="hidden"
                                      name="bankDoc"
                                      onChange={handleChange}
                                      value={values.bankDoc}
                                    />

                                    {errors.bankDoc && touched.bankDoc ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.bankDoc)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <h2 class="heading_style">
                      <a href="#">Guardian Information</a>
                    </h2>
                    <div class="card mb-3">
                      <div class="row g-0 p-1">
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item border-radius">
                            <div class="row">
                              <div class="col-sm-6">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-6 col-form-label"
                                  >
                                    Father Name or Guardian Name
                                  </label>
                                  <div class="col-sm-6">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="father.name"
                                      onChange={handleChange}
                                      value={values?.father?.name}
                                    />
                                    {errors?.father?.name &&
                                    touched?.father?.name ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors?.father?.name)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-3">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-4 col-form-label"
                                  >
                                    DOB
                                  </label>
                                  <div class="col-sm-8">
                                    <input
                                      type="date"
                                      class="form-control form-control-sm"
                                      name="father.dob"
                                      onChange={handleChange}
                                      value={values.father?.dob}
                                    />
                                    {errors.father?.dob &&
                                    touched.father?.dob ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.father?.dob)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-3">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-4 col-form-label"
                                  >
                                    Aadhar No
                                  </label>
                                  <div class="col-sm-8">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="father.aadhaar_number"
                                      onChange={handleChange}
                                      value={values?.father?.aadhaar_number}
                                    />
                                    {errors.father?.aadhaar_number &&
                                    touched.father?.aadhaar_number ? (
                                      <div class="text-danger">
                                        {camelToTitle(
                                          errors.father.aadhaar_number
                                        )}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class="list-group-item border-radius">
                            <div class="row">
                              <h2 class="heading_style">
                                <a href="#">Mother Information</a>
                              </h2>
                              <div class="col-sm-6">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-6 col-form-label"
                                  >
                                    Mother Name
                                  </label>
                                  <div class="col-sm-6">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="mother.name"
                                      onChange={handleChange}
                                      value={values.mother?.name}
                                    />
                                    {errors.mother?.name &&
                                    touched.mother?.name ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.mother?.name)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-3">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-4 col-form-label"
                                  >
                                    DOB
                                  </label>
                                  <div class="col-sm-8">
                                    <input
                                      type="date"
                                      class="form-control form-control-sm"
                                      name="mother.dob"
                                      onChange={handleChange}
                                      value={values.mother?.dob}
                                    />
                                    {errors.mother?.dob &&
                                    touched.mother?.dob ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.mother?.dob)}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-3">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-4 col-form-label"
                                  >
                                    Aadhar No
                                  </label>
                                  <div class="col-sm-8">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="mother.aadhaar_number"
                                      onChange={handleChange}
                                      value={values.mother?.aadhaar_number}
                                    />
                                    {errors.mother?.aadhaar_number &&
                                    touched.mother?.aadhaar_number ? (
                                      <div class="text-danger">
                                        {camelToTitle(
                                          errors.mother?.aadhaar_number
                                        )}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          {values.maritalStatus === "married" && (
                            <li class="list-group-item border-radius">
                              <div class="row">
                                <h2 class="heading_style">
                                  <a href="#">Spouse Information</a>
                                </h2>
                                <div class="col-sm-6">
                                  <div class="row form-group mb-0">
                                    <label
                                      for="inputPassword"
                                      class="col-sm-6 col-form-label"
                                    >
                                      Spouse Name
                                    </label>
                                    <div class="col-sm-6">
                                      <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        name="spouse.name"
                                        onChange={handleChange}
                                        value={values.spouse?.name}
                                      />
                                      {errors.spouse?.name &&
                                      touched.spouse?.name ? (
                                        <div class="text-danger">
                                          {camelToTitle(errors.spouse?.name)}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-3">
                                  <div class="row form-group mb-0">
                                    <label
                                      for="inputPassword"
                                      class="col-sm-4 col-form-label"
                                    >
                                      DOB
                                    </label>
                                    <div class="col-sm-8">
                                      <input
                                        type="date"
                                        class="form-control form-control-sm"
                                        name="spouse.dob"
                                        onChange={handleChange}
                                        value={values.spouse?.dob}
                                      />
                                      {errors.spouse?.dob &&
                                      touched.spouse?.dob ? (
                                        <div class="text-danger">
                                          {camelToTitle(errors.spouse?.dob)}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-3">
                                  <div class="row form-group mb-0">
                                    <label
                                      for="inputPassword"
                                      class="col-sm-4 col-form-label"
                                    >
                                      Aadhar No
                                    </label>
                                    <div class="col-sm-8">
                                      <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        name="spouse.aadhaar_number"
                                        onChange={handleChange}
                                        value={values.spouse?.aadhaar_number}
                                      />
                                      {errors.spouse?.aadhaar_number &&
                                      touched.spouse?.aadhaar_number ? (
                                        <div class="text-danger">
                                          {camelToTitle(
                                            errors.spouse?.aadhaar_number
                                          )}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          )}
                          <li class="list-group-item border-radius">
                            <div class="row">
                              <div class="col-sm-6">
                                <div class="row form-group mb-0">
                                  <label
                                    for="inputPassword"
                                    class="col-sm-6 col-form-label"
                                  >
                                    Emergency Contact Number
                                  </label>
                                  <div class="col-sm-6">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      name="emergency_contact_number"
                                      onChange={handleChange}
                                      value={values.emergency_contact_number}
                                    />
                                    {errors.emergency_contact_number &&
                                    touched.emergency_contact_number ? (
                                      <div class="text-danger">
                                        {camelToTitle(
                                          errors.emergency_contact_number
                                        )}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {values.maritalStatus !== "unmarried" && (
                      <>
                        <h2 class="heading_style">
                          <a href="#">Child Information</a>
                        </h2>
                        <FieldArray
                          name="children"
                          render={(arrayHelpers) => {
                            const users = values.children;
                            return (
                              <div>
                                {users && users.length > 0
                                  ? users.map((user, index) => (
                                      <div class="card mb-3">
                                        <div class="row g-0 p-1">
                                          <ul class="list-group list-group-flush">
                                            <li class="list-group-item border-radius">
                                              <div class="row">
                                                <div class="col-sm-3">
                                                  <div class="row form-group mb-0">
                                                    <label
                                                      for="inputPassword"
                                                      class="col-sm-4 col-form-label"
                                                    >
                                                      Child {index + 1}
                                                    </label>
                                                    <div class="col-sm-8">
                                                      <Field
                                                        type="text"
                                                        class="form-control form-control-sm"
                                                        name={`children.${index}.name`}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="col-sm-3">
                                                  <div class="row form-group mb-0">
                                                    <label
                                                      for="inputPassword"
                                                      class="col-sm-4 col-form-label"
                                                    >
                                                      Gender
                                                    </label>
                                                    <div class="col-sm-8">
                                                      <select
                                                        class="form-control form-control-sm"
                                                        name={`children.${index}.gender`}
                                                        onChange={handleChange}
                                                        value={values.gender}
                                                      >
                                                        <option>select </option>
                                                        {genderOptions().map(
                                                          (g) => (
                                                            <option
                                                              value={g.key}
                                                            >
                                                              {g.value}
                                                            </option>
                                                          )
                                                        )}
                                                      </select>
                                                      {errors.site_name &&
                                                      touched.site_name ? (
                                                        <div class="text-danger">
                                                          {camelToTitle(
                                                            errors.site_name
                                                          )}
                                                        </div>
                                                      ) : null}
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="col-sm-2">
                                                  <div class="row form-group mb-0">
                                                    <label
                                                      for="inputPassword"
                                                      class="col-sm-4 col-form-label"
                                                    >
                                                      DOB
                                                    </label>
                                                    <div class="col-sm-8">
                                                      <Field
                                                        type="date"
                                                        class="form-control form-control-sm"
                                                        name={`children.${index}.dob`}
                                                        onChange={handleChange}
                                                      />
                                                      {/* {errors.site_name &&
                                                  touched.site_name ? (
                                                    <div class="text-danger">
                                                      {camelToTitle(
                                                        errors.site_name
                                                      )}
                                                    </div>
                                                  ) : null} */}
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="col-sm-3">
                                                  <div class="row form-group mb-0">
                                                    <label
                                                      for="inputPassword"
                                                      class="col-sm-4 col-form-label"
                                                    >
                                                      Aadhar NO
                                                    </label>
                                                    <div class="col-sm-8">
                                                      <Field
                                                        type="text"
                                                        class="form-control form-control-sm"
                                                        name={`children.${index}.aadhaar_number`}
                                                        onChange={handleChange}
                                                      />
                                                      {/* {errors.site_name &&
                                                      touched.site_name ? (
                                                        <div class="text-danger">
                                                          {camelToTitle(
                                                            errors.site_name
                                                          )}
                                                        </div>
                                                      ) : null} */}
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="col-sm-1">
                                                  <div class="row form-group mb-0">
                                                    <div class="col-sm-12">
                                                      {index ==
                                                        users.length - 1 && (
                                                        <button
                                                          type="button"
                                                          class="btn btn-success mx-2"
                                                          onClick={() => {
                                                            console.log("ok");
                                                            return arrayHelpers.push(
                                                              {
                                                                name: "",
                                                                dob: "",
                                                                aadhaar_number:
                                                                  "",
                                                                relation:
                                                                  "child",
                                                              }
                                                            );
                                                          }}
                                                        >
                                                          <span class="material-symbols-outlined float-start me-1">
                                                            add_circle
                                                          </span>{" "}
                                                        </button>
                                                      )}
                                                      {index <
                                                        users.length - 1 && (
                                                        <button
                                                          type="button"
                                                          class="btn btn-success"
                                                          onClick={() => {
                                                            console.log("ok");
                                                            return arrayHelpers.remove(
                                                              index
                                                            );
                                                          }}
                                                        >
                                                          <span class="material-symbols-outlined float-start me-1">
                                                            delete
                                                          </span>{" "}
                                                        </button>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    ))
                                  : null}
                              </div>
                            );
                          }}
                        />
                      </>
                    )}

                    <div class="card-body text-center">
                      <div>
                        {/* <button type="button" class="btn btn-success">
                          <span class="material-symbols-outlined float-start me-1">
                            add_circle
                          </span>{" "}
                          Add More Child
                        </button> */}
                        {isEdit && user?.status == "pending" && (
                          <>
                            <button
                              type="button"
                              class="btn btn-success"
                              onClick={() => statusChange("approved")}
                            >
                              <span class="material-symbols-outlined float-start me-1">
                                done
                              </span>{" "}
                              Approved
                            </button>
                            <button
                              type="button"
                              class="btn btn-danger"
                              onClick={() => statusChange("rejected")}
                            >
                              <span class="material-symbols-outlined float-start me-1">
                                close
                              </span>{" "}
                              Rejected
                            </button>
                          </>
                        )}
                        {isEdit && user?.status == "approved" && (
                          <>
                            <button
                              type="button"
                              class="btn btn-danger"
                              onClick={() => statusChange("blocked")}
                            >
                              <span class="material-symbols-outlined float-start me-1">
                                close
                              </span>{" "}
                              Block
                            </button>
                          </>
                        )}
                        <button type="submit" class="btn btn-success">
                          {" "}
                          Submit{" "}
                          <span class="material-symbols-outlined float-end ms-1">
                            send
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      {isOpen && (
        <ImageLightBox
          images={images.map((i) => i.url)}
          isOpen={isOpen}
          closeLightBox={closeLightBox}
        />
      )}
    </>
  );
}
