import React from "react";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

export default function ExcelGenerator({ excelData, fileName = null }) {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToExcel = async () => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName ? fileName : Date.now() + fileExtension);
  };
  return (
    <div>
      <b>
        Download
        <a
          className="text-dark"
          href="javascript:void(0);"
          onClick={exportToExcel}
        >
          <span class="material-symbols-outlined align-middle">download</span>
        </a>
      </b>
    </div>
  );
}
