import React, { useEffect, useState } from "react";
import { Field, Formik } from "formik";
import config from "../../config/config";
import Network from "../../helpers/network";
import api from "../../config/api";
import { timeFormated } from "../../helpers/time";
import { Navigate, useNavigate,useSearchParams} from "react-router-dom";
import Pagination from "../../components/Pagination/Pagination.js";
import Loader from "../../components/Loader/Loader";
import { AddSalaryValidation, EditSalaryValidation } from "./Salary.validation";
import { camelToTitle } from "../../helpers/data";

export default function SalaryInfo() {
 const [isEdit, setEdit] = useState(false);
  const [result, setResult] = useState([]);
  const [page, setPage] = useState(1);
  const [totalUser, setTotalUser] = useState(0);
  const [isError, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [message, setMessage] = useState(null);
  const [search, setSearch] = useState(null);
  const [status, setStatus] = useState("active");
  const [searchParams, setSearchParams] = useSearchParams();
  const [salaryId, setSalaryId] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [salary, setSalary] = useState(null);
 
  const navigate = useNavigate();
 
  const getSingleSalaryList = async (id) => {
    try {
      let res;
      if (search && search.length > 3) {
        res = await Network.get(api.getSalaryById,{
         id:id
        });
        console.log("edit")
      } else {
        res = await Network.get(api.getSalaryById, {
          id:id
        });
        setEdit(true);
        clearStatus();
        console.log("add")
      }

      console.log(res?.data?.data);
      setResult(res?.data?.data);
     
    } catch (error) {
      console.log(error);
    }
  };
  console.log(result);
  
 
  const clearStatus = () => {
    setError(false);
    setSuccess(false);
    setMessage(null);
  };
  console.log("isEdit",isEdit)
  const submit = async (values, resetForm) => {
    try {
      clearStatus();
      console.log("value>>", values);
      if (isEdit) {
        console.log("edit")
        const response = await Network.put(api.updateSalary, {
          ...result,
          id:salaryId,
        });
        console.log('result',response);
        setMessage("Sallary has been updated successfully");
        setSuccess(true);
      } else {
        console.log("add")
        const result = await Network.post(api.addSalary, values);
        console.log(result);
        setMessage("Salary has been created successfully");
        setSuccess(true);
        resetForm();
      }
    } catch (error) {
      console.log("error", error);
      setError(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
    }
  };
  console.log('salaryId',result)
//   const handleChange=async()
  useEffect(() => {
    getSingleSalaryList(searchParams.get("id"))
    setSalaryId(searchParams.get("id"))
  }, []);

  return (
    <>
      <div class="col-md-10 col-sm-12  bashboard_boxes_open no_mar mb-2">
        <div class="purchase_order_buttom_nav mt-0">
          <div class=" mt-0 mb-0">
            <nav class="navbar bg-body-tertiary">
              <div class="container-fluid">
                <form class="d-flex ">
                  <h6 class="navbar-brand text-white">Salary View</h6>
                </form>
                <ul class="d-flex nav justify-content-end">
                  <li class="nav-item">
                    <a href="#">
                      <button class="nav-link bg-primary text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined">
                          add_circle
                        </span>
                      </button>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="flow_through_details.html">
                      <button class="nav-link bg-success text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined">edit_note</span>
                      </button>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="#">
                      <button class="nav-link bg-success text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined d-block">
                          {" "}
                          save{" "}
                        </span>
                      </button>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="#">
                      <button class="nav-link bg-danger text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined">cancel</span>{" "}
                      </button>
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div class="container-fluid pt-3">
        {isLoading && <Loader />}
          {/* <div class="row">
            <hr/> */}
            <Formik
              initialValues={{
                basic: isEdit ? salary?.id : "",
                basic: isEdit ? salary?.basic : "",
                da: isEdit ? salary?.da : "",
                hra: isEdit ? salary?.hra : "",
                other_allowance: isEdit ? salary?.other_allowance : "",
                all_cash_reimbursement: isEdit ? salary?.all_cash_reimbursement : "",
                lta: isEdit ? salary?.lta : "",
                medical: isEdit ? salary?.medical : "",
                arrears: isEdit ? salary?.arrears : "",
                pf: isEdit ? salary?.pf : "",
                esi: isEdit ? salary?.esi : "",
                income_tax: isEdit ? salary?.income_tax : "",
                professional_tax: isEdit ? salary?.professional_tax : "",
                loans_and_advances: isEdit ? salary?.loans_and_advances : "",
                perquisites: isEdit ? salary?.perquisites : "",
                
              }}
              onSubmit={(values, { resetForm }) => submit(values, resetForm)}
              validationSchema={
                isEdit ? EditSalaryValidation : AddSalaryValidation
              }
              enableReinitialize={true}
            >
              {({
                handleChange,
                handleSubmit,
                errors,
                touched,
                values,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  {/* {JSON.stringify(errors)} */}
                  {isSuccess && (
                    <div className="alert alert-success">{message}</div>
                  )}
                  {isError && (
                    <div className="alert alert-danger">{message}</div>
                  )}
                  <div class="row"><hr/>
                  <div class="col-sm-3">
           <label>Basic</label>
            <input type="text" class="form-control" name="basic" onChange={handleChange} value={result.basic}/>{" "}
            {errors.basic &&
                                    touched.basic ? (
                                      <div class="text-danger">
                                        {camelToTitle(errors.basic)}
                                      </div>
                                    ) : null}
           </div>
          <input type="hidden" name="id" onChange={handleChange} value={result._id}/>
           <div class="col-sm-3">
           <label>Da</label>
            <input type="text" class="form-control" name="da" onChange={handleChange} value={result.da}/>
           </div>
           <div class="col-sm-3">
           <label>Hra</label>
            <input type="text" class="form-control" name="hra" onChange={handleChange} value={result.hra}/>
           </div>
           <div class="col-sm-3">
           <label>Other Allowance</label>
            <input type="text" class="form-control" name="other_allowance" onChange={handleChange} value={result.other_allowance}/><br/>
           </div>
           <hr/>
           <div class="col-sm-3">
           <label>All Cash Reimbursement</label>
            <input type="text" class="form-control" name="all_cash_reimbursement" onChange={handleChange} value={result.all_cash_reimbursement}/>
           </div>
           
           <div class="col-sm-3">
           <label>Lta</label>
            <input type="text" class="form-control" name="lta" onChange={handleChange} value={result.lta}/>
           </div>
           <div class="col-sm-3">
           <label>Medical</label>
            <input type="text" class="form-control" name="medical" onChange={handleChange} value={result.medical}/>
           </div>
           <div class="col-sm-3">
           <label>Arrears</label>
            <input type="text" class="form-control" name="arrears" onChange={handleChange} value={result.arrears}/><br/>
           </div>
           <hr/>
           <div class="col-sm-3">
           <label>Pf</label>
            <input type="text" class="form-control" name="pf" onChange={handleChange} value={result.pf}/>
           </div>
           
           <div class="col-sm-3">
           <label>Esi</label>
            <input type="text" class="form-control" name="esi" onChange={handleChange} value={result.esi}/>
           </div>
           <div class="col-sm-3">
           <label>Income Tax</label>
            <input type="text" class="form-control" name="income_tax" onChange={handleChange} value={result.income_tax}/>
           </div>
           <div class="col-sm-3">
           <label>Professional Tax</label>
            <input type="text" class="form-control" name="professional_tax" onChange={handleChange} value={result.professional_tax}/><br/>
           </div>
           <hr/>
           <div class="col-sm-3">
           <label>Loans And Advances</label>
            <input type="text" class="form-control" name="loans_and_advances" onChange={handleChange} value={result.loans_and_advances}/>
           </div>
           <div class="col-sm-3">
           <label>Perquisites</label>
            <input type="text" class="form-control" name="perquisites" onChange={handleChange} value={result.perquisites}/>
           </div>
           {/* <div class="col-sm-3">
           <label>Status</label>
            <input type="text" class="form-control" value={result?.status} readOnly/><br/>
           </div> */}
           <hr/>
           <div class="col-sm-3 offset-md-4">
            {/* <label>.</label> */}
           <button type="submit" class="form-control btn btn-success">Submit</button>
           </div>
                  </div>
                  </form>
              )}
            </Formik>
          {/* </div> */}
          
        </div>
      </div>
    </>
  );
}
