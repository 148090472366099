import React from "react";
import { Outlet } from "react-router-dom";
import ScrollToTop from "../ScrollToTop";
import Footer from "./Footer";
import NavBar from "./NavBar";
import Sidebar from "./Sidebar/Sidebar";

export default function Layout() {
  return (
    <section class="container-fluid">
      {/* <!-- Sidebar --> */}

      <NavBar />
      {/* <!-- End of Sidebar --> */}

      {/* <!-- Content Wrapper --> */}
      <div class="row">
        <Sidebar />

        {/* <!-- Begin Page Content --> */}
        <Outlet />
        {/* <!-- /.container-fluid --> */}
      </div>
    </section>
  );
}
