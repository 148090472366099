import React, { useEffect, useState } from "react";
import config from "../../config/config";
import Network from "../../helpers/network";
import api from "../../config/api";
import { timeFormated } from "../../helpers/time";
import { Navigate, useNavigate, createSearchParams } from "react-router-dom";
import Pagination from "../../components/Pagination/Pagination.js";
export default function UserRole(props) {
  const [result, setResult] = useState([]);
  const [page, setPage] = useState(1);
  const [totalUser, setTotalUser] = useState(0);
  const [isError, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [message, setMessage] = useState(null);
  const [search, setSearch] = useState(null);
  const [status, setStatus] = useState("active");
  const navigate = useNavigate();
  const getUserList = async () => {
    try {
      let res;
      if (search && search.length > 3) {
        res = await Network.get(api.employees, {
          per_page: config.per_page,
          page: page,
          q: search,
          // status: status,
        });
      } else {
        res = await Network.get(api.employees, {
          per_page: config.per_page,
          page: page,
          // status: status,
        });
      }

      console.log(res?.data?.data);
      setResult(res?.data?.data?.users);
      setTotalUser(res?.data?.data?.totalUsers);
    } catch (error) {
      console.log(error);
    }
  };
  //   const goToAddSite = () => {
  //     console.log("navigate");
  //     navigate("/site-add");
  //   };
  const clearStatus = () => {
    setError(false);
    setSuccess(false);
    setMessage(null);
  };

  const gotoEditSite = (id) => {
    navigate({
      pathname: "/role-edit",
      search: `?${createSearchParams({
        id: id,
      })}`,
    });
  };

  //   const deleteSite = async (id) => {
  //     try {
  //       clearStatus();
  //       if (window.confirm("Are you sure?")) {
  //         const result = await Network.delete(api.sites, { id });
  //         console.log(result);
  //         getSiteList();
  //         setMessage("Site has been updated successfully");
  //         setSuccess(true);
  //       }
  //     } catch (error) {
  //       setMessage(error?.response?.message);
  //       setSuccess(false);
  //       setError(true);
  //       console.log(error);
  //     }
  //   };
  const searchHandler = () => {
    getUserList();
  };

  useEffect(() => {
    getUserList();
  }, [page]);

  useEffect(() => {
    getUserList();
  }, []);
  useEffect(() => {
    getUserList();
  }, [status]);
  return (
    <>
      <div class="col-md-10 col-sm-12  bashboard_boxes_open no_mar mb-2">
        <div class="purchase_order_buttom_nav mt-0">
          <div class=" mt-0 mb-0">
            <nav class="navbar bg-body-tertiary">
              <div class="container-fluid">
                <form class="d-flex ">
                  <h6 class="navbar-brand text-white">Sites</h6>
                </form>
                <ul class="d-flex nav justify-content-end">
                  {/* <li class="nav-item">
                    <a href="javascript:void(0);" onClick={goToAddSite}>
                      <button class="nav-link bg-primary text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined">
                          add_circle
                        </span>
                      </button>
                    </a>
                  </li> */}
                  {/* <li class="nav-item">
                    <a href="flow_through_details.html">
                      <button class="nav-link bg-success text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined">edit_note</span>
                      </button>
                    </a>
                  </li> */}
                  {/* <li class="nav-item">
                    <a href="#">
                      <button class="nav-link bg-success text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined d-block">
                          {" "}
                          save{" "}
                        </span>
                      </button>
                    </a>
                  </li> */}
                  {/* <li class="nav-item">
                    <a href="#">
                      <button class="nav-link bg-danger text-white rounded-5 me-1 px-2 border-0">
                        <span class="material-symbols-outlined">cancel</span>{" "}
                      </button>
                    </a>
                  </li> */}
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div class="container-fluid pt-3">
          <div class="row">
            <div class="col-sm-12">
              <div class="card gate_entry_details2">
                <div class="table-content  tabile-responsive card-body">
                  <div class="row mb-2">
                    <div class="col-sm-5">
                      <div class="row form-group mb-0">
                        <label
                          for="inputPassword"
                          class="col-sm-3 col-form-label"
                        >
                          Search
                        </label>
                        <div class="col-sm-5">
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <table
                    id="example"
                    class="table   dt-responsive "
                    width="100%"
                  >
                    <thead>
                      <tr>
                        <th class="table-primary" width="30%">
                          Employee Name
                        </th>
                        <th class="table-primary">Emp Code</th>
                        <th class="table-primary">Site</th>
                        <th class="table-primary">Role</th>
                        <th class="table-primary  text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {result.length > 0 &&
                        result.map((r) => (
                          <tr>
                            <td>{r?.firstName + " " + r?.lastName}</td>
                            <td>{r?.empId}</td>
                            <td>{r?.siteId?.site_name}</td>
                            <td>{r?.role}</td>
                            <td class="text-center">
                              <button
                                class=" bg-primary text-white  border-0 "
                                onClick={() => gotoEditSite(r?._id)}
                              >
                                <span class="material-symbols-outlined">
                                  edit
                                </span>
                              </button>
                              {/* <button
                                class=" bg-danger text-white  border-0 "
                                onClick={() => {
                                  deleteSite(r?._id);
                                }}
                              >
                                <span class="material-symbols-outlined">
                                  delete
                                </span>
                              </button> */}
                            </td>
                          </tr>
                        ))}
                      {result.length === 0 && (
                        <tr>
                          <td colSpan={5}>No result found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* <div class="card-body mt-3">
                <nav aria-label="Page navigation example">
                  <ul class="pagination justify-content-center">
                    <li class="page-item disabled ">
                      <a class="page-link bg-success text-white">Previous</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">
                        1
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">
                        2
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">
                        3
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link bg-success text-white" href="#">
                        Next
                      </a>
                    </li>
                  </ul>
                </nav>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
