import { Button } from "bootstrap";
import React from "react";
import Modal from "react-bootstrap/Modal";

export default function AttendenceModal({ title, show, setShow, children }) {
  const handleClose = () => {
    setShow(false);
  };
  return (
    <>
      {/* <!-- Logout Modal--> */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        {/* <Modal.Footer>
          <button variant="secondary" onClick={handleClose}>
            Close
          </button>
          <button variant="primary" onClick={handleClose}>
            Save Changes
          </button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}
